import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setProducts,
  updatesProduct,
  deleteProduct,
} from "../store/ProductsSlice.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import ProductDetail from "./SubScreens/ProductDetail.js";
import "./Products.css";
import { v4 as uuidv4 } from "uuid";
import DOMPurify from "dompurify";

const Products = () => {
  const dispatch = useDispatch();
  const products = useSelector((state) => state.products.formData.products);

  const [selectedProductId, setSelectedProductId] = useState(null);
  const [addProduct, setAddProduct] = useState(false);
  const [newProd, setNewProd] = useState("");
  const [editProduct, setEditProduct] = useState(false);
  const [updateProd, setUpdateProd] = useState("");

  useEffect(() => {
    setEditProduct(false);
  }, [products, selectedProductId]);

  const handleNewProduct = async () => {
    const sanitizedNewProd = DOMPurify.sanitize(newProd);
    dispatch(setProducts({ id: uuidv4(), name: sanitizedNewProd }));
    setAddProduct(false);
    setNewProd("");
  };

  const handleUpdateProduct = async (id, name) => {
    try {
      const sanitizedUpdateProd = DOMPurify.sanitize(updateProd);
      await dispatch(
        updatesProduct({ id, updatedProduct: { name: sanitizedUpdateProd } })
      ).unwrap();
      setEditProduct(false);
      setUpdateProd("");
    } catch (error) {
      console.error("Failed to update product:", error);
    }
  };

  const handleRemoveProduct = async (id) => {
    try {
      await dispatch(deleteProduct(id)).unwrap();
      setSelectedProductId(null);
      setEditProduct(false);
    } catch (error) {
      console.error("Failed to delete product:", error);
    }
  };

  const cancel = () => {
    setAddProduct(false);
    setNewProd("");
  };

  const cancelEdit = () => {
    setEditProduct(false);
  };

  const handleSelectProduct = (productId) => {
    if (productId === selectedProductId) {
      setSelectedProductId(null);
    } else {
      setSelectedProductId(productId);
    }
  };

  const selectedProduct = products.find(
    (product) => product.name === selectedProductId
  );

  return (
    <div className="outerContainer">
      <div className="listContainer">
        {addProduct && (
          <div
            style={{ display: "flex", width: "100%", flexDirection: "column" }}
          >
            <input
              className="textInput"
              placeholder="New Product Name"
              value={newProd}
              onChange={(e) => setNewProd(e.target.value)}
            />
            <div
              style={{
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <button
                style={{
                  background: "linear-gradient(to right, #1876D2, #000080)",
                }}
                className="addButton"
                onClick={handleNewProduct}
              >
                Save Product
              </button>
              <button
                style={{
                  background: "linear-gradient(to right, #1876D2, #000080)",
                }}
                className="addButton"
                onClick={cancel}
              >
                Cancel Product
              </button>
            </div>
          </div>
        )}

        {!addProduct && (
          <button
            style={{
              background: "linear-gradient(to right, #1876D2, #000080)",
            }}
            className="addButton"
            onClick={() => setAddProduct(true)}
          >
            Add New Product
          </button>
        )}

        {products.length > 0 && <h2>Products List:</h2>}

        {products.length > 0 && (
          <div className="productList">
            {products.map((product) => (
              <div key={product.id} className="productOption">
                {!editProduct && (
                  <div onClick={() => handleSelectProduct(product.name)}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        color:
                          product.name === selectedProductId ? "blue" : null,
                      }}
                    >
                      <span>{product.name} --</span>
                      <FontAwesomeIcon icon={faChevronRight} />
                    </div>
                  </div>
                )}

                {editProduct && selectedProductId === product.name && (
                  <div style={{ flexDirection: "column" }}>
                    <input
                      style={{ width: "100%" }}
                      className="textInput"
                      placeholder={`Update ${product.name}?`}
                      value={updateProd}
                      onChange={(e) => setUpdateProd(e.target.value)}
                    />
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <button
                        style={{
                          background:
                            "linear-gradient(to right, #1876D2, #000080)",
                        }}
                        className="addButton"
                        onClick={() =>
                          handleUpdateProduct(product.id, updateProd)
                        }
                      >
                        Update Product
                      </button>
                      <button
                        style={{
                          background:
                            "linear-gradient(to right, #1876D2, #000080)",
                        }}
                        className="addButton"
                        onClick={cancelEdit}
                      >
                        Cancel Update
                      </button>
                    </div>
                  </div>
                )}

                {products.length > 0 &&
                  !editProduct &&
                  selectedProductId === product.name && (
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <button
                        style={{
                          background:
                            "linear-gradient(to right, #1876D2, #000080)",
                        }}
                        className="addButton"
                        onClick={() => setEditProduct(true)}
                      >
                        Edit Product
                      </button>
                      <button
                        style={{
                          background:
                            "linear-gradient(to right, #1876D2, #000080)",
                        }}
                        className="addButton"
                        onClick={() => handleRemoveProduct(product.id)}
                      >
                        Delete Product
                      </button>
                    </div>
                  )}
              </div>
            ))}
          </div>
        )}
      </div>

      <div className="detailContainer">
        {selectedProduct ? (
          <ProductDetail product={selectedProduct.name} />
        ) : (
          <h1 style={{ marginLeft: 100, marginTop: 50 }}>Create a product</h1>
        )}
      </div>
    </div>
  );
};

export default Products;

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setCashFlow,
  incrementCashFlowYear,
} from "../../store/CashFlowSlice.js";
import DOMPurify from "dompurify";
import "./CashFlow.css";
import * as XLSX from "xlsx";

const CashFlow = () => {
  const dispatch = useDispatch();
  const reduxCashFlow = useSelector(
    (state) => state.cashFlow.formData.years || {}
  );
  const [currentYear, setCurrentYear] = useState(
    Object.keys(reduxCashFlow).pop() || "2023-2024"
  );
  const [cashFlowItems, setCashFlowItems] = useState(
    reduxCashFlow[currentYear]?.cashFlowItems || {}
  );
  const [calculatedValues, setCalculatedValues] = useState({
    netCashFromOperatingActivities: [0, 0, 0, 0],
    netCashUsedInInvestingActivities: [0, 0, 0, 0],
    netCashProvidedInFinancingActivities: [0, 0, 0, 0],
    netIncreaseInCash: [0, 0, 0, 0],
    cashAtEndOfPeriod: [0, 0, 0, 0],
  });

  useEffect(() => {
    const budgetItems = cashFlowItems;

    const netCashFromOperatingActivities = budgetItems.netIncome?.map(
      (_, index) =>
        parseFloat(budgetItems.netIncome[index]) +
        parseFloat(budgetItems.depreciation[index]) +
        parseFloat(budgetItems.changesInAccountsRecievable[index]) +
        parseFloat(budgetItems.changesInInventory[index]) +
        parseFloat(budgetItems.changesInAccountsPayable[index])
    );

    const netCashUsedInInvestingActivities = budgetItems.saleOfInvestment?.map(
      (_, index) =>
        parseFloat(budgetItems.saleOfInvestment[index]) -
        parseFloat(budgetItems.purchaseOfPropertyAndEquipment[index])
    );

    const netCashProvidedInFinancingActivities =
      budgetItems.proceedsFromIssuanceOfStock?.map(
        (_, index) =>
          parseFloat(budgetItems.proceedsFromIssuanceOfStock[index]) -
          parseFloat(budgetItems.paymentOfDividends[index]) -
          parseFloat(budgetItems.repaymentOfDebt[index])
      );

    const netIncreaseInCash = netCashFromOperatingActivities?.map(
      (value, index) =>
        value +
        netCashUsedInInvestingActivities[index] +
        netCashProvidedInFinancingActivities[index]
    );

    const cashAtEndOfPeriod = netIncreaseInCash?.map(
      (value, index) =>
        value + parseFloat(budgetItems.cashAtBeginningOfPeriod[index])
    );

    setCalculatedValues({
      netCashFromOperatingActivities,
      netCashUsedInInvestingActivities,
      netCashProvidedInFinancingActivities,
      netIncreaseInCash,
      cashAtEndOfPeriod,
    });
  }, [cashFlowItems]);

  useEffect(() => {
    setCashFlowItems(reduxCashFlow[currentYear]?.cashFlowItems || {});
  }, [currentYear, reduxCashFlow]);

  const handleInputChange = (field, index, value) => {
    const sanitizedValue = DOMPurify.sanitize(value);
    // Allow digits, decimal point, and minus sign; ensure minus sign is at the beginning
    const filteredValue = sanitizedValue.replace(/(?!^-)[^0-9.]/g, "");
    const numericValue = parseFloat(filteredValue) || 0;

    setCashFlowItems((prevState) => ({
      ...prevState,
      [field]: prevState[field].map((item, itemIndex) =>
        itemIndex === index ? numericValue : item
      ),
    }));
  };

  const handleSubmit = () => {
    dispatch(setCashFlow({ year: currentYear, cashFlowItems }));
    alert("Cash Flow Saved");
  };

  const handleIncrementYear = () => {
    const [startYear, endYear] = currentYear.split("-").map(Number);
    const newYear = `${startYear + 1}-${endYear + 1}`;
    dispatch(incrementCashFlowYear());
    setCurrentYear(newYear);
    if (!reduxCashFlow[newYear]) {
      setCashFlowItems({
        netIncome: [0, 0, 0, 0],
        depreciation: [0, 0, 0, 0],
        changesInAccountsRecievable: [0, 0, 0, 0],
        changesInInventory: [0, 0, 0, 0],
        changesInAccountsPayable: [0, 0, 0, 0],
        netCashFromOperatingActivities: [0, 0, 0, 0],
        investingActivities: [0, 0, 0, 0],
        purchaseOfPropertyAndEquipment: [0, 0, 0, 0],
        saleOfInvestment: [0, 0, 0, 0],
        netCashUsedInInvestingActivities: [0, 0, 0, 0],
        financingActivities: [0, 0, 0, 0],
        proceedsFromIssuanceOfStock: [0, 0, 0, 0],
        paymentOfDividends: [0, 0, 0, 0],
        repaymentOfDebt: [0, 0, 0, 0],
        netCashProvidedInFinancingActivities: [0, 0, 0, 0],
        netIncreaseInCash: [0, 0, 0, 0],
        cashAtBeginningOfPeriod: [0, 0, 0, 0],
        cashAtEndOfPeriod: [0, 0, 0, 0],
      });
    } else {
      setCashFlowItems(reduxCashFlow[newYear].cashFlowItems);
    }
  };

  const handleDecrementYear = () => {
    const years = Object.keys(reduxCashFlow);
    const currentYearIndex = years.indexOf(currentYear);
    if (currentYearIndex > 0) {
      const previousYear = years[currentYearIndex - 1];
      setCurrentYear(previousYear);
      setCashFlowItems(reduxCashFlow[previousYear]?.cashFlowItems || {});
    }
  };

  const capitalizeWords = (str) => {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const exportToXLSX = () => {
    const data = [];
    const years = Object.keys(reduxCashFlow);

    years.forEach((year) => {
      const yearData = reduxCashFlow[year].cashFlowItems;
      Object.keys(yearData).forEach((category) => {
        const row = [
          year,
          capitalizeWords(category.replace(/([A-Z])/g, " $1")),
        ];
        row.push(...yearData[category]);
        data.push(row);
      });

      // Add calculated values for each year
      const netCashFromOperatingActivities =
        calculatedValues.netCashFromOperatingActivities;
      const netCashUsedInInvestingActivities =
        calculatedValues.netCashUsedInInvestingActivities;
      const netCashProvidedInFinancingActivities =
        calculatedValues.netCashProvidedInFinancingActivities;
      const netIncreaseInCash = calculatedValues.netIncreaseInCash;
      const cashAtEndOfPeriod = calculatedValues.cashAtEndOfPeriod;

      data.push([
        year,
        "Net Cash From Operating Activities",
        ...netCashFromOperatingActivities,
      ]);
      data.push([
        year,
        "Net Cash Used In Investing Activities",
        ...netCashUsedInInvestingActivities,
      ]);
      data.push([
        year,
        "Net Cash Provided In Financing Activities",
        ...netCashProvidedInFinancingActivities,
      ]);
      data.push([year, "Net Increase In Cash", ...netIncreaseInCash]);
      data.push([year, "Cash At End Of Period", ...cashAtEndOfPeriod]);
    });

    const worksheet = XLSX.utils.aoa_to_sheet([
      ["Cash Flow Statement"],
      ["Year", "Category", "Q1", "Q2", "Q3", "Q4"],
      ...data,
    ]);

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Cash Flow");

    XLSX.writeFile(workbook, "CashFlow.xlsx");
  };

  return (
    <div className="container">
      <div className="row">
        <h2 style={{ display: "inline", fontSize: 26, fontStyle: "italic" }}>
          Cash Flow for financial year: {currentYear}
        </h2>
        <div className="year-controls">
          <button data-testid="increment-year" onClick={handleIncrementYear}>
            ▲
          </button>
          <button data-testid="decrement-year" onClick={handleDecrementYear}>
            ▼
          </button>
        </div>
      </div>

      <div className="table">
        <div className="header row">
          <span>Category</span>
          <span>Q1</span>
          <span>Q2</span>
          <span>Q3</span>
          <span>Q4</span>
        </div>
        {Object.keys(cashFlowItems).map((category) => (
          <div key={category} className="row">
            <span style={{ width: 200 }}>
              {capitalizeWords(category.replace(/([A-Z])/g, " $1"))}
            </span>
            {cashFlowItems[category].map((value, index) =>
              [
                "netCashFromOperatingActivities",
                "netCashUsedInInvestingActivities",
                "netCashProvidedInFinancingActivities",
                "netIncreaseInCash",
                "cashAtEndOfPeriod",
              ].includes(category) ? (
                <span key={index} style={{ width: 200, margin: "0 5px" }}>
                  ${calculatedValues[category][index]}
                </span>
              ) : (
                <input
                  key={index}
                  type="text"
                  value={value}
                  onChange={(e) =>
                    handleInputChange(category, index, e.target.value)
                  }
                />
              )
            )}
          </div>
        ))}
      </div>
      <div>
        <button className="export-button" onClick={handleSubmit}>
          Save Cash Flow
        </button>
        <button className="export-button" onClick={exportToXLSX}>
          Export to XLSX
        </button>
      </div>
    </div>
  );
};

export default CashFlow;

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setFormData } from "../store/inputsSlice.js";
import DOMPurify from "dompurify";
import "./styles/Style.css";

const StrategicPlan = () => {
  const dispatch = useDispatch();
  const reduxFormData = useSelector(
    (state) => state.inputs.formData.StrategicPlan
  );
  const [formData, setLocalFormData] = useState(reduxFormData);

  useEffect(() => {
    setLocalFormData(reduxFormData);
  }, [reduxFormData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    // Sanitize the input value
    const sanitizedValue = DOMPurify.sanitize(value);
    setLocalFormData({
      ...formData,
      [name]: sanitizedValue,
    });
  };

  const handleSubmit = () => {
    dispatch(setFormData({ StrategicPlan: formData }));
    alert("Strategic Plan Saved");
  };

  return (
    <div className="content">
      <h2 className="title">Strategic Plan</h2>
      <div className="column">
        <label className="subheading">Barriers to Entry:</label>
        <textarea
          className="textarea"
          type="text"
          name="input1"
          value={formData.input1}
          onChange={handleChange}
          placeholder="What are barriers to market entry?"
        />
        <label className="subheading">Supplier Power:</label>
        <textarea
          className="textarea"
          type="text"
          name="input2"
          value={formData.input2}
          onChange={handleChange}
          placeholder="What power do suppliers have?"
        />
        <label className="subheading">Buyer Power:</label>
        <textarea
          className="textarea"
          type="text"
          name="input3"
          value={formData.input3}
          onChange={handleChange}
          placeholder="What power do buyers have?"
        />
        <label className="subheading">Threat of Substitutes:</label>
        <textarea
          className="textarea"
          type="text"
          name="input4"
          value={formData.input4}
          onChange={handleChange}
          placeholder="What is the threat of substitutes?"
        />
        <label className="subheading">Degree of Rivalry:</label>
        <textarea
          className="textarea"
          type="text"
          name="input5"
          value={formData.input5}
          onChange={handleChange}
          placeholder="What is the degree of market rivalry?"
        />
        <label className="subheading">Strategic Partner:</label>
        <textarea
          className="textarea"
          type="text"
          name="input6"
          value={formData.input6}
          onChange={handleChange}
          placeholder="Who are potential strategic partners and do I need them?"
        />

        <button
          style={{ background: "linear-gradient(to right, #1876D2, #000080)" }}
          className="button"
          onClick={handleSubmit}
        >
          Save Strategic Plan
        </button>
      </div>
    </div>
  );
};

export default StrategicPlan;

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setFormData } from "../store/inputsSlice.js";
import DOMPurify from "dompurify";
import "./styles/Style.css";

const LegalPlan = () => {
  const dispatch = useDispatch();
  const reduxFormData = useSelector((state) => state.inputs.formData.LegalPlan);
  const [formData, setLocalFormData] = useState(reduxFormData);

  useEffect(() => {
    setLocalFormData(reduxFormData);
  }, [reduxFormData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    // Sanitize the input value
    const sanitizedValue = DOMPurify.sanitize(value);
    setLocalFormData({
      ...formData,
      [name]: sanitizedValue,
    });
  };

  const handleSubmit = () => {
    dispatch(setFormData({ LegalPlan: formData }));
    alert("Legal Plan Saved");
  };

  return (
    <div className="content">
      <h2 className="title">Legal Plan</h2>
      <div className="column">
        <label className="subheading">Business Name:</label>
        <textarea
          className="textarea"
          type="text"
          name="input1"
          value={formData.input1}
          onChange={handleChange}
          placeholder="What business name? Is it registered?"
        />
        <label className="subheading">Business Structure:</label>
        <textarea
          className="textarea"
          type="text"
          name="input2"
          value={formData.input2}
          onChange={handleChange}
          placeholder="How will you structure the business?"
        />
        <label className="subheading">Licences, permits and Regulations:</label>
        <textarea
          className="textarea"
          type="text"
          name="input3"
          value={formData.input3}
          onChange={handleChange}
          placeholder="Any licences or permits required?"
        />
        <label className="subheading">The Market:</label>
        <textarea
          className="textarea"
          type="text"
          name="input4"
          value={formData.input4}
          onChange={handleChange}
          placeholder="Who specifically are your target market?"
        />
        <label className="subheading">Intellectual property:</label>
        <textarea
          className="textarea"
          type="text"
          name="input5"
          value={formData.input5}
          onChange={handleChange}
          placeholder="What patents or copywrites do you need?"
        />
        <label className="subheading">Business Insurance:</label>
        <textarea
          className="textarea"
          type="text"
          name="input6"
          value={formData.input6}
          onChange={handleChange}
          placeholder="What insurance do I need?"
        />
        <label className="subheading">Data Protection and Privacy:</label>
        <textarea
          className="textarea"
          type="text"
          name="input7"
          value={formData.input7}
          onChange={handleChange}
          placeholder="Will we be compliant with data protection and privacy laws? How?"
        />

        <button
          style={{ background: "linear-gradient(to right, #1876D2, #000080)" }}
          className="button"
          onClick={handleSubmit}
        >
          Save Legal Plan
        </button>
      </div>
    </div>
  );
};

export default LegalPlan;

import React, { useState, useEffect } from 'react';
import { Button, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { loginUser } from './store/authSlice'; // Ensure correct path
import { clearAllData, fetchBackendData } from './thunks/fetchBackendData';
import { useNavigate } from 'react-router-dom';

function Login({ open, onClose, onLoginSuccess }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [userName, setUserName] = useState(''); 
  const dispatch = useDispatch();
  const authStatus = useSelector((state) => state.auth.status);
  const authError = useSelector((state) => state.auth.error);
  const token = useSelector((state) => state.auth.token);
  const navigate = useNavigate();

  useEffect(() => {
    const handlePostLogin = async () => {
      try {
        // eslint-disable-next-line
        const clearDataResult = await dispatch(clearAllData());
        if (clearAllData.fulfilled.match(clearDataResult)) {
        } else {
          alert('Failed to clear data:', clearDataResult.payload);
        }

        if (token) {
          try {
            // eslint-disable-next-line
            const resultAction = await dispatch(fetchBackendData(token));
            console.log('Backend data fetched:', resultAction);
            alert("login successful");
          } catch (error) {
            console.log('No backend data found or fetch failed');
            console.error('Fetch backend data error:', error);
          }
        } else {
          console.log("Login failed, token doesn't exist");
        }

          if (userName) {
            onLoginSuccess(userName); 
            onClose(); 
          } else {
            throw new Error('User name is missing in the login response');
          }

        } catch (error) {
        console.error('Failed to clear data or fetch backend data:', error);
      }
    };

    if (authStatus === 'succeeded' && token && userName) {
      handlePostLogin();
    }
    // eslint-disable-next-line
  }, [userName]);

  const handleLogin = async () => {
    try {
      console.log(`Dispatching loginUser with email: ${email}, password: ${password}`);
// eslint-disable-next-line
      const result = await dispatch(loginUser({ email, password }));
      console.log('Login Result:', result); 
      // eslint-disable-next-line
      if (result.payload && result.payload.name) {
      setUserName(result.payload.name);
    } else {
      alert("unable to acccess userName from payload");
    }
    } catch (error) {
      console.error('Failed to log in:', error);
    }
  };

  const handleForgotPassword = () => {
    navigate('/reset-password');
    };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Login</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please enter your login credentials.
        </DialogContentText>
        {authError && <DialogContentText style={{ color: 'red' }}>{authError}</DialogContentText>}
        <TextField
          autoFocus
          margin="dense"
          label="Email"
          type="email"
          fullWidth
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          margin="dense"
          label="Password"
          type="password"
          fullWidth
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleLogin} color="primary" disabled={authStatus === 'loading'}>
          Login
        </Button>
        <Button onClick={handleForgotPassword} color="secondary">
          Forgot Password?
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default Login;

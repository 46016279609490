import React from "react";
import { Typography, Container, Box } from "@mui/material";
import { useSpring, animated } from "@react-spring/web";
import bossBabeImage from "./Img/BossBrains.png";
import gardening from "./Img/BossGardenMan.png";
import ideas from "./Img/BossIdeas.png";
import shield from "./Img/BossCybersecurity.png";
import Footer from "./components/Footer";

function AnimatedTypography({ children, ...props }) {
  const animationProps = useSpring({
    from: { opacity: 0, transform: "translate3d(0,40px,0)" },
    to: { opacity: 1, transform: "translate3d(0,0px,0)" },
    config: { tension: 200, friction: 15 },
  });

  return (
    <animated.div style={animationProps}>
      <Typography {...props}>{children}</Typography>
    </animated.div>
  );
}

function Welcome() {
  return (
    <div>
      <Container
        maxWidth="100%"
        style={{ textAlign: "center", marginTop: "50px" }}
      >
      <AnimatedTypography
              variant="h2"
              paragrah
              style={{
                marginBottom: 50,
                fontWeight: "bold",
              }}
            >
         WEBSITE UNDER CONSTRUCTION
      </AnimatedTypography>
        <Box>
          <div
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "row",
              marginBottom: 50,
            }}
          >
            <div
              style={{
                flexDirection: "column",
                marginLeft: "5%",
                marginRight: "5%",
                marginTop: "5%",
              }}
            >
              <AnimatedTypography
                variant="h5"
                paragraph
                style={{
                  fontStyle: "italic",
                  marginBottom: 50,
                  fontWeight: "bold",
                }}
              >
                "The hub for bringing your business ideas to life."
              </AnimatedTypography>
              <AnimatedTypography variant="h6" paragraph>
                Here you will find the tools and resources you need to turn your
                business dreams into reality. Whether you're just starting out
                with a spark of an idea or you're an established business owner
                looking to innovate, our platform is designed to support every
                stage of your journey. With robust features for planning,
                organizing, and refining your business concepts, you'll have
                everything at your fingertips to ensure your business success.
              </AnimatedTypography>
            </div>
            <img
              src={bossBabeImage}
              style={{
                width: "40%",
                height: "40%",
                objectFit: "cover",
                borderRadius: 20,
              }}
              alt="Boss Babe"
            />
          </div>

          <div
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "row",
              marginBottom: 50,
            }}
          >
            <img
              src={gardening}
              style={{
                width: "40%",
                height: "40%",
                objectFit: "cover",
                borderRadius: 20,
              }}
              alt="Gardening"
            />
            <div
              style={{
                flexDirection: "column",
                marginLeft: "5%",
                marginRight: "5%",
                marginTop: "10%",
              }}
            >
              <AnimatedTypography
                variant="h5"
                paragraph
                style={{
                  fontStyle: "italic",
                  marginBottom: 50,
                  fontWeight: "bold",
                }}
              >
                "Plant the seeds of your business."
              </AnimatedTypography>
              <AnimatedTypography variant="h6" paragraph>
                At BOSS Entrepreneur, we provide you with the fertile ground and
                essential tools to help your business idea take root and grow.
                Our platform is designed to support you from the initial stages
                of ideation to the flourishing of a fully realized enterprise.
                We offer resources for detailed planning, market analysis, and
                strategic development, ensuring that your idea is nurtured with
                the care and attention it needs.
              </AnimatedTypography>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "row",
              marginBottom: 50,
            }}
          >
            <div
              style={{
                flexDirection: "column",
                marginLeft: "5%",
                marginRight: "5%",
                marginTop: "10%",
              }}
            >
              <AnimatedTypography
                variant="h5"
                paragraph
                style={{
                  fontStyle: "italic",
                  marginBottom: 50,
                  fontWeight: "bold",
                }}
              >
                "Brainstorm and iterate over business ideas."
              </AnimatedTypography>
              <AnimatedTypography variant="h6" paragraph>
                We understand that the journey from a concept to a successful
                business involves continuous brainstorming and refinement. Our
                platform is specifically crafted to facilitate this dynamic
                process, providing entrepreneurs with the tools they need to
                generate, evaluate, and improve their ideas.
              </AnimatedTypography>
            </div>
            <img
              src={ideas}
              style={{
                width: "40%",
                height: "40%",
                objectFit: "cover",
                borderRadius: 20,
              }}
              alt="Boss Babe"
            />
          </div>

          <div
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "row",
              marginBottom: 50,
            }}
          >
            <img
              src={shield}
              style={{
                width: "40%",
                height: "40%",
                objectFit: "cover",
                borderRadius: 20,
              }}
              alt="Boss Babe"
            />
            <div
              style={{
                flexDirection: "column",
                marginLeft: "5%",
                marginRight: "5%",
                marginTop: "10%",
              }}
            >
              <AnimatedTypography
                variant="h5"
                paragraph
                style={{
                  fontStyle: "italic",
                  marginBottom: 50,
                  fontWeight: "bold",
                }}
              >
                "Robust Privacy and Security."
              </AnimatedTypography>
              <AnimatedTypography variant="h6" paragraph>
                Our platform is built with robust privacy and security standards
                to ensure that your sensitive business ideas and personal data
                are protected at all times. We employ advanced encryption
                methods, regular security audits, and stringent access controls
                to safeguard your information against unauthorized access and
                cyber threats. You can trust that we are committed to
                maintaining a secure environment where you can confidently
                develop and iterate on your business ideas, knowing that your
                data is in safe hands.
              </AnimatedTypography>
            </div>
          </div>
        </Box>
      </Container>
      <Footer />
    </div>
  );
}

export default Welcome;

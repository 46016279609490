import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setMarketingBudget,
  addRow,
  removeRow,
} from "../../store/MarketingBudgetSlice.js";
import DOMPurify from "dompurify";
import "./MarketingBudget.css";
import * as XLSX from "xlsx";

const MarketingBudget = () => {
  const dispatch = useDispatch();
  const reduxMarketingBudget = useSelector(
    (state) => state.marketingBudget.formData.MarketingBudget.years
  );

  const [currentYear, setCurrentYear] = useState(() => {
    if (reduxMarketingBudget && Object.keys(reduxMarketingBudget).length > 0) {
      return Object.keys(reduxMarketingBudget).pop();
    }
    return "2023-2024";
  });

  const [channels, setChannels] = useState(
    reduxMarketingBudget[currentYear]?.channels || Array(5).fill("")
  );
  const [objectives, setObjectives] = useState(
    reduxMarketingBudget[currentYear]?.objectives || Array(5).fill("")
  );
  const [audiences, setAudiences] = useState(
    reduxMarketingBudget[currentYear]?.audiences || Array(5).fill("")
  );
  const [qOne, setQOne] = useState(
    reduxMarketingBudget[currentYear]?.qOne || Array(5).fill("")
  );
  const [qTwo, setQTwo] = useState(
    reduxMarketingBudget[currentYear]?.qTwo || Array(5).fill("")
  );
  const [qThree, setQThree] = useState(
    reduxMarketingBudget[currentYear]?.qThree || Array(5).fill("")
  );
  const [qFour, setQFour] = useState(
    reduxMarketingBudget[currentYear]?.qFour || Array(5).fill("")
  );

  useEffect(() => {
    if (reduxMarketingBudget && reduxMarketingBudget[currentYear]) {
      setChannels(reduxMarketingBudget[currentYear].channels);
      setObjectives(reduxMarketingBudget[currentYear].objectives);
      setAudiences(reduxMarketingBudget[currentYear].audiences);
      setQOne(reduxMarketingBudget[currentYear].qOne);
      setQTwo(reduxMarketingBudget[currentYear].qTwo);
      setQThree(reduxMarketingBudget[currentYear].qThree);
      setQFour(reduxMarketingBudget[currentYear].qFour);
    }
  }, [currentYear, reduxMarketingBudget]);

  const handleInputChange = (index, field, value) => {
    const sanitizedValue = DOMPurify.sanitize(value);
    const updateArray = (array) => {
      const newArray = [...array];
      newArray[index] = sanitizedValue;
      return newArray;
    };

    switch (field) {
      case "channel":
        setChannels(updateArray(channels));
        break;
      case "objective":
        setObjectives(updateArray(objectives));
        break;
      case "audience":
        setAudiences(updateArray(audiences));
        break;
      case "qOne":
        setQOne(updateArray(qOne));
        break;
      case "qTwo":
        setQTwo(updateArray(qTwo));
        break;
      case "qThree":
        setQThree(updateArray(qThree));
        break;
      case "qFour":
        setQFour(updateArray(qFour));
        break;
      default:
        break;
    }
  };

  const handleSubmit = () => {
    dispatch(
      setMarketingBudget({
        year: currentYear,
        channels,
        objectives,
        audiences,
        qOne,
        qTwo,
        qThree,
        qFour,
      })
    );
    alert("Marketing Budget Saved");
  };

  const handleAddRow = () => {
    dispatch(addRow({ year: currentYear }));
  };

  const handleRemoveRow = () => {
    dispatch(removeRow({ year: currentYear }));
  };

  const handleIncrementYear = () => {
    const [startYear, endYear] = currentYear.split("-").map(Number);
    const newYear = `${startYear + 1}-${endYear + 1}`;
    setCurrentYear(newYear);
    if (!reduxMarketingBudget[newYear]) {
      dispatch(
        setMarketingBudget({
          year: newYear,
          channels: Array(5).fill(""),
          objectives: Array(5).fill(""),
          audiences: Array(5).fill(""),
          qOne: Array(5).fill(""),
          qTwo: Array(5).fill(""),
          qThree: Array(5).fill(""),
          qFour: Array(5).fill(""),
        })
      );
    } else {
      setChannels(reduxMarketingBudget[newYear].channels);
      setObjectives(reduxMarketingBudget[newYear].objectives);
      setAudiences(reduxMarketingBudget[newYear].audiences);
      setQOne(reduxMarketingBudget[newYear].qOne);
      setQTwo(reduxMarketingBudget[newYear].qTwo);
      setQThree(reduxMarketingBudget[newYear].qThree);
      setQFour(reduxMarketingBudget[newYear].qFour);
    }
  };

  const handleDecrementYear = () => {
    const [startYear, endYear] = currentYear.split("-").map(Number);
    const newYear = `${startYear - 1}-${endYear - 1}`;
    setCurrentYear(newYear);
    if (!reduxMarketingBudget[newYear]) {
      dispatch(
        setMarketingBudget({
          year: newYear,
          channels: Array(5).fill(""),
          objectives: Array(5).fill(""),
          audiences: Array(5).fill(""),
          qOne: Array(5).fill(""),
          qTwo: Array(5).fill(""),
          qThree: Array(5).fill(""),
          qFour: Array(5).fill(""),
        })
      );
    } else {
      setChannels(reduxMarketingBudget[newYear].channels);
      setObjectives(reduxMarketingBudget[newYear].objectives);
      setAudiences(reduxMarketingBudget[newYear].audiences);
      setQOne(reduxMarketingBudget[newYear].qOne);
      setQTwo(reduxMarketingBudget[newYear].qTwo);
      setQThree(reduxMarketingBudget[newYear].qThree);
      setQFour(reduxMarketingBudget[newYear].qFour);
    }
  };

  const exportToXLSX = () => {
    const data = [
      ["Marketing Budget"],
      ["Year", currentYear, "", "", "", "", "", ""],
      ["Channel", "Objective", "Audience", "Q1", "Q2", "Q3", "Q4"],
      ...channels.map((channel, index) => [
        channel,
        objectives[index],
        audiences[index],
        qOne[index],
        qTwo[index],
        qThree[index],
        qFour[index],
      ]),
    ];

    const worksheet = XLSX.utils.aoa_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Marketing Budget");

    XLSX.writeFile(workbook, `MarketingBudget_${currentYear}.xlsx`);
  };

  return (
    <div className="marketing-budget-container">
      <div className="header">
        <h2>Marketing Budget for Financial Year: {currentYear}</h2>
        <div style={{ flexDirection: "column" }}>
          <button
            className="buttonOne"
            data-testid="increment-year"
            onClick={handleIncrementYear}
          >
            ▲
          </button>
          <button
            className="buttonOne"
            data-testid="decrement-year"
            onClick={handleDecrementYear}
          >
            ▼
          </button>
        </div>
      </div>
      <div className="table">
        <div className="header row">
          <span>Channel</span>
          <span>Objective</span>
          <span>Audience</span>
          <span>Q1</span>
          <span>Q2</span>
          <span>Q3</span>
          <span>Q4</span>
        </div>
        {channels.map((_, index) => (
          <div key={index} className="row">
            <input
              type="text"
              value={channels[index]}
              onChange={(e) =>
                handleInputChange(index, "channel", e.target.value)
              }
              placeholder="Channel"
            />
            <input
              type="text"
              value={objectives[index]}
              onChange={(e) =>
                handleInputChange(index, "objective", e.target.value)
              }
              placeholder="Objective"
            />
            <input
              type="text"
              value={audiences[index]}
              onChange={(e) =>
                handleInputChange(index, "audience", e.target.value)
              }
              placeholder="Audience"
            />
            <input
              type="number"
              value={qOne[index]}
              onChange={(e) => handleInputChange(index, "qOne", e.target.value)}
              placeholder="Q1"
            />
            <input
              type="number"
              value={qTwo[index]}
              onChange={(e) => handleInputChange(index, "qTwo", e.target.value)}
              placeholder="Q2"
            />
            <input
              type="number"
              value={qThree[index]}
              onChange={(e) =>
                handleInputChange(index, "qThree", e.target.value)
              }
              placeholder="Q3"
            />
            <input
              type="number"
              value={qFour[index]}
              onChange={(e) =>
                handleInputChange(index, "qFour", e.target.value)
              }
              placeholder="Q4"
            />
          </div>
        ))}
      </div>
      <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
        <button className="save-button" onClick={handleAddRow}>
          Add Row
        </button>
        <button className="save-button" onClick={handleSubmit}>
          Save Marketing Budget
        </button>
        <button className="save-button" onClick={exportToXLSX}>
          Export to XLSX
        </button>
        <button className="save-button" onClick={handleRemoveRow}>
          Remove Row
        </button>
      </div>
    </div>
  );
};

export default MarketingBudget;

import React, { useState, useEffect } from "react";
import {
  Dialog,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  createCustomerAndSubscription,
  selectPayPalState,
} from "../store/stripeSlice";
import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";

const Checkout = ({
  open,
  onClose,
  onCheckoutSuccess,
  onCheckoutFailure,
  customerEmail,
}) => {
  const [plan, setPlan] = useState("P-Free-Plan");
  const [currency] = useState("AUD");
  const dispatch = useDispatch();
  const { loading = false, error = null } =
    useSelector(selectPayPalState) || {};

  useEffect(() => {
    console.log("Current plan:", plan);
  }, [plan]);

  const handlePlanChange = (event) => {
    setPlan(event.target.value);
  };

  const handleSubscription = async (details) => {
    try {
      if (plan === "P-Free-Plan") {
        const result = await dispatch(
          createCustomerAndSubscription({
            plan_id: plan,
            currency,
            payerId: "Free",
            paymentId: "Free",
            customerEmail,
          })
        ).unwrap();
        if (onCheckoutSuccess) onCheckoutSuccess(result);
        return;
      }
      const result = await dispatch(
        createCustomerAndSubscription({
          plan_id: plan,
          currency,
          payerId: details.payerID,
          paymentId: details.id,
          customerEmail,
        })
      ).unwrap();
      if (onCheckoutSuccess) onCheckoutSuccess(result);
    } catch (err) {
      if (onCheckoutFailure) onCheckoutFailure(err.message);
      alert("Failed to create customer and subscription. Please try again.");
    }
  };

  const getPlanAmount = () => {
    switch (plan) {
      case "P-4Y017358ED8944937M4I5B4Y":
        return "39.00";
      case "P-3LT67380E3216371GM37UZEQ":
        return "229.00";
      case "P-9KX58854SL630474KM37UYFA":
        return "400.00";
      default:
        return "0.00";
    }
  };

  const buttonStyle = {
    fontSize: "18px",
    padding: "10px 20px",
    color: "white",
    backgroundColor: "#E29578",
    border: "none",
    cursor: "pointer",
    transition: "background-color 0.3s ease",
    marginRight: 10,
  };

  const buttonHoverStyle = {
    backgroundColor: "#FFDDD2",
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <div style={{ padding: 20 }}>
        <h1>Choose Your Subscription</h1>
        <h2>Free 7 Day Trial</h2>
        <h3>
          Your card will not be charged until after the 7-day trial period. You
          can cancel a subscription at any time.
        </h3>

        <RadioGroup value={plan} onChange={handlePlanChange}>
          <FormControlLabel
            value="P-Free-Plan"
            control={<Radio />}
            label="Free Plan"
          />
          <FormControlLabel
            value="P-4Y017358ED8944937M4I5B4Y"
            control={<Radio />}
            label="Monthly Subscription - $39.00"
          />
          <FormControlLabel
            value="P-3LT67380E3216371GM37UZEQ"
            control={<Radio />}
            label="Six-Monthly Subscription - $229.00"
          />
          <FormControlLabel
            value="P-9KX58854SL630474KM37UYFA"
            control={<Radio />}
            label="Yearly Subscription - $400.00"
          />
        </RadioGroup>

        {plan !== "P-Free-Plan" ? (
          <div style={{ marginTop: 20, marginBottom: 20 }}>
            <div
              style={{
                marginTop: 20,
                marginBottom: 20,
                flexDirection: "row",
                display: "flex",
              }}
            >
              <h2>Payment details</h2>
            </div>

            <PayPalScriptProvider
              options={{
                "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
              }}
            >
              <PayPalButtons
                key={plan}
                createOrder={(data, actions) => {
                  return actions.order.create({
                    purchase_units: [
                      {
                        amount: {
                          value: getPlanAmount(),
                        },
                        payee: {
                          email_address: "dr.dbrown@icloud.com",
                        },
                      },
                    ],
                  });
                }}
                onApprove={async (data, actions) => {
                  await handleSubscription(data);
                }}
                onError={(err) => {
                  console.error("PayPal Checkout Error:", err);
                  alert(
                    "There was an error processing your payment. Please try again."
                  );
                }}
              />
            </PayPalScriptProvider>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              marginTop: 20,
              marginBottom: 20,
              flexDirection: "row",
            }}
          >
            <Button
              onClick={() =>
                handleSubscription({ payerID: "Free", id: "Free" })
              }
              disabled={loading}
              variant="contained"
              color="primary"
              style={buttonStyle}
              onMouseOver={(e) =>
                (e.currentTarget.style.backgroundColor =
                  buttonHoverStyle.backgroundColor)
              }
              onMouseOut={(e) =>
                (e.currentTarget.style.backgroundColor =
                  buttonStyle.backgroundColor)
              }
              onFocus={(e) =>
                (e.currentTarget.style.backgroundColor =
                  buttonHoverStyle.backgroundColor)
              } // Added onFocus
              onBlur={(e) =>
                (e.currentTarget.style.backgroundColor =
                  buttonStyle.backgroundColor)
              } // Added onBlur
            >
              Choose Free Plan
            </Button>
            <Button
              onClick={onClose}
              disabled={loading}
              variant="contained"
              color="secondary"
              style={buttonStyle}
              onMouseOver={(e) =>
                (e.currentTarget.style.backgroundColor =
                  buttonHoverStyle.backgroundColor)
              }
              onMouseOut={(e) =>
                (e.currentTarget.style.backgroundColor =
                  buttonStyle.backgroundColor)
              }
              onFocus={(e) =>
                (e.currentTarget.style.backgroundColor =
                  buttonHoverStyle.backgroundColor)
              } // Added onFocus
              onBlur={(e) =>
                (e.currentTarget.style.backgroundColor =
                  buttonStyle.backgroundColor)
              } // Added onBlur
            >
              Cancel
            </Button>
          </div>
        )}
      </div>
    </Dialog>
  );
};

export default Checkout;

// ProductDescription.js
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./ProductDescription.css";
import { updateProductDescription } from "../../store/ProductsSlice.js";
import DOMPurify from "dompurify";

const ProductDescription = ({ productId }) => {
  const dispatch = useDispatch();
  const product = useSelector((state) =>
    state.products.formData.products.find((p) => p.name === productId)
  );

  const [description, setDescription] = useState(product?.description || "");
  const [usp, setUSP] = useState(product?.usp || "");
  const [useCase, setUseCase] = useState(product?.useCase || "");
  const [emEng, setEmEng] = useState(product?.emEng || "");
  const [techSpec, setTechSpec] = useState(product?.techSpec || "");

  useEffect(() => {
    if (product) {
      setDescription(product.description || "");
      setUSP(product.usp || "");
      setUseCase(product.useCase || "");
      setEmEng(product.emEng || "");
      setTechSpec(product.techSpec || "");
    }
  }, [product]);

  const saveData = () => {
    const sanitizedDescription = DOMPurify.sanitize(description);
    const sanitizedUSP = DOMPurify.sanitize(usp);
    const sanitizedUseCase = DOMPurify.sanitize(useCase);
    const sanitizedEmEng = DOMPurify.sanitize(emEng);
    const sanitizedTechSpec = DOMPurify.sanitize(techSpec);

    dispatch(
      updateProductDescription({
        productId,
        description: sanitizedDescription,
        usp: sanitizedUSP,
        useCase: sanitizedUseCase,
        emEng: sanitizedEmEng,
        techSpec: sanitizedTechSpec,
      })
    );
    alert("Product Description saved successfully!");
  };

  return (
    <div className="product-description-container">
      <h1
        style={{
          fontFamily: "Verdana, sans-serif",
          fontStyle: "italic",
          fontSize: 26,
        }}
      >
        {productId} Overview
      </h1>
      <label className="label">Product Description:</label>
      <textarea
        className="input"
        placeholder="What is the product?"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
      />

      <label className="label">Unique Selling Proposition:</label>
      <textarea
        className="input"
        placeholder="What are the unique features?"
        value={usp}
        onChange={(e) => setUSP(e.target.value)}
      />

      <label className="label">Use Case Scenario:</label>
      <textarea
        className="input"
        placeholder="Describe scenarios where the product can be used?"
        value={useCase}
        onChange={(e) => setUseCase(e.target.value)}
      />

      <label className="label">Emotional Appeal:</label>
      <textarea
        className="input"
        placeholder="How will it harness emotional appeal?"
        value={emEng}
        onChange={(e) => setEmEng(e.target.value)}
      />

      <label className="label">Technical Specifications:</label>
      <textarea
        className="input"
        placeholder="Size, weight, materials and other technical details?"
        value={techSpec}
        onChange={(e) => setTechSpec(e.target.value)}
      />

      <button
        style={{ background: "linear-gradient(to right, #1876D2, #000080)" }}
        className="save-button"
        onClick={saveData}
      >
        Save Product Description
      </button>
    </div>
  );
};

export default ProductDescription;

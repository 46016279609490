// PriceValue.js
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Bar } from "react-chartjs-2";
import "chart.js/auto";
import "./PriceValue.css";
import { updateProductValues } from "../../store/ProductsSlice.js";

const PriceValue = ({ productId }) => {
  const dispatch = useDispatch();
  const product = useSelector((state) =>
    state.products.formData.products.find((p) => p.name === productId)
  );

  const [maxProductValue, setMaxProductValue] = useState(
    product?.maxProductValue || "0"
  );
  const [productUnitCost, setProductUnitCost] = useState(
    product?.productUnitCost || "0"
  );
  const [price, setPrice] = useState(product?.price || "0");
  const [chartData, setChartData] = useState({
    labels: ["Max Product Value", "Product Unit Cost", "Price"],
    datasets: [
      {
        label: "Values",
        data: [0, 0, 0],
        backgroundColor: [
          "rgba(75, 192, 192, 0.6)",
          "rgba(153, 102, 255, 0.6)",
          "rgba(255, 159, 64, 0.6)",
        ],
      },
    ],
  });
  // eslint-disable-next-line
  const [chartConfig, setChartConfig] = useState({
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  });

  useEffect(() => {
    if (product) {
      setMaxProductValue(product.maxProductValue || "0");
      setProductUnitCost(product.productUnitCost || "0");
      setPrice(product.price || "0");
      updateChartData(
        product.maxProductValue || "0",
        product.productUnitCost || "0",
        product.price || "0"
      );
    }
  }, [product]);

  const socialValue = parseFloat(maxProductValue) - parseFloat(price);
  const unitProfitMargin = parseFloat(price) - parseFloat(productUnitCost);

  const saveData = () => {
    dispatch(
      updateProductValues({
        productId,
        maxProductValue,
        productUnitCost,
        price,
      })
    );
    alert("Price-Cost-Value saved successfully!");
  };

  const updateChartData = (maxProductValue, productUnitCost, price) => {
    const data = [
      parseFloat(maxProductValue) || 0,
      parseFloat(productUnitCost) || 0,
      parseFloat(price) || 0,
    ];
    setChartData({
      labels: ["Max Product Value", "Product Unit Cost", "Price"],
      datasets: [
        {
          label: "Values",
          data,
          backgroundColor: [
            "rgba(75, 192, 192, 0.6)",
            "rgba(153, 102, 255, 0.6)",
            "rgba(255, 159, 64, 0.6)",
          ],
        },
      ],
    });
  };

  return (
    <div className="container">
      <h1
        style={{
          display: "inline",
          fontFamily: "Verdana, sans-serif",
          fontStyle: "italic",
          fontSize: 26,
        }}
      >
        Price-Cost-Value Analysis
      </h1>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div className="input-container">
          <div className="input-group">
            <label className="label">Max Product Value:</label>
            <input
              className="input"
              placeholder="Enter max product value"
              type="number"
              value={maxProductValue}
              onChange={(e) => {
                const value = e.target.value;
                setMaxProductValue(value);
                updateChartData(value, productUnitCost, price);
              }}
            />
          </div>
          <div className="input-group">
            <label className="label">Product Unit Cost:</label>
            <input
              className="input"
              placeholder="Enter product unit cost"
              type="number"
              value={productUnitCost}
              onChange={(e) => {
                const value = e.target.value;
                setProductUnitCost(value);
                updateChartData(maxProductValue, value, price);
              }}
            />
          </div>
          <div className="input-group">
            <label className="label">Price:</label>
            <input
              className="input"
              placeholder="Enter proposed price"
              type="number"
              value={price}
              onChange={(e) => {
                const value = e.target.value;
                setPrice(value);
                updateChartData(maxProductValue, productUnitCost, value);
              }}
            />
          </div>
          <div className="input-group">
            <label className="label">Unit Profit Margin:</label>
            <p className="text">{unitProfitMargin}</p>
          </div>
          <div className="input-group">
            <label className="label">Social Value:</label>
            <p className="text">{socialValue}</p>
          </div>
          <button
            style={{
              background: "linear-gradient(to right, #1876D2, #000080)",
            }}
            className="saveButton"
            onClick={saveData}
          >
            Save Price-Cost-Value
          </button>
        </div>
        <div
          style={{ width: "60%", height: "100%" }}
          className="chart-container"
        >
          <Bar data={chartData} options={chartConfig} />
        </div>
      </div>
    </div>
  );
};

export default PriceValue;

import { createSlice } from "@reduxjs/toolkit";
import { fetchBackendData } from "../thunks/fetchBackendData.js"; // Ensure the correct import path

export const GanttChartSlice = createSlice({
  name: "ganttChart",
  initialState: {
    formData: {
      GanttChart: {
        rows: Array.from({ length: 5 }, (_, index) => ({
          id: index,
          task: "",
          resource: "",
          timeframe: "",
          completed: false,
        })),
      },
    },
  },
  reducers: {
    setFormData: (state, action) => {
      state.formData = { ...state.formData, ...action.payload };
    },
    setGanttChartRows: (state, action) => {
      state.formData.GanttChart.rows = action.payload;
    },
    addGanttChartRow: (state) => {
      const newRow = {
        id: state.formData.GanttChart.rows.length,
        task: "",
        resource: "",
        timeframe: "",
        completed: false,
      };
      state.formData.GanttChart.rows.push(newRow);
    },
    removeGanttChartRow: (state, action) => {
      const index = action.payload;
      if (state.formData.GanttChart.rows.length > 1) {
        state.formData.GanttChart.rows.splice(index, 1);
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchBackendData.fulfilled, (state, action) => {
      state.formData = action.payload.formData.ganttChart.formData;
    });
  },
});

export const {
  setFormData,
  setGanttChartRows,
  addGanttChartRow,
  removeGanttChartRow,
} = GanttChartSlice.actions;

export default GanttChartSlice.reducer;

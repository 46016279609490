import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setOperatingBudget,
  incrementOpBudgetYear,
} from "../../store/OperatingBudgetSlice.js";
import DOMPurify from "dompurify";
import "./OperatingBudget.css";
import * as XLSX from "xlsx";

const OperatingBudget = () => {
  const dispatch = useDispatch();
  const reduxOperatingBudget = useSelector(
    (state) => state.operatingBudget.formData.years || {}
  );
  const [currentYear, setCurrentYear] = useState(
    Object.keys(reduxOperatingBudget).pop() || "2023-2024"
  );
  const [budgetItems, setBudgetItems] = useState(
    reduxOperatingBudget[currentYear]?.budgetItems || {}
  );

  useEffect(() => {
    setBudgetItems(reduxOperatingBudget[currentYear]?.budgetItems || {});
  }, [currentYear, reduxOperatingBudget]);

  const handleInputChange = (field, index, value) => {
    const sanitizedValue = DOMPurify.sanitize(value);
    const filteredValue = sanitizedValue.replace(/[^0-9.]/g, "");
    setBudgetItems((prevState) => ({
      ...prevState,
      [field]: prevState[field].map((item, itemIndex) =>
        itemIndex === index ? filteredValue : item
      ),
    }));
  };

  const handleSubmit = () => {
    dispatch(setOperatingBudget({ year: currentYear, budgetItems }));
    alert("Operating Budget Saved");
  };

  const handleIncrementYear = () => {
    const [startYear, endYear] = currentYear.split("-").map(Number);
    const newYear = `${startYear + 1}-${endYear + 1}`;
    if (!reduxOperatingBudget[newYear]) {
      dispatch(incrementOpBudgetYear());
      setBudgetItems({
        salesRevenue: [0, 0, 0, 0],
        otherRevenue: [0, 0, 0, 0],
        salariesWages: [0, 0, 0, 0],
        rentLease: [0, 0, 0, 0],
        additionalExpenses: [0, 0, 0, 0],
      });
    } else {
      setBudgetItems(reduxOperatingBudget[newYear].budgetItems);
    }
    setCurrentYear(newYear);
  };

  const handleDecrementYear = () => {
    const years = Object.keys(reduxOperatingBudget);
    const currentYearIndex = years.indexOf(currentYear);
    if (currentYearIndex > 0) {
      const previousYear = years[currentYearIndex - 1];
      setCurrentYear(previousYear);
      setBudgetItems(reduxOperatingBudget[previousYear]?.budgetItems || {});
    }
  };

  const calculateTotal = (fields) => {
    const totals = [0, 0, 0, 0];
    fields.forEach((field) => {
      for (let i = 0; i < 4; i++) {
        totals[i] += parseFloat(budgetItems[field]?.[i] || 0);
      }
    });
    return totals;
  };

  const totalRevenue = calculateTotal(["salesRevenue", "otherRevenue"]);
  const totalExpenses = calculateTotal([
    "salariesWages",
    "rentLease",
    "additionalExpenses",
  ]);
  const netOperatingIncome = totalRevenue.map(
    (rev, index) => rev - totalExpenses[index]
  );

  const capitalizeWords = (str) => {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const exportToXLSX = () => {
    const data = [];
    const years = Object.keys(reduxOperatingBudget);

    years.forEach((year) => {
      const yearData = reduxOperatingBudget[year].budgetItems;
      Object.keys(yearData).forEach((category) => {
        const row = [
          year,
          capitalizeWords(category.replace(/([A-Z])/g, " $1")),
        ];
        row.push(...yearData[category]);
        data.push(row);
      });
      // Add calculated values for each year
      const totalRevenue = calculateTotal(["salesRevenue", "otherRevenue"]);
      const totalExpenses = calculateTotal([
        "salariesWages",
        "rentLease",
        "additionalExpenses",
      ]);
      const netOperatingIncome = totalRevenue.map(
        (rev, index) => rev - totalExpenses[index]
      );
      data.push([year, "Total Revenue", ...totalRevenue]);
      data.push([year, "Total Expenses", ...totalExpenses]);
      data.push([year, "Net Operating Income", ...netOperatingIncome]);
    });

    const worksheet = XLSX.utils.aoa_to_sheet([
      ["Operating Budget"],
      ["Year", "Category", "Q1", "Q2", "Q3", "Q4"],
      ...data,
    ]);

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Operating Budget");

    XLSX.writeFile(workbook, "OperatingBudget.xlsx");
  };

  return (
    <div className="container">
      <div className="row">
        <h2 style={{ display: "inline", fontSize: 26, fontStyle: "italic" }}>
          Operating Budget for financial year: {currentYear}
        </h2>
        <div className="year-controls">
          <button data-testid="increment-year" onClick={handleIncrementYear}>
            ▲
          </button>
          <button data-testid="decrement-year" onClick={handleDecrementYear}>
            ▼
          </button>
        </div>
      </div>

      <div className="table">
        <div className="header row">
          <span>Category</span>
          <span>Q1</span>
          <span>Q2</span>
          <span>Q3</span>
          <span>Q4</span>
        </div>
        {Object.keys(budgetItems).map((category) => (
          <div key={category} className="row">
            <span style={{ width: 200 }}>
              {capitalizeWords(category.replace(/([A-Z])/g, " $1"))}
            </span>
            {budgetItems[category].map((value, index) => (
              <input
                key={index}
                type="text"
                value={value}
                onChange={(e) =>
                  handleInputChange(category, index, e.target.value)
                }
              />
            ))}
          </div>
        ))}
        <div className="row">
          <span style={{ width: 200 }}>Total Revenue</span>
          {totalRevenue.map((value, index) => (
            <span key={index}>${value}</span>
          ))}
        </div>

        <div className="row">
          <span style={{ width: 200 }}>Total Expenses</span>
          {totalExpenses.map((value, index) => (
            <span key={index}>${value}</span>
          ))}
        </div>

        <div className="row">
          <span style={{ width: 200 }}>Net Operating Income</span>
          {netOperatingIncome.map((value, index) => (
            <span key={index}>${value}</span>
          ))}
        </div>
      </div>
      <div>
        <button className="export-button" onClick={handleSubmit}>
          Save Operating Budget
        </button>
        <button className="export-button" onClick={exportToXLSX}>
          Export to XLSX
        </button>
      </div>
    </div>
  );
};

export default OperatingBudget;

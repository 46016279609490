import axiosInstance from "../components/axiosConfig.js";

const saveFormDataMiddleware = (store) => (next) => async (action) => {
  const actionsToWatch = {
    "inputs/setFormData": "inputs",
    "inputs/setStartupCosts": "inputs",
    "operatingBudget/setOperatingBudget": "operatingBudget",
    "cashFlow/setCashFlow": "cashFlow",
    "ganttChart/setFormData": "ganttChart",
    "competitors/setFormData": "competitors",
    "hiring/addEmployee": "hiring",
    "hiring/updateEmployee": "hiring",
    "hiring/removeEmployee": "hiring",
    "hiring/confirmEmployee": "hiring",
    "hiring/updateHRPlan": "hiring",
    "incomeStatement/setIncomeStatement": "incomeStatement",
    "capitalisationTable/setCapitalisationTable": "capitalisationTable",
    "balanceSheet/setBalanceSheet": "balanceSheet",
    "marketingBudget/setMarketingBudget": "marketingBudget",
    "riskAnalysis/addRisk": "riskAnalysis",
    "riskAnalysis/updateRisk": "riskAnalysis",
    "riskAnalysis/removeRisk": "riskAnalysis",
    "riskAnalysis/setMitigationPlan": "riskAnalysis",
    "riskAnalysis/clearRisks": "riskAnalysis",
    "products/saveProduct": "products",
    "products/addFeature": "products",
    "products/updateProduct": "products",
    "products/updateProductValues": "products",
    "products/updateProductDescription": "products",
    "products/deleteProduct": "products",
  };

  if (actionsToWatch[action.type]) {
    // alert('Middleware triggered for action:');

    const result = next(action); // Dispatch the action to update Redux state first
    const state = store.getState();
    const { csrfToken } = state.auth; // Assuming auth contains the token and username

    // Construct the formData object from the state
    const formData = {
      inputs: state.inputs,
      operatingBudget: state.operatingBudget,
      cashFlow: state.cashFlow,
      ganttChart: state.ganttChart,
      competitors: state.competitors,
      hiring: state.hiring,
      incomeStatement: state.incomeStatement,
      capitalisationTable: state.capitalisationTable,
      balanceSheet: state.balanceSheet,
      marketingBudget: state.marketingBudget,
      riskAnalysis: state.riskAnalysis,
      products: state.products,
    };

    // Display the payload using alert
    // alert('Payload:' + JSON.stringify(formData)); // Log the payload

    console.log("Action triggered:", action);
    console.log("Updated FormData state:", formData);

    try {
      console.log("Sending POST request to /formData/save/");
      console.log("Payload:", { formData }); // Log the payload

      const response = await axiosInstance.post(
        "https://bossentrepreneur.com.au/bossData/save/",
        { formData }, // Sending the unnamed object correctly
        {
          headers: {
            "Content-Type": "application/json",
            "csrf-token": csrfToken,
          },
          withCredentials: true,
        }
      );
      console.log("Server response:", response.data); // Log only the data part of the response
    } catch (error) {
      if (error.response) {
        console.error("Server responded with error:", error.response.data);
        console.log(
          `Failed to save formData data: ${error.response.data.message}`
        );
      } else if (error.request) {
        console.error("No response received:", error.request);
        console.log("Failed to save formData data: No response from server");
      } else {
        console.error("Error:", error.message);
        console.log(`Failed to save formData data: ${error.message}`);
      }
    }

    return result;
  }

  return next(action);
};

export default saveFormDataMiddleware;

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addFeature,
  updateFeature,
  deleteFeature,
} from "../../store/ProductsSlice.js";
import { VictoryChart, VictoryLine, VictoryAxis } from "victory";
import "./ValueProfile.css";
import { v4 as uuidv4 } from "uuid";
import DOMPurify from "dompurify";

const ValueProfile = ({ productId }) => {
  const dispatch = useDispatch();
  const product = useSelector((state) =>
    state.products.formData.products.find((p) => p.name === productId)
  );

  const [feature, setFeature] = useState(product.feature || []);
  const [newFeatureName, setNewFeatureName] = useState("");
  const [newFeatureValue, setNewFeatureValue] = useState("1");
  const [error, setError] = useState("");
  const [addFeatureState, setAddFeatureState] = useState(null);
  const [existingFeature, setExistingFeature] = useState(null);

  useEffect(() => {}, [product]);

  const handleAddFeature = (name, value) => {
    const sanitizedName = DOMPurify.sanitize(name); // Sanitize feature name
    const newFeature = {
      id: uuidv4(),
      name: sanitizedName,
      value: parseInt(value, 10),
    };
    dispatch(addFeature({ productId, feature: newFeature }));
    setFeature([...feature, newFeature]);
    setAddFeatureState(false);
    setNewFeatureName("");
    setNewFeatureValue("1");
    alert("Feature added successfully");
  };

  const handleEditFeature = (id, name, updatedValue) => {
    const sanitizedName = DOMPurify.sanitize(name); // Sanitize feature name
    const updatedFeature = {
      id,
      name: sanitizedName,
      value: parseInt(updatedValue, 10),
    };
    dispatch(updateFeature({ productId, feature: updatedFeature }));
    setFeature((prevFeatures) =>
      prevFeatures.map((f) => (f.id === id ? updatedFeature : f))
    );
    setExistingFeature(null);
  };

  const handleRemoveFeature = (id) => {
    dispatch(deleteFeature({ productId, featureId: id }));
    setFeature((prevFeatures) => prevFeatures.filter((f) => f.id !== id));
    setExistingFeature(null);
  };

  const cancel = () => {
    setAddFeatureState(false);
  };

  const chartData = feature.map((feat) => ({
    x: feat.name,
    y: parseInt(feat.value, 10),
  }));

  return (
    <div className="container">
      <div style={{ alignItems: "flex-start", justifyContent: "flex-start" }}>
        <h1
          style={{
            display: "inline",
            fontFamily: "Verdana, sans-serif",
            fontStyle: "italic",
            fontSize: 26,
          }}
        >
          Feature Value Profile
        </h1>
        <p className="regularText">
          1. Is this important and we need to improve it?
        </p>
        <p className="regularText">
          2. Is this a feature that could be eliminated?
        </p>
        <p className="regularText">
          3. What features can we reduce because they don’t create value?
        </p>
        <p className="regularText">
          4. What new features can we create that have never been offered?
        </p>
      </div>
      <div className="buttonContainer">
        <button
          style={{
            background: "linear-gradient(to right, #1876D2, #000080)",
            color: "white",
            fontSize: 18,
            padding: 10,
            width: "100%",
          }}
          onClick={() => setAddFeatureState(true)}
        >
          Add Feature
        </button>
      </div>
      {addFeatureState && (
        <div>
          <input
            className="input"
            placeholder="Feature Name"
            onChange={(e) => setNewFeatureName(e.target.value)}
            value={newFeatureName}
          />
          <input
            className="input"
            placeholder="Value (1-10)"
            type="number"
            onChange={(e) => {
              const filteredText = e.target.value.replace(/[^0-9]/g, "");
              setNewFeatureValue(filteredText);
            }}
            onBlur={() => {
              const trimmedValue = newFeatureValue.trim();
              const value = parseInt(trimmedValue, 10);
              if (trimmedValue === "") {
                setError("Please enter a value.");
              } else if (isNaN(value) || value < 1 || value > 10) {
                setError("Value must be between 1 and 10.");
              } else {
                setError("");
              }
            }}
          />
          {error ? <p style={{ color: "red" }}>{error}</p> : null}
          <div className="inputRow">
            <button
              style={{
                background: "linear-gradient(to right, #1876D2, #000080)",
              }}
              className="saveButton"
              onClick={() => {
                if (!error) {
                  const ValidFeature =
                    parseInt(newFeatureValue, 10) >= 1 &&
                    parseInt(newFeatureValue, 10) <= 10;
                  if (ValidFeature) {
                    handleAddFeature(newFeatureName, newFeatureValue);
                    setNewFeatureName("");
                    setNewFeatureValue("1");
                  } else {
                    setError("Value must be between 1 and 10.");
                  }
                }
              }}
              disabled={error !== ""}
            >
              Add Feature
            </button>
            <button
              style={{
                background: "linear-gradient(to right, #1876D2, #000080)",
              }}
              className="saveButton"
              onClick={cancel}
            >
              Cancel
            </button>
          </div>
        </div>
      )}
      {feature.map((f) => (
        <div key={f.id}>
          {existingFeature === f.id ? (
            <>
              <div>
                <input
                  className="input"
                  placeholder={f.name}
                  onChange={(e) => setNewFeatureName(e.target.value)}
                />
                <input
                  className="input"
                  placeholder={f.value}
                  type="number"
                  onChange={(e) => {
                    const filteredText = e.target.value.replace(/[^0-9]/g, "");
                    setNewFeatureValue(filteredText);
                  }}
                  onBlur={() => {
                    const trimmedValue = newFeatureValue.trim();
                    const value = parseInt(trimmedValue, 10);
                    if (trimmedValue === "") {
                      setError("Please enter a value.");
                    } else if (isNaN(value) || value < 1 || value > 10) {
                      setError("Value must be between 1 and 10.");
                    } else {
                      setError("");
                    }
                  }}
                />
                {error ? <p style={{ color: "red" }}>{error}</p> : null}
              </div>
              <div className="inputRow">
                <button
                  style={{
                    background: "linear-gradient(to right, #1876D2, #000080)",
                  }}
                  className="saveButton"
                  onClick={() => {
                    if (!error) {
                      const ValueValid =
                        parseInt(newFeatureValue, 10) >= 1 &&
                        parseInt(newFeatureValue, 10) <= 10;
                      if (ValueValid) {
                        handleEditFeature(
                          f.id,
                          newFeatureName,
                          newFeatureValue
                        );
                        setNewFeatureName("");
                        setNewFeatureValue("1");
                        setExistingFeature(null);
                      } else {
                        setError("Value must be between 1 and 10.");
                      }
                    }
                  }}
                  disabled={error !== ""}
                >
                  Update Feature
                </button>
                <button
                  style={{
                    background: "linear-gradient(to right, #1876D2, #000080)",
                  }}
                  className="saveButton"
                  onClick={() => handleRemoveFeature(f.id)}
                >
                  Delete Feature
                </button>
              </div>
            </>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                fontSize: 18,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <button
                style={{ backgroundColor: "white" }}
                onClick={() => {
                  setExistingFeature(f.id);
                  setNewFeatureName(f.name);
                  setNewFeatureValue(f.value);
                }}
              >
                <p
                  style={{
                    color: "blue",
                    backgroundColor: "white",
                    fontSize: 18,
                  }}
                >
                  Feature:
                </p>
              </button>
              <p style={{ marginRight: 50 }}>{f.name}</p>
              <div
                style={{
                  flexDirection: "row",
                  fontSize: 18,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p className="featureText">Value: {f.value}</p>
              </div>
            </div>
          )}
        </div>
      ))}
      {feature.length > 0 && (
        <div
          style={{
            padding: 20,
            width: "90%",
            height: "90%",
            alignSelf: "center",
          }}
        >
          <VictoryChart
            domain={{ y: [0, 10] }}
            style={{ parent: { margin: 20 } }}
          >
            <VictoryAxis
              label="Feature Name"
              style={{
                axisLabel: { padding: 30 },
                tickLabels: { angle: -35, fontSize: 16, padding: 10 },
              }}
            />
            <VictoryAxis
              dependentAxis
              domain={[1, 10]}
              label="Value"
              style={{
                axisLabel: { padding: 35 },
                tickLabels: { fontSize: 10 },
              }}
            />
            <VictoryLine
              data={chartData}
              style={{
                data: { stroke: "#c43a31" },
              }}
            />
          </VictoryChart>
        </div>
      )}
    </div>
  );
};

export default ValueProfile;

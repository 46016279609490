import { createSlice } from "@reduxjs/toolkit";
import { fetchBackendData } from "../thunks/fetchBackendData.js"; // Ensure the correct import path

export const CapitalisationTableSlice = createSlice({
  name: "capitalisationTable",
  initialState: {
    formData: {
      CapitalisationTable: {
        rows: Array(5)
          .fill()
          .map((_, i) => ({
            id: i + 1,
            shareholder: "",
            secType: "",
            numShares: 0,
            priceShare: 0,
          })),
        totalShares: 0,
        sumInvest: 0,
        totalOwnership: 0,
      },
    },
  },
  reducers: {
    setCapitalisationTable: (state, action) => {
      state.formData.CapitalisationTable = {
        ...state.formData.CapitalisationTable,
        ...action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchBackendData.fulfilled, (state, action) => {
      state.formData = action.payload.formData.capitalisationTable.formData;
    });
  },
});

export const { setCapitalisationTable } = CapitalisationTableSlice.actions;

export default CapitalisationTableSlice.reducer;

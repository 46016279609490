import { createSlice } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";
import { fetchBackendData } from "../thunks/fetchBackendData.js"; // Ensure the correct import path

const initialState = {
  operationalRisk: [],
  technologyRisk: [],
  intellectualPropertyRisk: [],
  positionRisk: [],
  allRisks: [],
  mitigationPlan: "",
};

const RiskAnalysisSlice = createSlice({
  name: "riskAnalysis",
  initialState,
  reducers: {
    addRisk: (state, action) => {
      const { type, risk } = action.payload;
      state[type].push({ ...risk, id: uuidv4() });
      state.allRisks = [
        ...state.operationalRisk,
        ...state.technologyRisk,
        ...state.intellectualPropertyRisk,
        ...state.positionRisk,
      ];
    },
    updateRisk: (state, action) => {
      const { type, id, risk } = action.payload;
      state[type] = state[type].map((r) =>
        r.id === id ? { ...r, ...risk } : r
      );
      state.allRisks = [
        ...state.operationalRisk,
        ...state.technologyRisk,
        ...state.intellectualPropertyRisk,
        ...state.positionRisk,
      ];
    },
    removeRisk: (state, action) => {
      const { type, id } = action.payload;
      state[type] = state[type].filter((r) => r.id !== id);
      state.allRisks = [
        ...state.operationalRisk,
        ...state.technologyRisk,
        ...state.intellectualPropertyRisk,
        ...state.positionRisk,
      ];
    },
    setMitigationPlan: (state, action) => {
      state.mitigationPlan = action.payload;
    },
    clearRisks: (state) => {
      state.operationalRisk = [];
      state.technologyRisk = [];
      state.intellectualPropertyRisk = [];
      state.positionRisk = [];
      state.allRisks = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchBackendData.fulfilled, (state, action) => {
      const riskAnalysis = action.payload.formData.riskAnalysis;
      // alert('fetchBackendData.fulfilled action payload:' + JSON.stringify(action.payload));
      state.operationalRisk = riskAnalysis.operationalRisk || [];
      state.technologyRisk = riskAnalysis.technologyRisk || [];
      state.intellectualPropertyRisk =
        riskAnalysis.intellectualPropertyRisk || [];
      state.positionRisk = riskAnalysis.positionRisk || [];
      state.mitigationPlan = riskAnalysis.mitigationPlan || "";
      state.allRisks = [
        ...state.operationalRisk,
        ...state.technologyRisk,
        ...state.intellectualPropertyRisk,
        ...state.positionRisk,
      ];
    });
  },
});

export const {
  addRisk,
  updateRisk,
  removeRisk,
  setMitigationPlan,
  clearRisks,
} = RiskAnalysisSlice.actions;

export default RiskAnalysisSlice.reducer;

import React, { useState } from "react";
import "./FrequentlyAskedQuestions.css"; // Import the CSS file for styling
import { Button } from "@mui/material";

const faqs = [
  {
    question: "What is the purpost of this website",
    answer:
      "To facilicate new business idea iteration nad organisation, safely and securely.",
  },
  {
    question: "Are Payments Secure?",
    answer: "Yes, all payments are secure and encrypted through PayPal.",
  },
  {
    question: "Is my information secure?",
    answer:
      "Yes, all information is encrypted in transit and at rest in storage.",
  },
  {
    question: "What are your companies core values?",
    answer: "Integrity, Respect and Continuous Improvement",
  },
  {
    question: "Is my privacy respected?",
    answer:
      "Your information is not shared with any third parties. We accept its and outlandish idea, but we do not analyse your personal data.",
  },
];

const FrequentlyAskedQuestions = ({ onClose }) => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const handleBack = () => {
    onClose();
  };

  return (
    <div className="faq-container">
      <h1 style={{ fontFamily: "Shantell Sans, sans-serif" }}>
        Frequently Asked Questions
      </h1>
      <div className="faq-list">
        {faqs.map((faq, index) => (
          <div key={index} className="faq-item">
            <div
              className="faq-question"
              onClick={() => toggleFAQ(index)}
              style={{ fontFamily: "Verdana, sans-serif" }}
            >
              {faq.question}
              <span className="faq-toggle">
                {openIndex === index ? "-" : "+"}
              </span>
            </div>
            {openIndex === index && (
              <div
                className="faq-answer"
                style={{ fontFamily: "Verdana, sans-serif" }}
              >
                {faq.answer}
              </div>
            )}
          </div>
        ))}
      </div>
      <Button
        onClick={handleBack}
        style={{ fontFamily: "Shantell Sans, sans-serif" }}
      >
        Back
      </Button>
    </div>
  );
};

export default FrequentlyAskedQuestions;

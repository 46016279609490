import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  TextField,
  Button,
  Typography,
  Container,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import axiosInstance from "./axiosConfig.js";
import { logout } from "../store/authSlice"; // Assuming you have a logout action in your auth slice

const Settings = ({ onLogout }) => {
  // Add onLogout prop
  const dispatch = useDispatch();
  const navigate = useNavigate(); // Hook for navigation
  const user = useSelector((state) => state.auth.user);

  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [open, setOpen] = useState(false); // State for the confirmation dialog

  const handlePasswordUpdate = async () => {
    if (newPassword !== confirmPassword) {
      setError("New password and confirm password do not match");
      return;
    }

    try {
      const response = await axiosInstance.post(
        "https://bossentrepreneur.com.au/bossauth/update-password",
        {
          currentPassword,
          newPassword,
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );

      console.log("Password update response:", response.data);
      setSuccess("Password updated successfully");
      setError("");
      setCurrentPassword("");
      setNewPassword("");
      setConfirmPassword("");
    } catch (err) {
      console.error(
        "Error during password update:",
        err.response || err.message
      );
      setError(err.response?.data.message || "Password update failed");
      setSuccess("");
    }
  };

  const handleClose = () => {
    navigate("/componentA"); // Change to the desired route when closing
  };

  const handleDelete = async () => {
    try {
      await axiosInstance.delete(
        "https://bossentrepreneur.com.au/bossauth/delete-user",
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );

      localStorage.removeItem("token");
      dispatch(logout()); // Ensure the user is logged out in the Redux state
      onLogout(); // Call the onLogout prop to update parent state
    } catch (err) {
      console.error(
        "Error during account deletion:",
        err.response || err.message
      );
      setError(err.response?.data.message || "Account deletion failed");
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleDialogClose = () => {
    setOpen(false);
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box mt={5} display="flex" flexDirection="column" alignItems="center">
        <Typography component="h1" variant="h5">
          Settings
        </Typography>
        {user && (
          <>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              label="Username"
              value={user.name}
              disabled
            />
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              label="Email"
              value={user.email}
              disabled
            />
          </>
        )}
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          label="Current Password"
          type="password"
          value={currentPassword}
          onChange={(e) => setCurrentPassword(e.target.value)}
        />
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          label="New Password"
          type="password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
        />
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          label="Confirm New Password"
          type="password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
        {error && <Typography color="error">{error}</Typography>}
        {success && <Typography color="primary">{success}</Typography>}
        <Box mt={2} width="100%" display="flex" justifyContent="space-between">
          <Button
            style={{ margin: 5 }}
            variant="contained"
            color="primary"
            onClick={handlePasswordUpdate}
          >
            Update Password
          </Button>
          <Button
            style={{ margin: 5 }}
            variant="contained"
            color="primary"
            onClick={handleClose}
          >
            Close
          </Button>
          <Button
            style={{ margin: 5 }}
            variant="contained"
            color="error"
            onClick={handleClickOpen}
          >
            Delete Account
          </Button>
        </Box>
      </Box>

      <Dialog open={open} onClose={handleDialogClose}>
        <DialogTitle>Confirm Account Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            All your user data will be deleted. Are you sure you want to
            proceed?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default Settings;

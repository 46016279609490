import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setFormData } from "../store/inputsSlice.js";
import DOMPurify from "dompurify";
import "./styles/Style.css";

const MarketAnalysis = () => {
  const dispatch = useDispatch();
  const reduxFormData = useSelector(
    (state) => state.inputs.formData.MarketAnalysis
  );

  const [formData, setLocalFormData] = useState(reduxFormData);

  useEffect(() => {
    setLocalFormData(reduxFormData);
  }, [reduxFormData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    // Sanitize the input value
    const sanitizedValue = DOMPurify.sanitize(value);
    setLocalFormData({
      ...formData,
      [name]: sanitizedValue,
    });
  };

  const handleSubmit = () => {
    dispatch(setFormData({ MarketAnalysis: formData }));
    alert("Market Analysis Saved");
  };

  return (
    <div className="content">
      <h2 className="title">Market Analysis</h2>
      <div className="column">
        <label className="subheading">Target Market:</label>
        <textarea
          className="textarea"
          type="text"
          name="input1"
          value={formData.input1}
          onChange={handleChange}
          placeholder="What is the target market?"
        />
        <label className="subheading">Customer Demographics:</label>
        <textarea
          className="textarea"
          type="text"
          name="input2"
          value={formData.input2}
          onChange={handleChange}
          placeholder="Who are the customers?"
        />
        <label className="subheading">Market Trends:</label>
        <textarea
          className="textarea"
          type="text"
          name="input3"
          value={formData.input3}
          onChange={handleChange}
          placeholder="What trends are emerging?"
        />
        <label className="subheading">Customer Needs:</label>
        <textarea
          className="textarea"
          type="text"
          name="input4"
          value={formData.input4}
          onChange={handleChange}
          placeholder="What do the custoemrs need?"
        />
        <label className="subheading">Market Entry Barriers:</label>
        <textarea
          className="textarea"
          type="text"
          name="input5"
          value={formData.input5}
          onChange={handleChange}
          placeholder="What are the barriers to entering the market?"
        />
        <label className="subheading">Strategies to Appeal to Market:</label>
        <textarea
          className="textarea"
          type="text"
          name="input6"
          value={formData.input6}
          onChange={handleChange}
          placeholder="How can I appeal to the target market?"
        />
        <button
          style={{ background: "linear-gradient(to right, #1876D2, #000080)" }}
          className="button"
          onClick={handleSubmit}
        >
          Save Market Analysis
        </button>
      </div>
    </div>
  );
};

export default MarketAnalysis;

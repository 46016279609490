import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setFormData } from "../store/inputsSlice.js";
import DOMPurify from "dompurify";
import "./styles/Style.css";

const BusinessPlan = () => {
  const dispatch = useDispatch();
  const reduxFormData = useSelector(
    (state) => state.inputs.formData.BusinessPlan
  );
  const [formData, setLocalFormData] = useState(reduxFormData);

  useEffect(() => {
    setLocalFormData(reduxFormData);
  }, [reduxFormData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    // Sanitize the input value
    const sanitizedValue = DOMPurify.sanitize(value);
    setLocalFormData({
      ...formData,
      [name]: sanitizedValue,
    });
  };

  const handleSubmit = () => {
    dispatch(setFormData({ BusinessPlan: formData }));
    alert("Business Plan Saved");
  };

  return (
    <div className="content">
      <h2 className="title">Business Plan</h2>
      <div className="column">
        <label className="subheading">The Need:</label>
        <textarea
          className="textarea"
          type="text"
          name="input1"
          value={formData.input1}
          onChange={handleChange}
          placeholder="What is the need for your product?"
        />
        <label className="subheading">The Product Ideas:</label>
        <textarea
          className="textarea"
          type="text"
          name="input2"
          value={formData.input2}
          onChange={handleChange}
          placeholder="How will you meet this need?"
        />
        <label className="subheading">The Product Features:</label>
        <textarea
          className="textarea"
          type="text"
          name="input3"
          value={formData.input3}
          onChange={handleChange}
          placeholder="What are the unique features?"
        />
        <label className="subheading">The Market:</label>
        <textarea
          className="textarea"
          type="text"
          name="input4"
          value={formData.input4}
          onChange={handleChange}
          placeholder="Who specifically are your target market?"
        />
        <label className="subheading">Future of the Idea:</label>
        <textarea
          className="textarea"
          type="text"
          name="input5"
          value={formData.input5}
          onChange={handleChange}
          placeholder="Where is the market heading?"
        />

        <button
          style={{ background: "linear-gradient(to right, #1876D2, #000080)" }}
          className="button"
          onClick={handleSubmit}
        >
          Save Business Plan
        </button>
      </div>
    </div>
  );
};

export default BusinessPlan;

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setStartupCosts } from "../../store/inputsSlice.js";
import DOMPurify from "dompurify";
import "./StartupCosts.css";
import * as XLSX from "xlsx";

const StartupCosts = () => {
  const dispatch = useDispatch();
  const reduxStartupCosts = useSelector(
    (state) => state.inputs.formData.StartupCosts
  );
  const [rows, setRows] = useState(reduxStartupCosts.rows);
  const [totalEstimatedCost, setTotalEstimatedCost] = useState(
    reduxStartupCosts.totalEstimatedCost
  );
  const [totalActualCost, setTotalActualCost] = useState(
    reduxStartupCosts.totalActualCost
  );

  useEffect(() => {
    setRows(reduxStartupCosts.rows);
    setTotalEstimatedCost(reduxStartupCosts.totalEstimatedCost);
    setTotalActualCost(reduxStartupCosts.totalActualCost);
  }, [reduxStartupCosts]);

  const updateRow = (index, field, value) => {
    const sanitizedValue = DOMPurify.sanitize(value);
    const newRows = rows.map((row, idx) => {
      if (idx === index) {
        return { ...row, [field]: sanitizedValue };
      }
      return row;
    });
    setRows(newRows);
  };

  const calculateTotals = () => {
    const totalEstimated = rows.reduce(
      (acc, curr) => acc + Number(curr.estimatedCost),
      0
    );
    const totalActual = rows.reduce(
      (acc, curr) => acc + Number(curr.actualCost),
      0
    );
    setTotalEstimatedCost(totalEstimated);
    setTotalActualCost(totalActual);
  };

  useEffect(() => {
    calculateTotals();
    // eslint-disable-next-line
  }, [rows]);

  const handleSubmit = () => {
    dispatch(setStartupCosts({ rows, totalEstimatedCost, totalActualCost }));
    alert("Startup Costs Saved");
  };

  const addRow = () => {
    setRows([
      ...rows,
      {
        id: rows.length + 1,
        category: "",
        item: "",
        estimatedCost: 0,
        actualCost: 0,
        note: "",
      },
    ]);
  };

  const removeRow = () => {
    if (rows.length > 1) {
      const updatedRows = rows.slice(0, -1);
      setRows(updatedRows);
    }
  };

  const exportToXlsx = () => {
    const wsData = [
      ["Startup Costs"], // Title
      [
        "Category",
        "Item Description",
        "Estimated Cost",
        "Actual Cost",
        "Notes",
      ],
      ...rows.map((row) => [
        row.category,
        row.item,
        row.estimatedCost,
        row.actualCost,
        row.note,
      ]),
      ["", "", "Total Estimated Cost", totalEstimatedCost],
      ["", "", "Total Actual Cost", totalActualCost],
    ];
    const ws = XLSX.utils.aoa_to_sheet(wsData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Startup Costs");
    XLSX.writeFile(wb, "StartupCosts.xlsx");
  };

  return (
    <div className="container">
      <h1
        style={{
          display: "inline",
          flex: 1,
          fontSize: 26,
          fontStyle: "italic",
        }}
      >
        Startup Costs
      </h1>
      <div className="row header">
        <span>Category</span>
        <span>Item Description</span>
        <span>Estimated Cost</span>
        <span>Actual Cost</span>
        <span>Notes</span>
      </div>
      {rows.map((row, index) => (
        <div key={row.id} className="row">
          <input
            type="text"
            value={row.category}
            onChange={(e) => updateRow(index, "category", e.target.value)}
          />
          <input
            type="text"
            value={row.item}
            onChange={(e) => updateRow(index, "item", e.target.value)}
          />
          <input
            type="number"
            value={row.estimatedCost}
            onChange={(e) => updateRow(index, "estimatedCost", e.target.value)}
          />
          <input
            type="number"
            value={row.actualCost}
            onChange={(e) => updateRow(index, "actualCost", e.target.value)}
          />
          <input
            type="text"
            value={row.note}
            onChange={(e) => updateRow(index, "note", e.target.value)}
          />
        </div>
      ))}
      <div className="totals">
        <span>Total Estimated Cost: ${totalEstimatedCost}</span>
        <span style={{ marginLeft: 100 }}>
          Total Actual Cost: ${totalActualCost}
        </span>
      </div>
      <div className="buttons">
        <button onClick={addRow}>Add Row</button>
        <button onClick={handleSubmit}>Save Startup Costs</button>
        <button onClick={exportToXlsx}>Export to Excel</button>
        <button onClick={removeRow}>Remove Row</button>
      </div>
    </div>
  );
};

export default StartupCosts;

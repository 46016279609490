import React from 'react';
import Image from '../Img/BossBrains.png'
const Feedback = () => {

  return (
    <div style={{display: 'flex', flexDirection: 'row'}}>
    <div style={{flexDirection: 'column'}}>
      <h2 style={{marginLeft: '5%', fontFamily: 'Verdana', fontStyle: 'italic'}}>Feedback</h2>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <p style={{ marginLeft: '5%', marginRight: '5%'}}>We value constructive feedback because it is essential for growth and improvement. 
            Constructive feedback provides specific, actionable insights that help individuals and 
            teams understand how to improve our product. 
          </p>
          <p style={{ marginLeft: '5%', marginRight: '5%'}}>Please send feedback via email to: email@example.com</p>
        </div>
    </div>
<div>
  <img style={{width: '95%', marginTop: '10%', marginRight: '5%', borderRadius: 10}} src={Image} alt="" />
</div>
    </div>
  );
};

export default Feedback;

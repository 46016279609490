import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../components/axiosConfig.js";

export const loginUser = createAsyncThunk(
  "auth/loginUser",
  async ({ email, password }, { rejectWithValue }) => {
    try {
      console.log("Attempting login with email:", email);
      const response = await axiosInstance.post(
        "https://bossentrepreneur.com.au/bossauth/login",
        {
          email: email,
          password: password,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      alert("Login Error:" + JSON.stringify(error));
      console.error("Login Error:", error); // Log the error
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue("Login failed");
      }
    }
  }
);

const initialState = {
  user: null,
  token: localStorage.getItem("token") || null,
  status: "idle",
  error: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      state.user = null;
      state.token = null;
      localStorage.removeItem("token");
      state.status = "idle";
      state.error = null;
    },
    resetState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.status = "loading";
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        console.log("Fulfilled Action Payload:", action.payload);
        state.status = "succeeded";
        state.user = {
          _id: action.payload._id,
          name: action.payload.name,
          email: action.payload.email,
        };
        state.csrfToken = action.payload.csrfToken;
        state.error = null;
        console.log("login user fulfilled with authSlice", state.token + JSON);
      })
      .addCase(loginUser.rejected, (state, action) => {
        console.error("Rejected Action Payload:", action.payload);
        state.status = "failed";
        state.error = action.payload;
        alert("Login user rejected");
      });
  },
});

export const { logout, resetState } = authSlice.actions;

export default authSlice.reducer;

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setCapitalisationTable } from "../../store/CapitalisationTableSlice.js";
import DOMPurify from "dompurify";
import "./CapitalisationTable.css";
import * as XLSX from "xlsx";

const CapitalisationTable = () => {
  const dispatch = useDispatch();
  const reduxCapitalisationTable = useSelector(
    (state) => state.capitalisationTable.formData.CapitalisationTable
  );
  const [rows, setRows] = useState(reduxCapitalisationTable.rows);
  const [totalShares, setTotalShares] = useState(
    reduxCapitalisationTable.totalShares
  );
  const [sumInvest, setSumInvest] = useState(
    reduxCapitalisationTable.sumInvest
  );
  const [totalOwnership, setTotalOwnership] = useState(
    reduxCapitalisationTable.totalOwnership
  );
  const [totalInvest, setTotalInvest] = useState(0);
  const [test, setTest] = useState(null);

  useEffect(() => {
    const calculateTotals = () => {
      const totalInvestment = rows.reduce(
        (acc, row) => acc + row.numShares * row.priceShare,
        0
      );
      const updatedRows = rows.map((row) => ({
        ...row,
        totalInvest: row.numShares * row.priceShare,
        percentOwn:
          totalInvestment > 0
            ? ((row.numShares * row.priceShare) / totalInvestment) * 100
            : 0,
      }));
      setTotalShares(updatedRows.reduce((acc, row) => acc + row.numShares, 0));
      setSumInvest(totalInvestment);
      setTotalOwnership(
        updatedRows.reduce((acc, row) => acc + row.percentOwn, 0)
      );
      setRows(updatedRows);
      // eslint-disable-next-line
      setTotalInvest(totalInvest);
    };
    calculateTotals();
    // eslint-disable-next-line
  }, [test]);

  const updateRow = (index, field, value) => {
    const sanitizedValue = DOMPurify.sanitize(value);
    const newValue =
      field === "numShares" || field === "priceShare"
        ? parseFloat(sanitizedValue) || 0
        : sanitizedValue;
    const newRows = rows.map((row, idx) => {
      if (idx === index) {
        return { ...row, [field]: newValue };
      }
      return row;
    });
    setRows(newRows);
    setTest(newRows);
  };

  const handleSubmit = () => {
    dispatch(
      setCapitalisationTable({ rows, totalShares, sumInvest, totalOwnership })
    );
    alert("Capitalisation Table Saved");
  };

  const addRow = () => {
    setRows([
      ...rows,
      {
        id: rows.length + 1,
        shareholder: "",
        secType: "",
        numShares: 0,
        priceShare: 0,
      },
    ]);
  };

  const removeRow = () => {
    if (rows.length > 1) {
      const updatedRows = rows.slice(0, -1);
      setRows(updatedRows);
    }
  };

  const exportToXLSX = () => {
    const data = rows.map((row) => [
      row.shareholder,
      row.secType,
      row.numShares,
      ((row.numShares / totalShares) * 100 || 0).toFixed(2),
      row.priceShare,
      (row.numShares * row.priceShare || 0).toFixed(2),
    ]);

    const worksheet = XLSX.utils.aoa_to_sheet([
      ["Capitalisation Table"],
      [
        "Shareholder",
        "Type of Security",
        "Number of Shares",
        "% Ownership",
        "Price per Share",
        "Total Investment",
      ],
      ...data,
    ]);

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Capitalisation Table");

    XLSX.writeFile(workbook, "CapitalisationTable.xlsx");
  };

  return (
    <div>
      <h2
        style={{
          display: "inline",
          flex: 1,
          fontSize: 26,
          fontStyle: "italic",
        }}
      >
        Capitalisation Table
      </h2>
      <div className="header">
        <div className="column">Shareholder</div>
        <div className="column">Type of Security</div>
        <div className="column">Number of Shares</div>
        <div className="column">% Ownership</div>
        <div className="column">Price per Share</div>
        <div className="column">Total Investment</div>
      </div>
      {rows.map((row, index) => (
        <div key={row.id} className="row">
          <input
            onChange={(e) => updateRow(index, "shareholder", e.target.value)}
            value={row.shareholder}
            type="text"
          />
          <input
            onChange={(e) => updateRow(index, "secType", e.target.value)}
            value={row.secType}
            type="text"
          />
          <input
            onChange={(e) => updateRow(index, "numShares", e.target.value)}
            value={row.numShares}
            type="number"
          />
          <div style={{ width: "16%", textAlign: "center" }}>
            {((row.numShares / totalShares) * 100 || 0).toFixed(2)}%
          </div>
          <input
            onChange={(e) => updateRow(index, "priceShare", e.target.value)}
            value={row.priceShare}
            type="number"
          />
          <div style={{ width: "16%", textAlign: "center" }}>
            {(row.numShares * row.priceShare || 0).toFixed(2)}
          </div>
        </div>
      ))}
      <div className="box">
        <div className="rowtwo">
          <div className="text-head">Total Investment:</div>
          <div style={{ marginLeft: 10 }}>{sumInvest.toFixed(2)}</div>
        </div>
        <div className="rowtwo">
          <div className="text-head">Total Shares: </div>
          <div style={{ marginLeft: 10 }}>{totalShares}</div>
        </div>
        <div className="rowtwo">
          <div className="text-head">Total Ownership:</div>
          <div style={{ marginLeft: 10 }}>{totalOwnership.toFixed(2)}%</div>
        </div>
      </div>
      <div className="button-row">
        <div className="count-text">Current Number of Rows: {rows.length}</div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <button className="save-button" onClick={addRow}>
            Increase Rows
          </button>
          <button className="save-button" onClick={handleSubmit}>
            Save Capitalisation Table
          </button>
          <button className="save-button" onClick={exportToXLSX}>
            Export to XLSX
          </button>
          <button className="save-button" onClick={removeRow}>
            Decrease Rows
          </button>
        </div>
      </div>
    </div>
  );
};

export default CapitalisationTable;

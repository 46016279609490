import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../components/axiosConfig.js";
import { fetchBackendData } from "../thunks/fetchBackendData.js"; // Ensure the correct import path

const initialState = {
  formData: {
    products: [],
  },
  error: null,
};

export const fetchProducts = createAsyncThunk(
  "products/fetchProducts",
  async (_, { getState, rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        "http://bossentrepreneur.com.au/get",
        {
          headers: {
            Authorization: `Bearer YOUR_JWT_TOKEN`, // Replace with actual token
          },
        }
      );
      const state = getState();
      return response.data.inputs.products || state.formData.products;
    } catch (error) {
      return rejectWithValue("Error loading products");
    }
  }
);

export const saveProduct = createAsyncThunk(
  "products/saveProduct",
  async (product, { getState, rejectWithValue }) => {
    const state = getState();
    const products = [...state.products.formData.products, product];
    try {
      await axiosInstance.post(
        "http://bossentrepreneur.com.au/save/products",
        { products },
        {
          headers: {
            Authorization: `Bearer YOUR_JWT_TOKEN`, // Replace with actual token
          },
        }
      );
      return product;
    } catch (error) {
      console.error("Error saving product:", error);
      return rejectWithValue(error.response?.data || "Error saving product");
    }
  }
);

export const updateProduct = createAsyncThunk(
  "products/updateProduct",
  async ({ id, updatedProduct }, { getState, rejectWithValue }) => {
    const state = getState();
    const products = state.products.formData.products.map((product) =>
      product.id === id ? updatedProduct : product
    );
    try {
      await axiosInstance.post(
        "http://bossentrepreneur.com.au/save/products",
        { products },
        {
          headers: {
            Authorization: `Bearer YOUR_JWT_TOKEN`, // Replace with actual token
          },
        }
      );
      return updatedProduct;
    } catch (error) {
      console.error("Error updating product:", error);
      return rejectWithValue(error.response?.data || "Error updating product");
    }
  }
);

export const deleteProduct = createAsyncThunk(
  "products/deleteProduct",
  async (id, { getState, rejectWithValue }) => {
    const state = getState();
    const products = state.products.formData.products.filter(
      (product) => product.id !== id
    );
    try {
      await axiosInstance.post(
        "http://bossentrepreneur.com.au/save/products",
        { products },
        {
          headers: {
            Authorization: `Bearer YOUR_JWT_TOKEN`, // Replace with actual token
          },
        }
      );
      return { id };
    } catch (error) {
      console.error("Error deleting product:", error);
      return rejectWithValue(error.response?.data || "Error deleting product");
    }
  }
);

const productsSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    setProducts: (state, action) => {
      state.formData.products.push(action.payload);
    },
    addFeature: (state, action) => {
      const { productId, feature } = action.payload;
      const product = state.formData.products.find((p) => p.name === productId);
      if (product) {
        if (!Array.isArray(product.feature)) {
          product.feature = [];
        }
        product.feature.push(feature);
      }
    },
    updatesProduct: (state, action) => {
      const { id, updatedProduct } = action.payload;
      const product = state.formData.products.find((p) => p.id === id);
      if (product) {
        product.name = updatedProduct.name;
      }
    },
    deleteProduct: (state, action) => {
      state.formData.products = state.formData.products.filter(
        (p) => p.id !== action.payload.id
      );
    },
    updateFeature: (state, action) => {
      const { productId, featureId } = action.payload;
      const product = state.formData.products.find((p) => p.name === productId);
      if (product) {
        const feature = product.feature.find((f) => f.id === featureId.id);
        if (feature.id) {
          feature.name = featureId.name;
          feature.value = featureId.value;
        }
      }
    },
    deleteFeature: (state, action) => {
      const { productId, featureId } = action.payload;
      const product = state.formData.products.find((p) => p.id === productId);
      if (product) {
        product.feature = product.feature.filter((f) => f.id !== featureId);
      }
    },
    updateProductValues: (state, action) => {
      const { productId, maxProductValue, productUnitCost, price } =
        action.payload;
      const product = state.formData.products.find((p) => p.name === productId);
      if (product) {
        product.maxProductValue = maxProductValue;
        product.productUnitCost = productUnitCost;
        product.price = price;
      }
    },
    updateProductDescription: (state, action) => {
      const { productId, description, usp, useCase, emEng, techSpec } =
        action.payload;
      const product = state.formData.products.find((p) => p.name === productId);
      if (product.name === productId) {
        product.description = description;
        product.usp = usp;
        product.useCase = useCase;
        product.emEng = emEng;
        product.techSpec = techSpec;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProducts.fulfilled, (state, action) => {
        state.formData.products = action.payload;
        state.error = null; // Clear any previous errors
      })
      .addCase(fetchProducts.rejected, (state, action) => {
        state.formData.products = initialState.formData.products;
        state.error = action.payload; // Set the error message
      })
      .addCase(saveProduct.fulfilled, (state, action) => {
        state.formData.products.push(action.payload);
        state.error = null; // Clear any previous errors
      })
      .addCase(saveProduct.rejected, (state, action) => {
        state.error = action.payload; // Set the error message
      })
      .addCase(updateProduct.fulfilled, (state, action) => {
        const index = state.formData.products.findIndex(
          (product) => product.name === action.payload.name
        );
        if (index !== -1) {
          state.formData.products[index] = action.payload;
        }
        state.error = null; // Clear any previous errors
      })
      .addCase(updateProduct.rejected, (state, action) => {
        state.error = action.payload; // Set the error message
      })
      .addCase(deleteProduct.fulfilled, (state, action) => {
        state.formData.products = state.formData.products.filter(
          (product) => product.id !== action.payload.id
        );
        state.error = null; // Clear any previous errors
      })
      .addCase(deleteProduct.rejected, (state, action) => {
        state.error = action.payload; // Set the error message
      })
      .addCase(fetchBackendData.fulfilled, (state, action) => {
        state.formData = action.payload.formData.products.formData;
      });
  },
});

export const {
  updatesProduct,
  addFeature,
  updateFeature,
  deleteFeature,
  updateProductValues,
  updateProductDescription,
  setProducts,
} = productsSlice.actions;

export default productsSlice.reducer;

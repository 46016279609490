import { createSlice } from "@reduxjs/toolkit";
import { fetchBackendData } from "../thunks/fetchBackendData.js"; // Ensure the correct import path

export const IncomeStatementSlice = createSlice({
  name: "incomeStatement",
  initialState: {
    formData: {
      years: {
        "2023-2024": {
          budgetItems: {
            grossSales: [0, 0, 0, 0],
            returnsAllowances: [0, 0, 0, 0],
            costGoodSold: [0, 0, 0, 0],
            sellingGeneralAdmin: [0, 0, 0, 0],
            depreciationAmortization: [0, 0, 0, 0],
            researchDevelopment: [0, 0, 0, 0],
            interestIncome: [0, 0, 0, 0],
            interestExpense: [0, 0, 0, 0],
            otherIncome: [0, 0, 0, 0],
            incomeTax: [0, 0, 0, 0],
          },
        },
      },
    },
  },
  reducers: {
    setIncomeStatement: (state, action) => {
      const { year, budgetItems } = action.payload;
      state.formData.years[year] = { budgetItems };
    },
    incrementIncomeStatementYear: (state) => {
      const lastYear = Object.keys(state.formData.years).pop().split("-")[1];
      const newYearStart = parseInt(lastYear);
      const newYearEnd = newYearStart + 1;
      const newYear = `${newYearStart}-${newYearEnd}`;

      if (!state.formData.years[newYear]) {
        state.formData.years[newYear] = {
          budgetItems: {
            grossSales: [0, 0, 0, 0],
            returnsAllowances: [0, 0, 0, 0],
            costGoodSold: [0, 0, 0, 0],
            sellingGeneralAdmin: [0, 0, 0, 0],
            depreciationAmortization: [0, 0, 0, 0],
            researchDevelopment: [0, 0, 0, 0],
            interestIncome: [0, 0, 0, 0],
            interestExpense: [0, 0, 0, 0],
            otherIncome: [0, 0, 0, 0],
            incomeTax: [0, 0, 0, 0],
          },
        };
      }
    },
    decrementIncomeStatementYear: (state) => {
      const years = Object.keys(state.formData.years);
      if (years.length > 1) {
        const lastYear = years.pop();
        delete state.formData.years[lastYear];
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchBackendData.fulfilled, (state, action) => {
      state.formData = action.payload.formData.incomeStatement.formData;
    });
  },
});

export const {
  setIncomeStatement,
  incrementIncomeStatementYear,
  decrementIncomeStatementYear,
} = IncomeStatementSlice.actions;

export default IncomeStatementSlice.reducer;

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import "./RiskAnalysis.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusSquare } from "@fortawesome/free-solid-svg-icons";
import RiskGraphOne from "./SubScreens/RiskGraphOne.js";
import { addRisk, setMitigationPlan } from "../store/RiskAnalysisSlice";
import DOMPurify from "dompurify";

const RiskAnalysis = () => {
  const dispatch = useDispatch();
  const reduxState = useSelector((state) => state.riskAnalysis);

  const [allRisks, setAllRisks] = useState([]);
  const [error, setError] = useState("");
  const [operationalRisk, setOperationalRisk] = useState([]);
  const [newOpRiskName, setNewOpRiskName] = useState("");
  const [newOpRiskImpact, setNewOpRiskImpact] = useState("1");
  const [newOpRiskLikelihood, setNewOpRiskLikelihood] = useState("1");
  const [editOpRisk, setEditOpRisk] = useState(false);
  const [existingOpRisk, setExistingOpRisk] = useState(null);
  const [technologyRisk, setTechnologyRisk] = useState([]);
  const [newTechRiskName, setNewTechRiskName] = useState("");
  const [newTechRiskImpact, setNewTechRiskImpact] = useState("1");
  const [newTechRiskLikelihood, setNewTechRiskLikelihood] = useState("1");
  const [editTechRisk, setEditTechRisk] = useState(false);
  const [existingTechRisk, setExistingTechRisk] = useState(null);
  const [intellectualPropertyRisk, setIntellectualPropertyRisk] = useState([]);
  const [newIPRiskName, setNewIPRiskName] = useState("");
  const [newIPRiskImpact, setNewIPRiskImpact] = useState("1");
  const [newIPRiskLikelihood, setNewIPRiskLikelihood] = useState("1");
  const [editIPRisk, setEditIPRisk] = useState(false);
  const [existingIPRisk, setExistingIPRisk] = useState(null);
  const [positionRisk, setPositionRisk] = useState([]);
  const [newPosRiskName, setNewPosRiskName] = useState("");
  const [newPosRiskImpact, setNewPosRiskImpact] = useState("1");
  const [newPosRiskLikelihood, setNewPosRiskLikelihood] = useState("1");
  const [editPosRisk, setEditPosRisk] = useState(false);
  const [existingPosRisk, setExistingPosRisk] = useState(null);
  const [mitigationPlan, setMitigationPlanState] = useState("");

  useEffect(() => {
    setOperationalRisk(reduxState.operationalRisk);
    setTechnologyRisk(reduxState.technologyRisk);
    setIntellectualPropertyRisk(reduxState.intellectualPropertyRisk);
    setPositionRisk(reduxState.positionRisk);
    setMitigationPlanState(reduxState.mitigationPlan);
  }, [reduxState]);

  const combineRisks = () => {
    setAllRisks([
      ...operationalRisk,
      ...technologyRisk,
      ...intellectualPropertyRisk,
      ...positionRisk,
    ]);
  };

  useEffect(() => {
    combineRisks();
    // eslint-disable-next-line
  }, [operationalRisk, technologyRisk, intellectualPropertyRisk, positionRisk]);

  const addOperationalRisk = (name, impact, likelihood) => {
    const sanitizedName = DOMPurify.sanitize(name);
    const newOpRisk = {
      id: uuidv4(),
      name: sanitizedName,
      impact: parseInt(impact, 10),
      likelihood: parseInt(likelihood, 10),
    };
    setOperationalRisk([...operationalRisk, newOpRisk]);
    setEditOpRisk(false);
  };

  const updateOperationalRisk = (id, updatedRisk) => {
    setOperationalRisk(
      operationalRisk.map((risk) =>
        risk.id === id ? { ...risk, ...updatedRisk } : risk
      )
    );
    setExistingOpRisk(null);
  };

  const removeOperationalRisk = (id) => {
    setOperationalRisk(operationalRisk.filter((risk) => risk.id !== id));
    setExistingOpRisk(null);
  };

  const addTechnologyRisk = (name, impact, likelihood) => {
    const sanitizedName = DOMPurify.sanitize(name);
    const newTechRisk = {
      id: uuidv4(),
      name: sanitizedName,
      impact: parseInt(impact, 10),
      likelihood: parseInt(likelihood, 10),
    };
    setTechnologyRisk([...technologyRisk, newTechRisk]);
    setEditTechRisk(false);
  };

  const updateTechnologyRisk = (id, updatedRisk) => {
    setTechnologyRisk(
      technologyRisk.map((risk) =>
        risk.id === id ? { ...risk, ...updatedRisk } : risk
      )
    );
    setExistingTechRisk(null);
  };

  const removeTechnologyRisk = (id) => {
    setTechnologyRisk(technologyRisk.filter((risk) => risk.id !== id));
    setExistingTechRisk(null);
  };

  const addIPRisk = (name, impact, likelihood) => {
    const sanitizedName = DOMPurify.sanitize(name);
    const newIPRisk = {
      id: uuidv4(),
      name: sanitizedName,
      impact: parseInt(impact, 10),
      likelihood: parseInt(likelihood, 10),
    };
    setIntellectualPropertyRisk([...intellectualPropertyRisk, newIPRisk]);
    setEditIPRisk(false);
  };

  const updateIPRisk = (id, updatedRisk) => {
    setIntellectualPropertyRisk(
      intellectualPropertyRisk.map((risk) =>
        risk.id === id ? { ...risk, ...updatedRisk } : risk
      )
    );
    setExistingIPRisk(null);
  };

  const removeIPRisk = (id) => {
    setIntellectualPropertyRisk(
      intellectualPropertyRisk.filter((risk) => risk.id !== id)
    );
    setExistingIPRisk(null);
  };

  const addPosRisk = (name, impact, likelihood) => {
    const sanitizedName = DOMPurify.sanitize(name);
    const newPosRisk = {
      id: uuidv4(),
      name: sanitizedName,
      impact: parseInt(impact, 10),
      likelihood: parseInt(likelihood, 10),
    };
    setPositionRisk([...positionRisk, newPosRisk]);
    setEditPosRisk(false);
  };

  const updatePosRisk = (id, updatedRisk) => {
    setPositionRisk(
      positionRisk.map((risk) =>
        risk.id === id ? { ...risk, ...updatedRisk } : risk
      )
    );
    setExistingPosRisk(null);
  };

  const removePosRisk = (id) => {
    setPositionRisk(positionRisk.filter((risk) => risk.id !== id));
    setExistingPosRisk(null);
  };

  const saveData = () => {
    operationalRisk.forEach((risk) =>
      dispatch(addRisk({ type: "operationalRisk", risk }))
    );
    technologyRisk.forEach((risk) =>
      dispatch(addRisk({ type: "technologyRisk", risk }))
    );
    intellectualPropertyRisk.forEach((risk) =>
      dispatch(addRisk({ type: "intellectualPropertyRisk", risk }))
    );
    positionRisk.forEach((risk) =>
      dispatch(addRisk({ type: "positionRisk", risk }))
    );
    dispatch(setMitigationPlan(mitigationPlan));
    alert("Risk Plan Saved");
  };

  const cancel = () => {
    setEditOpRisk(false);
    setEditTechRisk(false);
    setEditIPRisk(false);
    setEditPosRisk(false);
  };

  return (
    <div className="container">
      <h1
        style={{
          display: "inline",
          fontFamily: "Verdana, sans-serif",
          fontStyle: "italic",
          fontSize: 26,
          marginBottom: 20,
        }}
      >
        What are the business risks?
      </h1>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flex: 1,
          width: "100%",
        }}
      >
        <div className="input-section">
          <div className="risk-category">
            <div className="input-row">
              <label className="label">Operational Risk</label>
              <button
                data-testid="AddOPRISK"
                className="add-button"
                onClick={() => setEditOpRisk(true)}
              >
                <FontAwesomeIcon
                  icon={faPlusSquare}
                  size="lg"
                  color="#FFFFFF"
                />
              </button>
            </div>
            {editOpRisk && (
              <div className="risk-form">
                <label className="label">Add New Operational Risk:</label>
                <input
                  className="input"
                  placeholder="Name"
                  value={newOpRiskName}
                  onChange={(e) => setNewOpRiskName(e.target.value)}
                />
                <input
                  className="input"
                  placeholder="Impact (1-10)"
                  type="number"
                  onChange={(e) => {
                    const value = e.target.value.replace(/[^0-9]/g, "");
                    setNewOpRiskImpact(value);
                  }}
                  onBlur={() => {
                    const impact = parseInt(newOpRiskImpact, 10);
                    if (isNaN(impact)) {
                      setError("Please enter a numeric value.");
                    } else if (impact < 1 || impact > 10) {
                      setError("Impact must be between 1 and 10.");
                    } else {
                      setError(""); // Clear error message if input is valid
                    }
                  }}
                />
                <input
                  className="input"
                  placeholder="Likelihood (1-10)"
                  type="number"
                  onChange={(e) => {
                    const value = e.target.value.replace(/[^0-9]/g, "");
                    setNewOpRiskLikelihood(value);
                  }}
                  onBlur={() => {
                    const likelihood = parseInt(newOpRiskLikelihood, 10);
                    if (isNaN(likelihood)) {
                      setError("Please enter a numeric value.");
                    } else if (likelihood < 1 || likelihood > 10) {
                      setError("Likelihood must be between 1 and 10.");
                    } else {
                      setError(""); // Clear error message if input is valid
                    }
                  }}
                />
                {error && <p className="error-text">{error}</p>}
                <div className="input-row">
                  <button
                    style={{
                      background: "linear-gradient(to right, #1876D2, #000080)",
                    }}
                    className="save-button"
                    onClick={() => {
                      if (!error) {
                        const impactValid =
                          parseInt(newOpRiskImpact, 10) >= 1 &&
                          parseInt(newOpRiskImpact, 10) <= 10;
                        const likelihoodValid =
                          parseInt(newOpRiskLikelihood, 10) >= 1 &&
                          parseInt(newOpRiskLikelihood, 10) <= 10;
                        if (impactValid && likelihoodValid) {
                          addOperationalRisk(
                            newOpRiskName,
                            newOpRiskImpact,
                            newOpRiskLikelihood
                          );
                          setNewOpRiskName("");
                          setNewOpRiskImpact("1");
                          setNewOpRiskLikelihood("1");
                        } else {
                          setError(
                            "Both impact and likelihood must be between 1 and 10."
                          );
                        }
                      }
                    }}
                    disabled={!!error}
                  >
                    Add Operational Risk
                  </button>
                  <button
                    style={{
                      background: "linear-gradient(to right, #1876D2, #000080)",
                    }}
                    className="save-button"
                    onClick={cancel}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            )}
            {operationalRisk.map((risk) => (
              <div key={risk.id} className="input-row">
                {existingOpRisk === risk.id ? (
                  <>
                    <div style={{ flexDirection: 'column"' }}>
                      <div style={{ flexDirection: "row" }}>
                        <input
                          className="input"
                          placeholder={risk.name}
                          value={newOpRiskName}
                          onChange={(e) => setNewOpRiskName(e.target.value)}
                        />
                        <input
                          className="input"
                          placeholder={String(risk.impact)}
                          type="number"
                          value={newOpRiskImpact}
                          onChange={(e) => {
                            const value = e.target.value.replace(/[^0-9]/g, "");
                            setNewOpRiskImpact(value);
                          }}
                          onBlur={() => {
                            const impact = parseInt(newOpRiskImpact, 10);
                            if (isNaN(impact)) {
                              setError("Please enter a numeric value.");
                            } else if (impact < 1 || impact > 10) {
                              setError("Impact must be between 1 and 10.");
                            } else {
                              setError(""); // Clear error message if input is valid
                            }
                          }}
                        />
                        <input
                          className="input"
                          placeholder={String(risk.likelihood)}
                          type="number"
                          value={newOpRiskLikelihood}
                          onChange={(e) => {
                            const value = e.target.value.replace(/[^0-9]/g, "");
                            setNewOpRiskLikelihood(value);
                          }}
                          onBlur={() => {
                            const likelihood = parseInt(
                              newOpRiskLikelihood,
                              10
                            );
                            if (isNaN(likelihood)) {
                              setError("Please enter a numeric value.");
                            } else if (likelihood < 1 || likelihood > 10) {
                              setError("Likelihood must be between 1 and 10.");
                            } else {
                              setError(""); // Clear error message if input is valid
                            }
                          }}
                        />
                      </div>
                      {error && <p className="error-text">{error}</p>}
                      <div className="input-row">
                        <button
                          style={{
                            background:
                              "linear-gradient(to right, #1876D2, #000080)",
                          }}
                          className="save-button"
                          onClick={() => {
                            if (!error) {
                              const impactValid =
                                parseInt(newOpRiskImpact, 10) >= 1 &&
                                parseInt(newOpRiskImpact, 10) <= 10;
                              const likelihoodValid =
                                parseInt(newOpRiskLikelihood, 10) >= 1 &&
                                parseInt(newOpRiskLikelihood, 10) <= 10;
                              if (impactValid && likelihoodValid) {
                                const updatedRisk = {
                                  name: newOpRiskName,
                                  impact: newOpRiskImpact,
                                  likelihood: newOpRiskLikelihood,
                                };
                                updateOperationalRisk(risk.id, updatedRisk);
                                setNewOpRiskName("");
                                setNewOpRiskImpact("1");
                                setNewOpRiskLikelihood("1");
                                setExistingOpRisk(null);
                              } else {
                                setError(
                                  "Both impact and likelihood must be between 1 and 10."
                                );
                              }
                            }
                          }}
                          disabled={!!error}
                        >
                          Update Operational Risk
                        </button>
                        <button
                          style={{
                            background:
                              "linear-gradient(to right, #1876D2, #000080)",
                          }}
                          className="save-button"
                          onClick={() => removeOperationalRisk(risk.id)}
                        >
                          Delete Risk
                        </button>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="input-row">
                    <button
                      style={{ backgroundColor: "white", color: "blue" }}
                      onClick={() => {
                        setExistingOpRisk(risk.id);
                        setNewOpRiskName(risk.name);
                        setNewOpRiskImpact(String(risk.impact));
                        setNewOpRiskLikelihood(String(risk.likelihood));
                      }}
                    >
                      <p className="Risk">Risk:</p>
                    </button>
                    <p className="riskText">{risk.name}</p>
                    <p className="riskText">Impact: {risk.impact}</p>
                    <p className="riskText">Likelihood: {risk.likelihood}</p>
                  </div>
                )}
              </div>
            ))}
          </div>

          <div className="risk-category">
            <div className="input-row">
              <label className="label">Technology Risk</label>
              <button
                className="add-button"
                onClick={() => setEditTechRisk(true)}
              >
                <FontAwesomeIcon
                  icon={faPlusSquare}
                  size="lg"
                  color="#FFFFFF"
                />
              </button>
            </div>
            {editTechRisk && (
              <div className="risk-form">
                <label className="label">Add New Technology Risk:</label>
                <input
                  className="input"
                  placeholder="Name"
                  value={newTechRiskName}
                  onChange={(e) => setNewTechRiskName(e.target.value)}
                />
                <input
                  className="input"
                  placeholder="Impact (1-10)"
                  type="number"
                  onChange={(e) => {
                    const value = e.target.value.replace(/[^0-9]/g, "");
                    setNewTechRiskImpact(value);
                  }}
                  onBlur={() => {
                    const impact = parseInt(newTechRiskImpact, 10);
                    if (isNaN(impact)) {
                      setError("Please enter a numeric value.");
                    } else if (impact < 1 || impact > 10) {
                      setError("Impact must be between 1 and 10.");
                    } else {
                      setError(""); // Clear error message if input is valid
                    }
                  }}
                />
                <input
                  className="input"
                  placeholder="Likelihood (1-10)"
                  type="number"
                  onChange={(e) => {
                    const value = e.target.value.replace(/[^0-9]/g, "");
                    setNewTechRiskLikelihood(value);
                  }}
                  onBlur={() => {
                    const likelihood = parseInt(newTechRiskLikelihood, 10);
                    if (isNaN(likelihood)) {
                      setError("Please enter a numeric value.");
                    } else if (likelihood < 1 || likelihood > 10) {
                      setError("Likelihood must be between 1 and 10.");
                    } else {
                      setError(""); // Clear error message if input is valid
                    }
                  }}
                />
                {error && <p className="error-text">{error}</p>}
                <div className="input-row">
                  <button
                    style={{
                      background: "linear-gradient(to right, #1876D2, #000080)",
                    }}
                    className="save-button"
                    onClick={() => {
                      if (!error) {
                        const impactValid =
                          parseInt(newTechRiskImpact, 10) >= 1 &&
                          parseInt(newTechRiskImpact, 10) <= 10;
                        const likelihoodValid =
                          parseInt(newTechRiskLikelihood, 10) >= 1 &&
                          parseInt(newTechRiskLikelihood, 10) <= 10;
                        if (impactValid && likelihoodValid) {
                          addTechnologyRisk(
                            newTechRiskName,
                            newTechRiskImpact,
                            newTechRiskLikelihood
                          );
                          setNewTechRiskName("");
                          setNewTechRiskImpact("1");
                          setNewTechRiskLikelihood("1");
                        } else {
                          setError(
                            "Both impact and likelihood must be between 1 and 10."
                          );
                        }
                      }
                    }}
                    disabled={!!error}
                  >
                    Add Technology Risk
                  </button>
                  <button
                    style={{
                      background: "linear-gradient(to right, #1876D2, #000080)",
                    }}
                    className="save-button"
                    onClick={cancel}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            )}
            {technologyRisk.map((risk) => (
              <div key={risk.id} className="input-row">
                {existingTechRisk === risk.id ? (
                  <>
                    <div style={{ flexDirection: "column" }}>
                      <div style={{ flexDirection: "row" }}>
                        <input
                          className="input"
                          placeholder={risk.name}
                          value={newTechRiskName}
                          onChange={(e) => setNewTechRiskName(e.target.value)}
                        />
                        <input
                          className="input"
                          placeholder={String(risk.impact)}
                          type="number"
                          value={newTechRiskImpact}
                          onChange={(e) => {
                            const value = e.target.value.replace(/[^0-9]/g, "");
                            setNewTechRiskImpact(value);
                          }}
                          onBlur={() => {
                            const impact = parseInt(newTechRiskImpact, 10);
                            if (isNaN(impact)) {
                              setError("Please enter a numeric value.");
                            } else if (impact < 1 || impact > 10) {
                              setError("Impact must be between 1 and 10.");
                            } else {
                              setError(""); // Clear error message if input is valid
                            }
                          }}
                        />
                        <input
                          className="input"
                          placeholder={String(risk.likelihood)}
                          type="number"
                          value={newTechRiskLikelihood}
                          onChange={(e) => {
                            const value = e.target.value.replace(/[^0-9]/g, "");
                            setNewTechRiskLikelihood(value);
                          }}
                          onBlur={() => {
                            const likelihood = parseInt(
                              newTechRiskLikelihood,
                              10
                            );
                            if (isNaN(likelihood)) {
                              setError("Please enter a numeric value.");
                            } else if (likelihood < 1 || likelihood > 10) {
                              setError("Likelihood must be between 1 and 10.");
                            } else {
                              setError(""); // Clear error message if input is valid
                            }
                          }}
                        />
                      </div>
                      {error && <p className="error-text">{error}</p>}
                      <div className="input-row">
                        <button
                          style={{
                            background:
                              "linear-gradient(to right, #1876D2, #000080)",
                          }}
                          className="save-button"
                          onClick={() => {
                            if (!error) {
                              const impactValid =
                                parseInt(newTechRiskImpact, 10) >= 1 &&
                                parseInt(newTechRiskImpact, 10) <= 10;
                              const likelihoodValid =
                                parseInt(newTechRiskLikelihood, 10) >= 1 &&
                                parseInt(newTechRiskLikelihood, 10) <= 10;
                              if (impactValid && likelihoodValid) {
                                const updatedRisk = {
                                  name: newTechRiskName,
                                  impact: newTechRiskImpact,
                                  likelihood: newTechRiskLikelihood,
                                };
                                updateTechnologyRisk(risk.id, updatedRisk);
                                setNewTechRiskName("");
                                setNewTechRiskImpact("1");
                                setNewTechRiskLikelihood("1");
                                setExistingTechRisk(null);
                              } else {
                                setError(
                                  "Both impact and likelihood must be between 1 and 10."
                                );
                              }
                            }
                          }}
                          disabled={!!error}
                        >
                          Update Technology Risk
                        </button>
                        <button
                          style={{
                            background:
                              "linear-gradient(to right, #1876D2, #000080)",
                          }}
                          className="save-button"
                          onClick={() => removeTechnologyRisk(risk.id)}
                        >
                          Delete Risk
                        </button>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="input-row">
                    <button
                      style={{ backgroundColor: "white", color: "blue" }}
                      onClick={() => {
                        setExistingTechRisk(risk.id);
                        setNewTechRiskName(risk.name);
                        setNewTechRiskImpact(String(risk.impact));
                        setNewTechRiskLikelihood(String(risk.likelihood));
                      }}
                    >
                      <p className="Risk">Risk:</p>
                    </button>
                    <p className="riskText">{risk.name}</p>
                    <p className="riskText">Impact: {risk.impact}</p>
                    <p className="riskText">Likelihood: {risk.likelihood}</p>
                  </div>
                )}
              </div>
            ))}
          </div>

          <div className="risk-category">
            <div className="input-row">
              <label className="label">Intellectual Property Risk</label>
              <button
                className="add-button"
                onClick={() => setEditIPRisk(true)}
              >
                <FontAwesomeIcon
                  icon={faPlusSquare}
                  size="lg"
                  color="#FFFFFF"
                />
              </button>
            </div>
            {editIPRisk && (
              <div className="risk-form">
                <label className="label">
                  Add New Intellectual Property Risk:
                </label>
                <input
                  className="input"
                  placeholder="Name"
                  value={newIPRiskName}
                  onChange={(e) => setNewIPRiskName(e.target.value)}
                />
                <input
                  className="input"
                  placeholder="Impact (1-10)"
                  type="number"
                  onChange={(e) => {
                    const value = e.target.value.replace(/[^0-9]/g, "");
                    setNewIPRiskImpact(value);
                  }}
                  onBlur={() => {
                    const impact = parseInt(newIPRiskImpact, 10);
                    if (isNaN(impact)) {
                      setError("Please enter a numeric value.");
                    } else if (impact < 1 || impact > 10) {
                      setError("Impact must be between 1 and 10.");
                    } else {
                      setError(""); // Clear error message if input is valid
                    }
                  }}
                />
                <input
                  className="input"
                  placeholder="Likelihood (1-10)"
                  type="number"
                  onChange={(e) => {
                    const value = e.target.value.replace(/[^0-9]/g, "");
                    setNewIPRiskLikelihood(value);
                  }}
                  onBlur={() => {
                    const likelihood = parseInt(newIPRiskLikelihood, 10);
                    if (isNaN(likelihood)) {
                      setError("Please enter a numeric value.");
                    } else if (likelihood < 1 || likelihood > 10) {
                      setError("Likelihood must be between 1 and 10.");
                    } else {
                      setError(""); // Clear error message if input is valid
                    }
                  }}
                />
                {error && <p className="error-text">{error}</p>}
                <div className="input-row">
                  <button
                    style={{
                      background: "linear-gradient(to right, #1876D2, #000080)",
                    }}
                    className="save-button"
                    onClick={() => {
                      if (!error) {
                        const impactValid =
                          parseInt(newIPRiskImpact, 10) >= 1 &&
                          parseInt(newIPRiskImpact, 10) <= 10;
                        const likelihoodValid =
                          parseInt(newIPRiskLikelihood, 10) >= 1 &&
                          parseInt(newIPRiskLikelihood, 10) <= 10;
                        if (impactValid && likelihoodValid) {
                          addIPRisk(
                            newIPRiskName,
                            newIPRiskImpact,
                            newIPRiskLikelihood
                          );
                          setNewIPRiskName("");
                          setNewIPRiskImpact("1");
                          setNewIPRiskLikelihood("1");
                        } else {
                          setError(
                            "Both impact and likelihood must be between 1 and 10."
                          );
                        }
                      }
                    }}
                    disabled={!!error}
                  >
                    Add Intellectual Property Risk
                  </button>
                  <button
                    style={{
                      background: "linear-gradient(to right, #1876D2, #000080)",
                    }}
                    className="save-button"
                    onClick={cancel}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            )}
            {intellectualPropertyRisk.map((risk) => (
              <div key={risk.id} className="input-row">
                {existingIPRisk === risk.id ? (
                  <>
                    <div style={{ flexDirection: "column" }}>
                      <div style={{ flexDirection: "row" }}>
                        <input
                          className="input"
                          placeholder={risk.name}
                          value={newIPRiskName}
                          onChange={(e) => setNewIPRiskName(e.target.value)}
                        />
                        <input
                          className="input"
                          placeholder={String(risk.impact)}
                          value={newIPRiskImpact}
                          type="number"
                          onChange={(e) => {
                            const value = e.target.value.replace(/[^0-9]/g, "");
                            setNewIPRiskImpact(value);
                          }}
                          onBlur={() => {
                            const impact = parseInt(newIPRiskImpact, 10);
                            if (isNaN(impact)) {
                              setError("Please enter a numeric value.");
                            } else if (impact < 1 || impact > 10) {
                              setError("Impact must be between 1 and 10.");
                            } else {
                              setError(""); // Clear error message if input is valid
                            }
                          }}
                        />
                        <input
                          className="input"
                          placeholder={String(risk.likelihood)}
                          value={newIPRiskLikelihood}
                          type="number"
                          onChange={(e) => {
                            const value = e.target.value.replace(/[^0-9]/g, "");
                            setNewIPRiskLikelihood(value);
                          }}
                          onBlur={() => {
                            const likelihood = parseInt(
                              newIPRiskLikelihood,
                              10
                            );
                            if (isNaN(likelihood)) {
                              setError("Please enter a numeric value.");
                            } else if (likelihood < 1 || likelihood > 10) {
                              setError("Likelihood must be between 1 and 10.");
                            } else {
                              setError(""); // Clear error message if input is valid
                            }
                          }}
                        />
                      </div>
                      {error && <p className="error-text">{error}</p>}
                      <div className="input-row">
                        <button
                          style={{
                            background:
                              "linear-gradient(to right, #1876D2, #000080)",
                          }}
                          className="save-button"
                          onClick={() => {
                            if (!error) {
                              const impactValid =
                                parseInt(newIPRiskImpact, 10) >= 1 &&
                                parseInt(newIPRiskImpact, 10) <= 10;
                              const likelihoodValid =
                                parseInt(newIPRiskLikelihood, 10) >= 1 &&
                                parseInt(newIPRiskLikelihood, 10) <= 10;
                              if (impactValid && likelihoodValid) {
                                const updatedRisk = {
                                  name: newIPRiskName,
                                  impact: newIPRiskImpact,
                                  likelihood: newIPRiskLikelihood,
                                };
                                updateIPRisk(risk.id, updatedRisk);
                                setNewIPRiskName("");
                                setNewIPRiskImpact("1");
                                setNewIPRiskLikelihood("1");
                                setExistingIPRisk(null);
                              } else {
                                setError(
                                  "Both impact and likelihood must be between 1 and 10."
                                );
                              }
                            }
                          }}
                          disabled={!!error}
                        >
                          Update Intellectual Property Risk
                        </button>
                        <button
                          style={{
                            background:
                              "linear-gradient(to right, #1876D2, #000080)",
                          }}
                          className="save-button"
                          onClick={() => removeIPRisk(risk.id)}
                        >
                          Delete Risk
                        </button>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="input-row">
                    <button
                      style={{ backgroundColor: "white", color: "blue" }}
                      onClick={() => {
                        setExistingIPRisk(risk.id);
                        setNewIPRiskName(risk.name);
                        setNewIPRiskImpact(String(risk.impact));
                        setNewIPRiskLikelihood(String(risk.likelihood));
                      }}
                    >
                      <p className="Risk">Risk:</p>
                    </button>
                    <p className="riskText">{risk.name}</p>
                    <p className="riskText">Impact: {risk.impact}</p>
                    <p className="riskText">Likelihood: {risk.likelihood}</p>
                  </div>
                )}
              </div>
            ))}
          </div>

          <div className="risk-category">
            <div className="input-row">
              <label className="label">Position Risk</label>
              <button
                className="add-button"
                onClick={() => setEditPosRisk(true)}
              >
                <FontAwesomeIcon
                  icon={faPlusSquare}
                  size="lg"
                  color="#FFFFFF"
                />
              </button>
            </div>
            {editPosRisk && (
              <div className="risk-form">
                <label className="label">Add New Positional Risk:</label>
                <input
                  className="input"
                  placeholder="Name"
                  value={newPosRiskName}
                  onChange={(e) => setNewPosRiskName(e.target.value)}
                />
                <input
                  className="input"
                  placeholder="Impact (1-10)"
                  type="number"
                  onChange={(e) => {
                    const value = e.target.value.replace(/[^0-9]/g, "");
                    setNewPosRiskImpact(value);
                  }}
                  onBlur={() => {
                    const impact = parseInt(newPosRiskImpact, 10);
                    if (isNaN(impact)) {
                      setError("Please enter a numeric value.");
                    } else if (impact < 1 || impact > 10) {
                      setError("Impact must be between 1 and 10.");
                    } else {
                      setError(""); // Clear error message if input is valid
                    }
                  }}
                />
                <input
                  className="input"
                  placeholder="Likelihood (1-10)"
                  type="number"
                  onChange={(e) => {
                    const value = e.target.value.replace(/[^0-9]/g, "");
                    setNewPosRiskLikelihood(value);
                  }}
                  onBlur={() => {
                    const likelihood = parseInt(newPosRiskLikelihood, 10);
                    if (isNaN(likelihood)) {
                      setError("Please enter a numeric value.");
                    } else if (likelihood < 1 || likelihood > 10) {
                      setError("Likelihood must be between 1 and 10.");
                    } else {
                      setError(""); // Clear error message if input is valid
                    }
                  }}
                />
                {error && <p className="error-text">{error}</p>}
                <div className="input-row">
                  <button
                    style={{
                      background: "linear-gradient(to right, #1876D2, #000080)",
                    }}
                    className="save-button"
                    onClick={() => {
                      if (!error) {
                        const impactValid =
                          parseInt(newPosRiskImpact, 10) >= 1 &&
                          parseInt(newPosRiskImpact, 10) <= 10;
                        const likelihoodValid =
                          parseInt(newPosRiskLikelihood, 10) >= 1 &&
                          parseInt(newPosRiskLikelihood, 10) <= 10;
                        if (impactValid && likelihoodValid) {
                          addPosRisk(
                            newPosRiskName,
                            newPosRiskImpact,
                            newPosRiskLikelihood
                          );
                          setNewPosRiskName("");
                          setNewPosRiskImpact("1");
                          setNewPosRiskLikelihood("1");
                        } else {
                          setError(
                            "Both impact and likelihood must be between 1 and 10."
                          );
                        }
                      }
                    }}
                    disabled={!!error}
                  >
                    Add Positional Risk
                  </button>
                  <button
                    style={{
                      background: "linear-gradient(to right, #1876D2, #000080)",
                    }}
                    className="save-button"
                    onClick={cancel}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            )}
            {positionRisk.map((risk) => (
              <div key={risk.id} className="input-row">
                {existingPosRisk === risk.id ? (
                  <>
                    <div style={{ flexDirection: "column" }}>
                      <div style={{ flexDirection: "row" }}>
                        <input
                          className="input"
                          placeholder={risk.name}
                          value={newPosRiskName}
                          onChange={(e) => setNewPosRiskName(e.target.value)}
                        />
                        <input
                          className="input"
                          placeholder={String(risk.impact)}
                          type="number"
                          value={newPosRiskImpact}
                          onChange={(e) => {
                            const value = e.target.value.replace(/[^0-9]/g, "");
                            setNewPosRiskImpact(value);
                          }}
                          onBlur={() => {
                            const impact = parseInt(newPosRiskImpact, 10);
                            if (isNaN(impact)) {
                              setError("Please enter a numeric value.");
                            } else if (impact < 1 || impact > 10) {
                              setError("Impact must be between 1 and 10.");
                            } else {
                              setError(""); // Clear error message if input is valid
                            }
                          }}
                        />
                        <input
                          className="input"
                          placeholder={String(risk.likelihood)}
                          type="number"
                          value={newPosRiskLikelihood}
                          onChange={(e) => {
                            const value = e.target.value.replace(/[^0-9]/g, "");
                            setNewPosRiskLikelihood(value);
                          }}
                          onBlur={() => {
                            const likelihood = parseInt(
                              newPosRiskLikelihood,
                              10
                            );
                            if (isNaN(likelihood)) {
                              setError("Please enter a numeric value.");
                            } else if (likelihood < 1 || likelihood > 10) {
                              setError("Likelihood must be between 1 and 10.");
                            } else {
                              setError(""); // Clear error message if input is valid
                            }
                          }}
                        />
                      </div>
                      {error && <p className="error-text">{error}</p>}
                      <div className="input-row">
                        <button
                          style={{
                            background:
                              "linear-gradient(to right, #1876D2, #000080)",
                          }}
                          className="save-button"
                          onClick={() => {
                            if (!error) {
                              const impactValid =
                                parseInt(newPosRiskImpact, 10) >= 1 &&
                                parseInt(newPosRiskImpact, 10) <= 10;
                              const likelihoodValid =
                                parseInt(newPosRiskLikelihood, 10) >= 1 &&
                                parseInt(newPosRiskLikelihood, 10) <= 10;
                              if (impactValid && likelihoodValid) {
                                const updatedRisk = {
                                  name: newPosRiskName,
                                  impact: newPosRiskImpact,
                                  likelihood: newPosRiskLikelihood,
                                };
                                updatePosRisk(risk.id, updatedRisk);
                                setNewPosRiskName("");
                                setNewPosRiskImpact("1");
                                setNewPosRiskLikelihood("1");
                                setExistingPosRisk(null);
                              } else {
                                setError(
                                  "Both impact and likelihood must be between 1 and 10."
                                );
                              }
                            }
                          }}
                          disabled={!!error}
                        >
                          Update Positional Risk
                        </button>
                        <button
                          style={{
                            background:
                              "linear-gradient(to right, #1876D2, #000080)",
                          }}
                          className="save-button"
                          onClick={() => removePosRisk(risk.id)}
                        >
                          Delete Risk
                        </button>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="input-row">
                    <button
                      style={{ backgroundColor: "white", color: "blue" }}
                      onClick={() => {
                        setExistingPosRisk(risk.id);
                        setNewPosRiskName(risk.name);
                        setNewPosRiskImpact(String(risk.impact));
                        setNewPosRiskLikelihood(String(risk.likelihood));
                      }}
                    >
                      <p className="Risk">Risk:</p>
                    </button>
                    <p className="riskText">{risk.name}</p>
                    <p className="riskText">Impact: {risk.impact}</p>
                    <p className="riskText">Likelihood: {risk.likelihood}</p>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginLeft: "2%",
            width: "90%",
          }}
        >
          <div className="risk-graph-container">
            <RiskGraphOne data={allRisks} />
          </div>
        </div>
      </div>

      <label style={{ fontWeight: "bold", fontSize: 18, marginBottom: 10 }}>
        Risk Mitigation Plan:
      </label>
      <textarea
        id="mitigation-plan"
        className="input-two"
        placeholder="Enter your risk mitigation plan"
        value={mitigationPlan}
        onChange={(e) => setMitigationPlanState(e.target.value)}
      />
      <button
        style={{ background: "linear-gradient(to right, #1876D2, #000080)" }}
        className="save-button"
        onClick={saveData}
      >
        Save
      </button>
    </div>
  );
};

export default RiskAnalysis;

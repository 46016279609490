import axios from "axios";

// List of private IP ranges to block
const privateIpRanges = [
  /^10\./, // 10.0.0.0 - 10.255.255.255
  /^192\.168\./, // 192.168.0.0 - 192.168.255.255
  /^127\./, // 127.0.0.0 - 127.255.255.255 (loopback)
  /^169\.254\./, // 169.254.0.0 - 169.254.255.255 (link-local)
];

// Function to check if the IP is in the private range
const isPrivateIp = (ip) => privateIpRanges.some((range) => range.test(ip));

const axiosInstance = axios.create({
  maxRedirects: 0,
  timeout: 5000,
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
});

axiosInstance.interceptors.request.use(
  async (config) => {
    const url = new URL(config.url);
    const ipAddress = url.hostname;

    console.log("Requesting URL:", config.url);
    console.log("Resolved IP Address:", ipAddress);
    console.log("Axios Config:", config); // Log full Axios config

    if (isPrivateIp(ipAddress) && !ipAddress.startsWith("172.21.")) {
      console.error("Blocked request to private IP:", ipAddress);
      return Promise.reject(
        new Error("Request blocked: Private IP addresses are not allowed")
      );
    }

    return config;
  },
  (error) => {
    console.error("Error in request interceptor:", error);
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    console.error("Axios network error:", error.message);
    console.error(
      "Axios error details:",
      error.response ? error.response.data : "No response data"
    );
    console.error("Axios error stack:", error.stack); // Log error stack for debugging
    console.error("Axios error config:", error.config);
    return Promise.reject(error);
  }
);

export default axiosInstance;

import { combineReducers } from "redux";
import inputSlice from "./inputsSlice.js";
import OperatingBudgetSlice from "./OperatingBudgetSlice.js";
import CashFlowSlice from "./CashFlowSlice.js";
import GanttChartSlice from "./GanttChartSlice.js";
import CompetitorsSlice from "./CompetitorsSlice.js";
import HiringSlice from "./HiringSlice.js";
import IncomeStatementSlice from "./IncomeStatementSlice.js";
import CapitalisationTableSlice from "./CapitalisationTableSlice.js";
import BalanceSheetSlice from "./BalanceSheetSlice.js";
import MarketingBudgetSlice from "./MarketingBudgetSlice.js";
import RiskAnalysisSlice from "./RiskAnalysisSlice.js";
import productsSlice from "./ProductsSlice.js";
import authSlice from "./authSlice.js";
import userReducer from "./userSlice.js";
import dataSlice from "../thunks/fetchBackendData.js";
import stripeReducer from "./stripeSlice.js";

const appReducer = combineReducers({
  inputs: inputSlice,
  operatingBudget: OperatingBudgetSlice,
  cashFlow: CashFlowSlice,
  ganttChart: GanttChartSlice,
  competitors: CompetitorsSlice,
  hiring: HiringSlice,
  incomeStatement: IncomeStatementSlice,
  capitalisationTable: CapitalisationTableSlice,
  balanceSheet: BalanceSheetSlice,
  marketingBudget: MarketingBudgetSlice,
  riskAnalysis: RiskAnalysisSlice,
  products: productsSlice,
  user: userReducer,
  auth: authSlice,
  data: dataSlice,
  stripe: stripeReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "user/clearAllData/fulfilled") {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;

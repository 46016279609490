import { createSlice } from "@reduxjs/toolkit";
import { fetchBackendData } from "../thunks/fetchBackendData.js"; // Ensure the correct import path

export const OperatingBudgetSlice = createSlice({
  name: "operatingBudget",
  initialState: {
    formData: {
      years: {
        "2023-2024": {
          budgetItems: {
            salesRevenue: [0, 0, 0, 0],
            otherRevenue: [0, 0, 0, 0],
            salariesWages: [0, 0, 0, 0],
            rentLease: [0, 0, 0, 0],
            additionalExpenses: [0, 0, 0, 0],
          },
        },
      },
    },
  },
  reducers: {
    setOperatingBudget: (state, action) => {
      const { year, budgetItems } = action.payload;
      state.formData.years[year] = { budgetItems };
    },
    incrementOpBudgetYear: (state) => {
      const lastYear = Object.keys(state.formData.years).pop().split("-")[1];
      const newYearStart = parseInt(lastYear);
      const newYearEnd = newYearStart + 1;
      const newYear = `${newYearStart}-${newYearEnd}`;
      if (!state.formData.years[newYear]) {
        state.formData.years[newYear] = {
          budgetItems: {
            salesRevenue: [0, 0, 0, 0],
            otherRevenue: [0, 0, 0, 0],
            salariesWages: [0, 0, 0, 0],
            rentLease: [0, 0, 0, 0],
            additionalExpenses: [0, 0, 0, 0],
          },
        };
      }
    },
    decrementOpBudgetYear: (state) => {
      const years = Object.keys(state.formData.years);
      if (years.length > 1 && years[0] !== "2023-2024") {
        const lastYear = years.pop();
        delete state.formData.years[lastYear];
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchBackendData.fulfilled, (state, action) => {
      state.formData = action.payload.formData.operatingBudget.formData;
    });
  },
});

export const {
  setOperatingBudget,
  incrementOpBudgetYear,
  decrementOpBudgetYear,
} = OperatingBudgetSlice.actions;

export default OperatingBudgetSlice.reducer;

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addEmployee,
  updateEmployee,
  removeEmployee,
  confirmEmployee,
  updateHRPlan,
} from "../store/HiringSlice.js";
import DOMPurify from "dompurify";
import "./styles/Style.css";

const Hiring = () => {
  const dispatch = useDispatch();
  const employees = useSelector((state) => state.hiring.formData.employees);
  const employed = useSelector((state) => state.hiring.formData.employed);
  const hrPlan = useSelector((state) => state.hiring.formData.hrPlan); // Add HR plan from state
  const [showEmployeeFields, setShowEmployeeFields] = useState(false);
  const [editingEmployee, setEditingEmployee] = useState(null);
  const [employeeDetails, setEmployeeDetails] = useState({
    employeeName: "",
    skillsAndExperience: "",
    culturalFit: "",
    passionAndMotivation: "",
    creativityAndProblemSolving: "",
    adaptabilityAndFlexibility: "",
    teamCollaboration: "",
    entrepreneurialMindset: "",
    growthPotential: "",
    alignmentWithStartupStage: "",
    salaryAndBenefits: "",
  });
  const [hrPlanDetails, setHrPlanDetails] = useState(hrPlan);

  useEffect(() => {
    if (editingEmployee) {
      setEmployeeDetails(editingEmployee);
      setShowEmployeeFields(true);
    } else {
      setEmployeeDetails({
        employeeName: "",
        skillsAndExperience: "",
        culturalFit: "",
        passionAndMotivation: "",
        creativityAndProblemSolving: "",
        adaptabilityAndFlexibility: "",
        teamCollaboration: "",
        entrepreneurialMindset: "",
        growthPotential: "",
        alignmentWithStartupStage: "",
        salaryAndBenefits: "",
      });
    }
  }, [editingEmployee]);

  const handleInputChange = (key, value) => {
    const sanitizedValue = DOMPurify.sanitize(value);
    setEmployeeDetails((prev) => ({ ...prev, [key]: sanitizedValue }));
  };

  const handleHRChange = (value) => {
    const sanitizedValue = DOMPurify.sanitize(value);
    setHrPlanDetails(sanitizedValue);
  };

  const addEmployeeClick = () => {
    setShowEmployeeFields(true);
    setEditingEmployee(null);
  };

  const saveEmployeeDetails = () => {
    const newEmployee = {
      id: editingEmployee ? editingEmployee.id : Date.now(), // Simpler ID generation
      ...employeeDetails,
    };
    if (editingEmployee) {
      console.log("Dispatching updateEmployee:", newEmployee);
      dispatch(updateEmployee(newEmployee));
    } else {
      console.log("Dispatching addEmployee:", newEmployee);
      dispatch(addEmployee(newEmployee));
    }
    setEmployeeDetails({
      employeeName: "",
      skillsAndExperience: "",
      culturalFit: "",
      passionAndMotivation: "",
      creativityAndProblemSolving: "",
      adaptabilityAndFlexibility: "",
      teamCollaboration: "",
      entrepreneurialMindset: "",
      growthPotential: "",
      alignmentWithStartupStage: "",
      salaryAndBenefits: "",
    });
    setShowEmployeeFields(false);
    setEditingEmployee(null); // Clear the editing employee after saving
  };

  const saveHRPlan = () => {
    dispatch(updateHRPlan(hrPlanDetails));
    alert("HR plan saved");
  };

  const cancel = () => {
    setShowEmployeeFields(false);
    setEditingEmployee(null);
  };

  const confirmEmployeeClick = (employeeId) => {
    dispatch(confirmEmployee(employeeId));
  };

  const editEmployedClick = (employee) => {
    setEditingEmployee(employee);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const removeEmployedClick = (employeeId) => {
    dispatch(removeEmployee(employeeId));
  };

  const editEmployeeClick = (employee) => {
    setEditingEmployee(employee);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const removeEmployeeClick = (employeeId) => {
    dispatch(removeEmployee(employeeId));
  };

  const capitalizeWords = (str) => {
    return str
      .split(/(?=[A-Z])/)
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  return (
    <div className="content">
      <h1 className="title">Hiring Plan</h1>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          margin: "2%",
        }}
      >
        <div style={{ width: "100%" }}>
          <button
            style={{
              background: "linear-gradient(to right, #1876D2, #000080)",
            }}
            onClick={addEmployeeClick}
          >
            Add Potential Employee
          </button>

          {showEmployeeFields && (
            <div>
              {Object.keys(employeeDetails)
                .filter((key) => key !== "id")
                .map((key) => (
                  <div key={key} className="input-container">
                    <label className="subheading" htmlFor={key}>
                      {capitalizeWords(key)}:
                    </label>
                    <input
                      id={key}
                      className="inputcp"
                      style={{ width: "95%" }}
                      type="text"
                      value={employeeDetails[key]}
                      onChange={(e) => handleInputChange(key, e.target.value)}
                    />
                  </div>
                ))}
              <button
                style={{
                  background: "linear-gradient(to right, #1876D2, #000080)",
                }}
                onClick={saveEmployeeDetails}
              >
                Save Details
              </button>
              <button
                style={{
                  background: "linear-gradient(to right, #1876D2, #000080)",
                }}
                onClick={cancel}
              >
                Cancel
              </button>
            </div>
          )}

          {employed.length > 0 && (
            <>
              <h2>Employed Staff:</h2>
              {employed.map((employee) => (
                <div key={employee.id} className="employee-card">
                  <h3>{employee.employeeName}</h3>
                  <p>
                    <strong>Skills and Experience:</strong>{" "}
                    {employee.skillsAndExperience}
                  </p>
                  <p>
                    <strong>Cultural Fit:</strong> {employee.culturalFit}
                  </p>
                  <p>
                    <strong>Passion and Motivation:</strong>{" "}
                    {employee.passionAndMotivation}
                  </p>
                  <p>
                    <strong>Creativity and Problem Solving:</strong>{" "}
                    {employee.creativityAndProblemSolving}
                  </p>
                  <p>
                    <strong>Adaptability and Flexibility:</strong>{" "}
                    {employee.adaptabilityAndFlexibility}
                  </p>
                  <p>
                    <strong>Team Collaboration:</strong>{" "}
                    {employee.teamCollaboration}
                  </p>
                  <p>
                    <strong>Entrepreneurial Mindset:</strong>{" "}
                    {employee.entrepreneurialMindset}
                  </p>
                  <p>
                    <strong>Growth Potential:</strong>{" "}
                    {employee.growthPotential}
                  </p>
                  <p>
                    <strong>Alignment with Startup Stage:</strong>{" "}
                    {employee.alignmentWithStartupStage}
                  </p>
                  <p>
                    <strong>Salary and Benefits:</strong>{" "}
                    {employee.salaryAndBenefits}
                  </p>
                  <button
                    style={{
                      background: "linear-gradient(to right, #1876D2, #000080)",
                    }}
                    onClick={() => editEmployedClick(employee)}
                  >
                    Edit
                  </button>
                  <button
                    style={{
                      background: "linear-gradient(to right, #1876D2, #000080)",
                    }}
                    onClick={() => removeEmployedClick(employee.id)}
                  >
                    Remove
                  </button>
                </div>
              ))}
            </>
          )}

          {employees.length > 0 && (
            <>
              <h2>Potential Employees:</h2>
              {employees.map((employee) => (
                <div key={employee.id} className="employee-card">
                  <h3>{employee.employeeName}</h3>
                  <p>
                    <strong>Skills and Experience:</strong>{" "}
                    {employee.skillsAndExperience}
                  </p>
                  <p>
                    <strong>Cultural Fit:</strong> {employee.culturalFit}
                  </p>
                  <p>
                    <strong>Passion and Motivation:</strong>{" "}
                    {employee.passionAndMotivation}
                  </p>
                  <p>
                    <strong>Creativity and Problem Solving:</strong>{" "}
                    {employee.creativityAndProblemSolving}
                  </p>
                  <p>
                    <strong>Adaptability and Flexibility:</strong>{" "}
                    {employee.adaptabilityAndFlexibility}
                  </p>
                  <p>
                    <strong>Team Collaboration:</strong>{" "}
                    {employee.teamCollaboration}
                  </p>
                  <p>
                    <strong>Entrepreneurial Mindset:</strong>{" "}
                    {employee.entrepreneurialMindset}
                  </p>
                  <p>
                    <strong>Growth Potential:</strong>{" "}
                    {employee.growthPotential}
                  </p>
                  <p>
                    <strong>Alignment with Startup Stage:</strong>{" "}
                    {employee.alignmentWithStartupStage}
                  </p>
                  <p>
                    <strong>Salary and Benefits:</strong>{" "}
                    {employee.salaryAndBenefits}
                  </p>
                  <button
                    style={{
                      background: "linear-gradient(to right, #1876D2, #000080)",
                    }}
                    onClick={() => confirmEmployeeClick(employee.id)}
                  >
                    Confirm as Employee
                  </button>
                  <button
                    style={{
                      background: "linear-gradient(to right, #1876D2, #000080)",
                    }}
                    onClick={() => editEmployeeClick(employee)}
                  >
                    Edit
                  </button>
                  <button
                    style={{
                      background: "linear-gradient(to right, #1876D2, #000080)",
                    }}
                    onClick={() => removeEmployeeClick(employee.id)}
                  >
                    Remove
                  </button>
                </div>
              ))}
            </>
          )}
        </div>

        <div style={{ marginLeft: 20, width: "100%", marginTop: 50 }}>
          <label
            style={{ fontWeight: "bold", fontSize: "18", marginBottom: 20 }}
            htmlFor="hrPlan"
          >
            HR Plan:
          </label>
          <textarea
            id="hrPlan"
            className="HRtextarea"
            placeholder="HR Plan"
            style={{ width: "95%" }}
            type="text"
            value={hrPlanDetails}
            onChange={(e) => handleHRChange(e.target.value)}
          />
          <button
            style={{
              background: "linear-gradient(to right, #1876D2, #000080)",
            }}
            onClick={saveHRPlan}
          >
            Save HR Plan
          </button>
        </div>
      </div>
    </div>
  );
};

export default Hiring;

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setFormData } from "../store/inputsSlice.js";
import DOMPurify from "dompurify";
import "./styles/Style.css";

const MarketPlan = () => {
  const dispatch = useDispatch();
  const reduxFormData = useSelector(
    (state) => state.inputs.formData.MarketPlan
  );
  const [formData, setLocalFormData] = useState(reduxFormData);

  useEffect(() => {
    setLocalFormData(reduxFormData);
  }, [reduxFormData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    // Sanitize the input value
    const sanitizedValue = DOMPurify.sanitize(value);
    setLocalFormData({
      ...formData,
      [name]: sanitizedValue,
    });
  };

  const handleSubmit = () => {
    dispatch(setFormData({ MarketPlan: formData }));
    alert("Market Plan Saved");
  };

  return (
    <div className="content">
      <h2 className="title">Market Plan</h2>
      <div className="column">
        <label className="subheading">Goals:</label>
        <textarea
          className="textarea"
          type="text"
          name="input1"
          value={formData.input1}
          onChange={handleChange}
          placeholder="Goals of going to market?"
        />
        <label className="subheading">Tactics:</label>
        <textarea
          className="textarea"
          type="text"
          name="input2"
          value={formData.input2}
          onChange={handleChange}
          placeholder="Tactics for going to market?"
        />
        <label className="subheading">Timelines:</label>
        <textarea
          className="textarea"
          type="text"
          name="input3"
          value={formData.input3}
          onChange={handleChange}
          placeholder="Timelines for going to market?"
        />
        <label className="subheading">Performance Metrics:</label>
        <textarea
          className="textarea"
          type="text"
          name="input4"
          value={formData.input4}
          onChange={handleChange}
          placeholder="What Perfomance metrics will be used to measure success?"
        />
        <button
          style={{ background: "linear-gradient(to right, #1876D2, #000080)" }}
          className="button"
          onClick={handleSubmit}
        >
          Save Market Plan
        </button>
      </div>
    </div>
  );
};

export default MarketPlan;

import { createSlice } from "@reduxjs/toolkit";
import { fetchBackendData } from "../thunks/fetchBackendData.js"; // Ensure the correct import path

export const CompetitorSlice = createSlice({
  name: "competitors",
  initialState: {
    formData: {
      Competitors: [],
    },
  },
  reducers: {
    setFormData: (state, action) => {
      state.formData.Competitors = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchBackendData.fulfilled, (state, action) => {
      state.formData = action.payload.formData.competitors.formData;
    });
  },
});

export const { setFormData } = CompetitorSlice.actions;

export default CompetitorSlice.reducer;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../components/axiosConfig.js";
import { fetchBackendData } from "../thunks/fetchBackendData.js"; // Ensure the correct import path

// Async thunk to save form data to the backend
export const saveFormData = createAsyncThunk(
  "inputs/saveFormData",
  async (formData, { getState, rejectWithValue }) => {
    const state = getState();
    const { csrfToken } = state.auth;
    try {
      const response = await axiosInstance.post(
        "http://bossentrepreneur.com.au/bossData/save",
        { formData },
        {
          headers: {
            "Content-Type": "application/json",
            "csrf-token": csrfToken,
          },
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const inputsSlice = createSlice({
  name: "inputs",
  initialState: {
    formData: {
      BusinessPlan: {
        input1: "",
        input2: "",
        input3: "",
        input4: "",
        input5: "",
      },
      StrategicPlan: {
        input1: "",
        input2: "",
        input3: "",
        input4: "",
        input5: "",
        input6: "",
      },
      CulturePlan: { input1: "", input2: "", input3: "" },
      FuturePlan: { input1: "" },
      LegalPlan: {
        input1: "",
        input2: "",
        input3: "",
        input4: "",
        input5: "",
        input6: "",
        input7: "",
      },
      MarketAnalysis: {
        input1: "",
        input2: "",
        input3: "",
        input4: "",
        input5: "",
        input6: "",
      },
      MarketPlan: { input1: "", input2: "", input3: "", input4: "" },
      CustomerAnalysis: { input1: "", input2: "", input3: "", input4: "" },
      StartupCosts: {
        rows: Array(5)
          .fill()
          .map((_, i) => ({
            id: i + 1,
            category: "",
            item: "",
            estimatedCost: 0,
            actualCost: 0,
            note: "",
          })),
        totalEstimatedCost: 0,
        totalActualCost: 0,
      },
    },
    status: "idle",
    error: null,
  },
  reducers: {
    setFormData: (state, action) => {
      state.formData = { ...state.formData, ...action.payload };
    },
    setStartupCosts: (state, action) => {
      state.formData.StartupCosts = {
        ...state.formData.StartupCosts,
        ...action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(saveFormData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(saveFormData.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(saveFormData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(fetchBackendData.fulfilled, (state, action) => {
        state.formData = action.payload.formData.inputs.formData;
      });
  },
});

export const { setFormData, setStartupCosts } = inputsSlice.actions;

export default inputsSlice.reducer;

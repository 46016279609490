import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setFormData } from "../store/CompetitorsSlice.js";
import DOMPurify from "dompurify";
import "./styles/Style.css";

const Competitors = () => {
  const dispatch = useDispatch();
  const competitors =
    useSelector((state) => state.competitors.formData.Competitors) || [];
  const [showInputFields, setShowInputFields] = useState(false);
  const [editingIndex, setEditingIndex] = useState(null); // Track the index of the competitor being edited
  const [newCompetitor, setNewCompetitor] = useState({
    input1: "",
    input2: "",
    input3: "",
    input4: "",
    input5: "",
    input6: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    const sanitizedValue = DOMPurify.sanitize(value);
    setNewCompetitor({
      ...newCompetitor,
      [name]: sanitizedValue,
    });
  };

  const handleAddCompetitor = () => {
    if (newCompetitor.input1.trim() !== "") {
      const updatedCompetitors = [...competitors, newCompetitor];
      dispatch(setFormData(updatedCompetitors));
      setNewCompetitor({
        input1: "",
        input2: "",
        input3: "",
        input4: "",
        input5: "",
        input6: "",
      });
      setShowInputFields(false); // Hide input fields after adding competitor
    }
  };

  const handleEditCompetitor = (index) => {
    setEditingIndex(index);
    setNewCompetitor(competitors[index]);
  };

  const handleUpdateCompetitor = () => {
    const updatedCompetitors = competitors.map((competitor, index) =>
      index === editingIndex ? newCompetitor : competitor
    );
    dispatch(setFormData(updatedCompetitors));
    setNewCompetitor({
      input1: "",
      input2: "",
      input3: "",
      input4: "",
      input5: "",
      input6: "",
    });
    setEditingIndex(null);
    setShowInputFields(false);
  };

  const handleDeleteCompetitor = (index) => {
    const updatedCompetitors = competitors.filter((_, i) => i !== index);
    dispatch(setFormData(updatedCompetitors));
  };

  return (
    <div className="content">
      <h2 className="title">Competitors</h2>
      <div style={{ flexDirection: "column", marginTop: 20 }}>
        {!showInputFields && !editingIndex && (
          <button
            style={{
              background: "linear-gradient(to right, #1876D2, #000080)",
            }}
            className="button"
            onClick={() => setShowInputFields(true)}
          >
            Add Competitor
          </button>
        )}

        {(showInputFields || editingIndex !== null) && (
          <div className="column">
            <label className="subheading">Competitor Name:</label>
            <textarea
              className="input"
              type="text"
              name="input1"
              value={newCompetitor.input1}
              onChange={handleChange}
              placeholder="What is a competitor's name?"
            />
            <label className="subheading">Strengths:</label>
            <textarea
              className="input"
              type="text"
              name="input2"
              value={newCompetitor.input2}
              onChange={handleChange}
              placeholder="What are the competitor's strengths?"
            />
            <label className="subheading">Weaknesses:</label>
            <textarea
              className="input"
              type="text"
              name="input3"
              value={newCompetitor.input3}
              onChange={handleChange}
              placeholder="What are the competitor's weaknesses?"
            />
            <label className="subheading">Products:</label>
            <textarea
              className="input"
              type="text"
              name="input4"
              value={newCompetitor.input4}
              onChange={handleChange}
              placeholder="What are the competitor's products?"
            />
            <label className="subheading">Product Charges:</label>
            <textarea
              className="input"
              type="text"
              name="input5"
              value={newCompetitor.input5}
              onChange={handleChange}
              placeholder="How much do they charge for their products?"
            />
            <label className="subheading">Distribution Channels:</label>
            <textarea
              className="input"
              type="text"
              name="input6"
              value={newCompetitor.input6}
              onChange={handleChange}
              placeholder="How do they distribute their products?"
            />
            {editingIndex !== null ? (
              <button
                style={{
                  background: "linear-gradient(to right, #1876D2, #000080)",
                }}
                className="button"
                onClick={handleUpdateCompetitor}
              >
                Update Competitor
              </button>
            ) : (
              <button
                style={{
                  background: "linear-gradient(to right, #1876D2, #000080)",
                }}
                className="button"
                onClick={handleAddCompetitor}
              >
                Add Competitor
              </button>
            )}
          </div>
        )}

        {Array.isArray(competitors) &&
          competitors.length > 0 &&
          competitors.map((competitor, index) => (
            <div key={index} className="column">
              <div className="row">
                <h2 className="subheading">{competitor.input1}</h2>
                <button
                  style={{
                    background: "linear-gradient(to right, #1876D2, #000080)",
                  }}
                  className="compButton"
                  onClick={() => handleEditCompetitor(index)}
                >
                  Edit Competitor
                </button>
                <button
                  style={{
                    background: "linear-gradient(to right, #1876D2, #000080)",
                  }}
                  className="compButton"
                  onClick={() => handleDeleteCompetitor(index)}
                >
                  Delete Competitor
                </button>
              </div>
              <p>Strengths: {competitor.input2}</p>
              <p>Weaknesses: {competitor.input3}</p>
              <p>Products: {competitor.input4}</p>
              <p>How much they charge: {competitor.input5}</p>
              <p>Distribution channels: {competitor.input6}</p>
            </div>
          ))}
      </div>
    </div>
  );
};

export default Competitors;

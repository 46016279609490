import { createSlice } from "@reduxjs/toolkit";
import { fetchBackendData } from "../thunks/fetchBackendData.js"; // Ensure the correct import path

const initialState = {
  formData: {
    MarketingBudget: {
      years: {
        "2023-2024": {
          channels: Array(5).fill(""),
          objectives: Array(5).fill(""),
          audiences: Array(5).fill(""),
          qOne: Array(5).fill(""),
          qTwo: Array(5).fill(""),
          qThree: Array(5).fill(""),
          qFour: Array(5).fill(""),
        },
      },
    },
  },
};

const MarketingBudgetSlice = createSlice({
  name: "marketingBudget",
  initialState,
  reducers: {
    setMarketingBudget: (state, action) => {
      const {
        year,
        channels,
        objectives,
        audiences,
        qOne,
        qTwo,
        qThree,
        qFour,
      } = action.payload;
      if (!state.formData.MarketingBudget.years[year]) {
        state.formData.MarketingBudget.years[year] = {
          channels: [],
          objectives: [],
          audiences: [],
          qOne: [],
          qTwo: [],
          qThree: [],
          qFour: [],
        };
      }
      state.formData.MarketingBudget.years[year] = {
        channels,
        objectives,
        audiences,
        qOne,
        qTwo,
        qThree,
        qFour,
      };
    },
    addRow: (state, action) => {
      const { year } = action.payload;
      if (!state.formData.MarketingBudget.years[year]) {
        state.formData.MarketingBudget.years[year] = {
          channels: Array(5).fill(""),
          objectives: Array(5).fill(""),
          audiences: Array(5).fill(""),
          qOne: Array(5).fill(""),
          qTwo: Array(5).fill(""),
          qThree: Array(5).fill(""),
          qFour: Array(5).fill(""),
        };
      }
      state.formData.MarketingBudget.years[year].channels.push("");
      state.formData.MarketingBudget.years[year].objectives.push("");
      state.formData.MarketingBudget.years[year].audiences.push("");
      state.formData.MarketingBudget.years[year].qOne.push("");
      state.formData.MarketingBudget.years[year].qTwo.push("");
      state.formData.MarketingBudget.years[year].qThree.push("");
      state.formData.MarketingBudget.years[year].qFour.push("");
    },
    removeRow: (state, action) => {
      const { year } = action.payload;
      if (state.formData.MarketingBudget.years[year]) {
        state.formData.MarketingBudget.years[year].channels.pop();
        state.formData.MarketingBudget.years[year].objectives.pop();
        state.formData.MarketingBudget.years[year].audiences.pop();
        state.formData.MarketingBudget.years[year].qOne.pop();
        state.formData.MarketingBudget.years[year].qTwo.pop();
        state.formData.MarketingBudget.years[year].qThree.pop();
        state.formData.MarketingBudget.years[year].qFour.pop();
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchBackendData.fulfilled, (state, action) => {
      state.formData = action.payload.formData.marketingBudget.formData;
    });
  },
});

export const { setMarketingBudget, addRow, removeRow } =
  MarketingBudgetSlice.actions;

export default MarketingBudgetSlice.reducer;

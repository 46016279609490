import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setFormData } from "../store/inputsSlice.js";
import DOMPurify from "dompurify";
import "./styles/Style.css";

const FuturePlan = () => {
  const dispatch = useDispatch();
  const reduxFormData = useSelector(
    (state) => state.inputs.formData.FuturePlan
  );
  const [formData, setLocalFormData] = useState(reduxFormData);

  useEffect(() => {
    setLocalFormData(reduxFormData);
  }, [reduxFormData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    // Sanitize the input value
    const sanitizedValue = DOMPurify.sanitize(value);
    setLocalFormData({
      ...formData,
      [name]: sanitizedValue,
    });
  };

  const handleSubmit = () => {
    dispatch(setFormData({ FuturePlan: formData }));
    alert("Future Plan Saved");
  };

  return (
    <div className="content">
      <h2 className="title">Future Vision</h2>
      <div className="column">
        <label className="subheading">
          What is the future of the business:
        </label>
        <textarea
          className="textarea"
          type="text"
          name="input1"
          value={formData.input1}
          onChange={handleChange}
          placeholder="What are your ideas about the future of the business?"
        />
        <button
          style={{ background: "linear-gradient(to right, #1876D2, #000080)" }}
          className="button"
          onClick={handleSubmit}
        >
          Save Future Vision
        </button>
      </div>
    </div>
  );
};

export default FuturePlan;

import { createSlice } from "@reduxjs/toolkit";
import { fetchBackendData } from "../thunks/fetchBackendData.js"; // Ensure the correct import path

const initialState = {
  formData: {
    years: {
      "2023-2024": {
        budgetItems: {
          cash: [0, 0, 0, 0],
          accRecievable: [0, 0, 0, 0],
          inventory: [0, 0, 0, 0],
          prepaid: [0, 0, 0, 0],
          propEquip: [0, 0, 0, 0],
          accDepreciate: [0, 0, 0, 0],
          goodwill: [0, 0, 0, 0],
          intangible: [0, 0, 0, 0],
          accPayable: [0, 0, 0, 0],
          shortDebt: [0, 0, 0, 0],
          liabilities: [0, 0, 0, 0],
          longDebt: [0, 0, 0, 0],
          defTax: [0, 0, 0, 0],
          commonStock: [0, 0, 0, 0],
          retainedEarning: [0, 0, 0, 0],
        },
      },
    },
  },
};

const BalanceSheetSlice = createSlice({
  name: "balanceSheet",
  initialState,
  reducers: {
    setBalanceSheet: (state, action) => {
      const { year, budgetItems } = action.payload;
      state.formData.years[year] = { budgetItems };
    },
    incrementBalanceSheetYear: (state) => {
      const lastYear = Object.keys(state.formData.years).pop().split("-")[1];
      const newYear = `${lastYear}-${parseInt(lastYear, 10) + 1}`;
      if (!state.formData.years[newYear]) {
        state.formData.years[newYear] = {
          budgetItems: {
            cash: [0, 0, 0, 0],
            accRecievable: [0, 0, 0, 0],
            inventory: [0, 0, 0, 0],
            prepaid: [0, 0, 0, 0],
            propEquip: [0, 0, 0, 0],
            accDepreciate: [0, 0, 0, 0],
            goodwill: [0, 0, 0, 0],
            intangible: [0, 0, 0, 0],
            accPayable: [0, 0, 0, 0],
            shortDebt: [0, 0, 0, 0],
            liabilities: [0, 0, 0, 0],
            longDebt: [0, 0, 0, 0],
            defTax: [0, 0, 0, 0],
            commonStock: [0, 0, 0, 0],
            retainedEarning: [0, 0, 0, 0],
          },
        };
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchBackendData.fulfilled, (state, action) => {
      state.formData = action.payload.formData.balanceSheet.formData;
    });
  },
});

export const { setBalanceSheet, incrementBalanceSheetYear } =
  BalanceSheetSlice.actions;

export default BalanceSheetSlice.reducer;

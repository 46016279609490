import React, { useState } from "react";
import StartupCosts from "./SubScreens/StartupCosts.js";
import OperatingBudget from "./SubScreens/OperatingBudget.js";
import CashFlow from "./SubScreens/CashFlow.js";
import IncomeStatement from "./SubScreens/IncomeStatement.js";
import CapitalisationTable from "./SubScreens/CapitalisationTable.js";
import BalanceSheet from "./SubScreens/BalanceSheet.js";
import MarketingBudget from "./SubScreens/MarketingBudget.js";
import "./styles/Style.css"; // Assuming you have a CSS file for styling

const BudgetPlan = ({ onValueChange }) => {
  const [selectedOption, setSelectedOption] = useState(null);

  const options = [
    { component: StartupCosts, label: "Startup Costs" },
    { component: OperatingBudget, label: "Operating Budget" },
    { component: CashFlow, label: "Cash Flow" },
    { component: IncomeStatement, label: "Income Statement" },
    { component: CapitalisationTable, label: "Capitalisation Table" },
    { component: BalanceSheet, label: "Balance Sheet" },
    { component: MarketingBudget, label: "Marketing Budget" },
  ];

  const handlePress = (option) => {
    setSelectedOption(option);
    if (onValueChange) {
      onValueChange(option.label);
    }
  };

  return (
    <div className="content">
      <div className="row">
        {options.map((option) => (
          <button
            style={{ marginLeft: "1%", marginRight: "1%" }}
            key={option.label}
            className="option"
            onClick={() => handlePress(option)}
          >
            {option.label}
          </button>
        ))}
      </div>

      <div className="content">
        {selectedOption && <selectedOption.component />}
      </div>
    </div>
  );
};

export default BudgetPlan;

import React from 'react';
import { VictoryScatter, VictoryChart, VictoryAxis, VictoryTheme } from 'victory';

const RiskGraphOne = ({ data = [] }) => {
  // Prepare the data in the format VictoryScatter expects
  const dataPoints = data.map(risk => ({
    x: parseFloat(risk.likelihood), // Ensure the values are parsed as floats
    y: parseFloat(risk.impact),
    label: risk.name, // Optional: use 'label' to display the name of the risk
  }));

  return (
    <div style={{ width: '150%', height: '150%' }}> {/* Set an appropriate size for the chart */}
      <VictoryChart 
        theme={VictoryTheme.material}
        domain={{ x: [0, 10], y: [0, 10] }}  
        style={{
          parent: {
            width: "100%",
            height: "100%",
          }
        }}
      >
        {/* Include axis components with tickValues */}
        <VictoryAxis
          tickValues={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
          style={{
            axisLabel: { padding: 30 },
            tickLabels: { fontSize: 10, fill: 'grey' },
          }}
          label="Likelihood"
        />
        <VictoryAxis
          dependentAxis
          tickValues={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
          style={{
            axisLabel: { padding: 30 },
            tickLabels: { fontSize: 10, fill: 'grey' },
          }}
          label="Impact"
        />
        <VictoryScatter
          data={dataPoints}
          style={{ data: { fill: "#c43a31" } }}
          size={5} // Example size, adjust as needed
        />
      </VictoryChart>
    </div>
  );
};

export default RiskGraphOne;

import { all, call, put, takeLatest, select } from "redux-saga/effects";
import axiosInstance from "../components/axiosConfig.js";
import {
  fetchUserData,
  fetchUserDataSuccess,
  fetchUserDataFailure,
} from "./userSlice.js";
import { loginUser } from "./authSlice.js"; // Note that we import loginUser, not loginUserSuccess or loginUserFailure

// Worker saga: will be fired on fetchUserData actions
function* fetchUserDataSaga() {
  try {
    const token = yield select((state) => state.auth.token);

    const response = yield call(axiosInstance.get, "/formData/get", {
      headers: {
        Authorization: `Bearer ${token}`, // Include the token in the request headers
      },
    });

    yield put(fetchUserDataSuccess(response.data));
  } catch (error) {
    yield put(fetchUserDataFailure(error.response.data));
  }
}

// Worker saga: will be fired on loginUser actions
function* loginUserSaga(action) {
  try {
    const response = yield call(
      axiosInstance.post,
      "/auth/login",
      action.payload
    );
    const { token } = response.data;

    yield put({ type: loginUser.fulfilled.type, payload: { token } });
    yield put(fetchUserData()); // Dispatch fetchUserData action after successful login
  } catch (error) {
    yield put({ type: loginUser.rejected.type, payload: error.response.data });
  }
}

// Watcher saga: spawn a new fetchUserDataSaga task on each fetchUserData action
function* watchFetchUserData() {
  yield takeLatest(fetchUserData.type, fetchUserDataSaga);
}

// Watcher saga: spawn a new loginUserSaga task on each loginUser action
function* watchLoginUser() {
  yield takeLatest(loginUser.type, loginUserSaga);
}

// Root saga: spawn all watcher sagas
export default function* rootSaga() {
  yield all([watchFetchUserData(), watchLoginUser()]);
}

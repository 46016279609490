import React, { useState } from "react";
import "./ProductDetail.css"; // Ensure to create a CSS file for this component
import PriceValue from "./PriceValue.js";
import ValueProfile from "./ValueProfile.js";
import ProductDescription from "./ProductDescription.js";

const ProductDetail = ({ product }) => {
  const [activeComponent, setActiveComponent] = useState(null);

  const showComponent = (component) => {
    setActiveComponent(component);
  };

  return (
    <div className="product-detail-container">
      <h1 style={{ marginLeft: 20, fontStyle: "italic" }}>
        Product: {product ? product : "No product selected"}
      </h1>
      <div className="horizontal-scroll-container">
        <button
          style={{
            marginLeft: "2%",
            marginRight: "2%",
            background: "linear-gradient(to right, #1876D2, #000080)",
          }}
          className="option"
          onClick={() => showComponent("ProductDescription")}
        >
          Product Description
        </button>
        <button
          style={{
            marginLeft: "2%",
            marginRight: "2%",
            background: "linear-gradient(to right, #1876D2, #000080)",
          }}
          className="option"
          onClick={() => showComponent("PriceValue")}
        >
          Price-Cost-Value
        </button>
        <button
          style={{
            marginLeft: "2%",
            marginRight: "2%",
            background: "linear-gradient(to right, #1876D2, #000080)",
          }}
          className="option"
          onClick={() => showComponent("ValueProfile")}
        >
          Feature Value Profile
        </button>
      </div>
      <div className="component-container">
        {activeComponent === "PriceValue" && <PriceValue productId={product} />}
        {activeComponent === "ValueProfile" && (
          <ValueProfile productId={product} />
        )}
        {activeComponent === "ProductDescription" && (
          <ProductDescription productId={product} />
        )}
      </div>
    </div>
  );
};

export default ProductDetail;

// store.js
import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import rootReducer from "./rootReducer.js";
import saveFormDataMiddleware from "../middleware/saveFormDataMiddleware.js";
import rootSaga from "./sagas.js";

const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false, // Add this if you have non-serializable data in your state
    }).concat(sagaMiddleware, saveFormDataMiddleware),
});

sagaMiddleware.run(rootSaga);

export default store;

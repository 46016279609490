import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../components/axiosConfig.js";

// Async thunk to handle fetching user data
export const fetchUserData = createAsyncThunk(
  "user/fetchUserData",
  async (_, { getState, rejectWithValue }) => {
    const { csrfToken } = getState().auth;
    try {
      const response = await axiosInstance.get(
        "htto://bossentrepreneur.com.au/formData/get",
        {
          headers: {
            "Content-Type": "application/json",
            "csrf-token": csrfToken,
          },
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const userSlice = createSlice({
  name: "user",
  initialState: {
    data: null,
    status: "idle",
    error: null,
  },
  reducers: {
    fetchUserDataSuccess: (state, action) => {
      state.status = "succeeded";
      state.data = action.payload;
    },
    fetchUserDataFailure: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchUserData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })
      .addCase(fetchUserData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const { fetchUserDataSuccess, fetchUserDataFailure } = userSlice.actions;
export default userSlice.reducer;

import React, { useState } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import emailjs from "emailjs-com";
import { useSelector } from "react-redux";

function ResetPass({ open, onClose }) {
  const [email, setEmail] = useState("");
  const csrfToken = useSelector((state) => state.auth.csrfToken);

  const generateRandomPassword = () => {
    const chars =
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let password = "";
    for (let i = 0; i < 12; i++) {
      password += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return password;
  };

  const handleResetPassword = async () => {
    const newPassword = generateRandomPassword();
    const templateParams = {
      to_email: email,
      new_password: newPassword,
    };

    try {
      // Update the user's password in your auth API
      const response = await fetch(
        "https://localhost:4000/api/auth/reset-password",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "csrf-token": csrfToken,
          },
          withCredentials: true,
          body: JSON.stringify({ email, newPassword }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update password in the auth API");
      }

      // Send the new password to the user's email using EmailJS
      await emailjs.send(
        "service_0xfdu5j", // Replace with your EmailJS service ID
        "template_smrh67s", // Replace with your EmailJS template ID
        templateParams,
        "WHHvLZ0WsqQ4TIqLHD" // Replace with your EmailJS user ID
      );

      alert(`Password reset link sent to ${email}`);
      onClose();
    } catch (error) {
      console.error("Failed to reset password:", error);
      alert("Failed to reset password. Please try again.");
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Reset Password</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please enter your email to receive a password reset link.
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          label="Email"
          type="email"
          fullWidth
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleResetPassword} color="primary">
          Send Reset Link
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ResetPass;

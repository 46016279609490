import { createSlice } from "@reduxjs/toolkit";
import { fetchBackendData } from "../thunks/fetchBackendData.js"; // Ensure the correct import path

export const CashFlowSlice = createSlice({
  name: "cashFlow",
  initialState: {
    formData: {
      years: {
        "2023-2024": {
          cashFlowItems: {
            netIncome: [0, 0, 0, 0],
            depreciation: [0, 0, 0, 0],
            changesInAccountsRecievable: [0, 0, 0, 0],
            changesInInventory: [0, 0, 0, 0],
            changesInAccountsPayable: [0, 0, 0, 0],
            netCashFromOperatingActivities: [0, 0, 0, 0],
            investingActivities: [0, 0, 0, 0],
            purchaseOfPropertyAndEquipment: [0, 0, 0, 0],
            saleOfInvestment: [0, 0, 0, 0],
            netCashUsedInInvestingActivities: [0, 0, 0, 0],
            financingActivities: [0, 0, 0, 0],
            proceedsFromIssuanceOfStock: [0, 0, 0, 0],
            paymentOfDividends: [0, 0, 0, 0],
            repaymentOfDebt: [0, 0, 0, 0],
            netCashProvidedInFinancingActivities: [0, 0, 0, 0],
            netIncreaseInCash: [0, 0, 0, 0],
            cashAtBeginningOfPeriod: [0, 0, 0, 0],
            cashAtEndOfPeriod: [0, 0, 0, 0],
          },
        },
      },
    },
  },
  reducers: {
    setCashFlow: (state, action) => {
      const { year, cashFlowItems } = action.payload;
      state.formData.years[year] = { cashFlowItems };
    },
    incrementCashFlowYear: (state) => {
      const lastYear = Object.keys(state.formData.years).pop().split("-")[1];
      const newYearStart = parseInt(lastYear);
      const newYearEnd = newYearStart + 1;
      const newYear = `${newYearStart}-${newYearEnd}`;

      if (!state.formData.years[newYear]) {
        state.formData.years[newYear] = {
          cashFlowItems: {
            netIncome: [0, 0, 0, 0],
            depreciation: [0, 0, 0, 0],
            changesInAccountsRecievable: [0, 0, 0, 0],
            changesInInventory: [0, 0, 0, 0],
            changesInAccountsPayable: [0, 0, 0, 0],
            netCashFromOperatingActivities: [0, 0, 0, 0],
            investingActivities: [0, 0, 0, 0],
            purchaseOfPropertyAndEquipment: [0, 0, 0, 0],
            saleOfInvestment: [0, 0, 0, 0],
            netCashUsedInInvestingActivities: [0, 0, 0, 0],
            financingActivities: [0, 0, 0, 0],
            proceedsFromIssuanceOfStock: [0, 0, 0, 0],
            paymentOfDividends: [0, 0, 0, 0],
            repaymentOfDebt: [0, 0, 0, 0],
            netCashProvidedInFinancingActivities: [0, 0, 0, 0],
            netIncreaseInCash: [0, 0, 0, 0],
            cashAtBeginningOfPeriod: [0, 0, 0, 0],
            cashAtEndOfPeriod: [0, 0, 0, 0],
          },
        };
      }
    },
    decrementCashFlowYear: (state) => {
      const years = Object.keys(state.formData.years);
      if (years.length > 1) {
        const lastYear = years.pop();
        delete state.formData.years[lastYear];
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchBackendData.fulfilled, (state, action) => {
      state.formData = action.payload.formData.cashFlow.formData;
    });
  },
});

export const { setCashFlow, incrementCashFlowYear, decrementCashFlowYear } =
  CashFlowSlice.actions;

export default CashFlowSlice.reducer;

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setGanttChartRows,
  addGanttChartRow,
  removeGanttChartRow,
} from "../store/GanttChartSlice.js";
import DOMPurify from "dompurify";
import "./styles/StyleTwo.css";

const GanttChart = () => {
  const dispatch = useDispatch();
  const rows = useSelector(
    (state) => state.ganttChart.formData.GanttChart.rows
  );
  const incomplete = "In Progress";
  const complete = "Complete";

  useEffect(() => {
    dispatch(setGanttChartRows(rows));
    // eslint-disable-next-line
  }, [dispatch]);

  const handleInputChange = (value, index, field) => {
    const sanitizedValue = DOMPurify.sanitize(value);
    const updatedRows = rows.map((row, idx) => {
      if (idx === index) {
        return { ...row, [field]: sanitizedValue };
      }
      return row;
    });
    dispatch(setGanttChartRows(updatedRows));
  };

  const handleCheckboxChange = (index) => {
    const updatedRows = rows.map((row, idx) => {
      if (idx === index) {
        return { ...row, completed: !row.completed };
      }
      return row;
    });
    dispatch(setGanttChartRows(updatedRows));
  };

  const handleDeleteRow = (index) => {
    dispatch(removeGanttChartRow(index));
  };

  const saveData = () => {
    dispatch(setGanttChartRows(rows));
    alert("Gantt Chart Data Saved");
  };

  return (
    <div className="content">
      <h2 className="title">What actions do you need to take?</h2>
      <div>
        <div style={{ marginLeft: "5%", marginRight: "5%", marginTop: 40 }}>
          {rows.map((row, index) => (
            <div
              key={index}
              className={`row ${row.completed ? "completed-row" : ""}`}
            >
              <input
                className="inputnum"
                type="text"
                value={row.task}
                onChange={(e) =>
                  handleInputChange(e.target.value, index, "task")
                }
                placeholder="Task"
              />
              <input
                className="inputnum"
                type="text"
                value={row.resource}
                onChange={(e) =>
                  handleInputChange(e.target.value, index, "resource")
                }
                placeholder="Resource"
              />
              <input
                className="inputnum"
                type="text"
                value={row.timeframe}
                onChange={(e) =>
                  handleInputChange(e.target.value, index, "timeframe")
                }
                placeholder="Timeframe"
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  width: "50%",
                }}
              >
                <input
                  className="checkbox"
                  type="checkbox"
                  checked={row.completed}
                  onChange={() => handleCheckboxChange(index)}
                />
                <span className="checkboxtext">
                  {row.completed ? complete : incomplete}
                </span>
                <button
                  style={{
                    background: "linear-gradient(to right, #1876D2, #000080)",
                  }}
                  className="delete"
                  onClick={() => handleDeleteRow(index)}
                >
                  Delete
                </button>
              </div>
            </div>
          ))}
        </div>
        <div className="rows">
          <button
            style={{
              background: "linear-gradient(to right, #1876D2, #000080)",
            }}
            className="button"
            onClick={() => dispatch(addGanttChartRow())}
          >
            Add Row
          </button>
          <button
            style={{
              background: "linear-gradient(to right, #1876D2, #000080)",
            }}
            className="button"
            onClick={saveData}
          >
            Save Action Plan
          </button>
          <button
            style={{
              background: "linear-gradient(to right, #1876D2, #000080)",
            }}
            className="button"
            onClick={() => dispatch(removeGanttChartRow())}
          >
            Remove Row
          </button>
        </div>
      </div>
    </div>
  );
};

export default GanttChart;

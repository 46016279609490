import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setBalanceSheet, incrementBalanceSheetYear } from '../../store/BalanceSheetSlice';
import './BalanceSheet.css';
import * as XLSX from 'xlsx';

const BalanceSheet = () => {
  const dispatch = useDispatch();
  const reduxBalanceSheet = useSelector((state) => state.balanceSheet.formData.years || {});
  const [currentYear, setCurrentYear] = useState(Object.keys(reduxBalanceSheet).pop() || '2023-2024');
  const [budgetItems, setBudgetItems] = useState(reduxBalanceSheet[currentYear]?.budgetItems || {
    cash: [0, 0, 0, 0],
    accRecievable: [0, 0, 0, 0],
    inventory: [0, 0, 0, 0],
    prepaid: [0, 0, 0, 0],
    propEquip: [0, 0, 0, 0],
    accDepreciate: [0, 0, 0, 0],
    goodwill: [0, 0, 0, 0],
    intangible: [0, 0, 0, 0],
    accPayable: [0, 0, 0, 0],
    shortDebt: [0, 0, 0, 0],
    liabilities: [0, 0, 0, 0],
    longDebt: [0, 0, 0, 0],
    defTax: [0, 0, 0, 0],
    commonStock: [0, 0, 0, 0],
    retainedEarning: [0, 0, 0, 0],
  });

  useEffect(() => {
    setBudgetItems(reduxBalanceSheet[currentYear]?.budgetItems || {
      cash: [0, 0, 0, 0],
      accRecievable: [0, 0, 0, 0],
      inventory: [0, 0, 0, 0],
      prepaid: [0, 0, 0, 0],
      propEquip: [0, 0, 0, 0],
      accDepreciate: [0, 0, 0, 0],
      goodwill: [0, 0, 0, 0],
      intangible: [0, 0, 0, 0],
      accPayable: [0, 0, 0, 0],
      shortDebt: [0, 0, 0, 0],
      liabilities: [0, 0, 0, 0],
      longDebt: [0, 0, 0, 0],
      defTax: [0, 0, 0, 0],
      commonStock: [0, 0, 0, 0],
      retainedEarning: [0, 0, 0, 0],
    });
  }, [currentYear, reduxBalanceSheet]);

  const currentAssets= [(parseInt(budgetItems.cash[0])+parseInt(budgetItems.accRecievable[0])+ parseInt(budgetItems.inventory[0])+parseInt(budgetItems.prepaid[0])),
  (parseInt(budgetItems.cash[1])+parseInt(budgetItems.accRecievable[1])+ parseInt(budgetItems.inventory[1])+parseInt(budgetItems.prepaid[1])),
  (parseInt(budgetItems.cash[2])+parseInt(budgetItems.accRecievable[2])+ parseInt(budgetItems.inventory[2])+parseInt(budgetItems.prepaid[2])),
  (parseInt(budgetItems.cash[3])+parseInt(budgetItems.accRecievable[3])+ parseInt(budgetItems.inventory[3])+parseInt(budgetItems.prepaid[3]))]
  const nonCurrentAssets = [(parseInt(budgetItems.propEquip[0])-parseInt(budgetItems.accDepreciate[0])+parseInt(budgetItems.goodwill[0])+parseInt(budgetItems.intangible[0])), 
  (parseInt(budgetItems.propEquip[1])-parseInt(budgetItems.accDepreciate[1])+parseInt(budgetItems.goodwill[1])+parseInt(budgetItems.intangible[1])),
  (parseInt(budgetItems.propEquip[2])-parseInt(budgetItems.accDepreciate[2])+parseInt(budgetItems.goodwill[2])+parseInt(budgetItems.intangible[2])),
  (parseInt(budgetItems.propEquip[3])-parseInt(budgetItems.accDepreciate[3])+parseInt(budgetItems.goodwill[3])+parseInt(budgetItems.intangible[3]))]
  const totalAssets = [(currentAssets[0]+nonCurrentAssets[0]), (currentAssets[1]+nonCurrentAssets[1]), (currentAssets[2]+nonCurrentAssets[2]), (currentAssets[3]+nonCurrentAssets[3]) ]
  const currentLiabilities = [(parseInt(budgetItems.accPayable[0])+parseInt(budgetItems.shortDebt[0])+parseInt(budgetItems.liabilities[0])), 
    (parseInt(budgetItems.accPayable[1])+parseInt(budgetItems.shortDebt[1])+parseInt(budgetItems.liabilities[1])),
    (parseInt(budgetItems.accPayable[2])+parseInt(budgetItems.shortDebt[2])+parseInt(budgetItems.liabilities[2])),
    (parseInt(budgetItems.accPayable[3])+parseInt(budgetItems.shortDebt[3])+parseInt(budgetItems.liabilities[3]))]
  const totalNonCurrentLiabilities = [(parseInt(budgetItems.longDebt[0])+parseInt(budgetItems.defTax[0])), 
  (parseInt(budgetItems.longDebt[1])+parseInt(budgetItems.defTax[1])),
  (parseInt(budgetItems.longDebt[2])+parseInt(budgetItems.defTax[2])),
  (parseInt(budgetItems.longDebt[2])+parseInt(budgetItems.defTax[2]))]
  const totalLiabilities = [(currentLiabilities[0]+totalNonCurrentLiabilities[0]), (currentLiabilities[1]+totalNonCurrentLiabilities[1]),
  (currentLiabilities[2]+totalNonCurrentLiabilities[2]), (currentLiabilities[3]+totalNonCurrentLiabilities[3])]
  const totalEquity = [(parseInt(budgetItems.commonStock[0])+parseInt(budgetItems.retainedEarning[0])), 
  (parseInt(budgetItems.commonStock[1])+parseInt(budgetItems.retainedEarning[1])),
  (parseInt(budgetItems.commonStock[2])+parseInt(budgetItems.retainedEarning[2])),
  (parseInt(budgetItems.commonStock[3])+parseInt(budgetItems.retainedEarning[3]))] 
  const totalLiabilityEquity = [(totalEquity[0]+totalLiabilities[0]), (totalEquity[1]+totalLiabilities[1]), (totalEquity[2]+totalLiabilities[2]), (totalEquity[3]+totalLiabilities[3])]
  
  const handleInputChange = (event, field, index) => {
    const newValues = [...budgetItems[field]];
    newValues[index] = parseInt(event.target.value, 10) || 0;
    setBudgetItems({ ...budgetItems, [field]: newValues });
  };

  const handleSubmit = () => {
    dispatch(setBalanceSheet({ year: currentYear, budgetItems }));
    alert('Balance Sheet Saved');
  };

  const handleIncrementYear = () => {
    const [startYear, endYear] = currentYear.split('-').map(Number);
    const newYear = `${startYear + 1}-${endYear + 1}`;
    dispatch(incrementBalanceSheetYear());
    setCurrentYear(newYear);
    if (!reduxBalanceSheet[newYear]) {
      setBudgetItems({
        cash: [0, 0, 0, 0],
        accRecievable: [0, 0, 0, 0],
        inventory: [0, 0, 0, 0],
        prepaid: [0, 0, 0, 0],
        propEquip: [0, 0, 0, 0],
        accDepreciate: [0, 0, 0, 0],
        goodwill: [0, 0, 0, 0],
        intangible: [0, 0, 0, 0],
        accPayable: [0, 0, 0, 0],
        shortDebt: [0, 0, 0, 0],
        liabilities: [0, 0, 0, 0],
        longDebt: [0, 0, 0, 0],
        defTax: [0, 0, 0, 0],
        commonStock: [0, 0, 0, 0],
        retainedEarning: [0, 0, 0, 0],
      });
    } else {
      setBudgetItems(reduxBalanceSheet[newYear].budgetItems);
    }
  };

  const handleDecrementYear = () => {
    const years = Object.keys(reduxBalanceSheet);
    const currentYearIndex = years.indexOf(currentYear);
    if (currentYearIndex > 0) {
      const previousYear = years[currentYearIndex - 1];
      setCurrentYear(previousYear);
      setBudgetItems(reduxBalanceSheet[previousYear]?.budgetItems || {});
    }
  };

  const exportToXLSX = () => {
    const data = [
      ['Balance Sheet'],
      ['Year', currentYear, '', '', ''],
      ['Item', 'Q1', 'Q2', 'Q3', 'Q4'],
      ['Cash and Cash Equivalents', ...budgetItems.cash],
      ['Accounts Receivable', ...budgetItems.accRecievable],
      ['Inventory', ...budgetItems.inventory],
      ['Prepaid Expenses', ...budgetItems.prepaid],
      ['Total Current Assets', ...currentAssets],
      ['Property, Plant, and Equipment', ...budgetItems.propEquip],
      ['Less: Accumulated Depreciation', ...budgetItems.accDepreciate],
      ['Goodwill', ...budgetItems.goodwill],
      ['Intangible Assets', ...budgetItems.intangible],
      ['Total Non-Current Assets', ...nonCurrentAssets],
      ['Total Assets', ...totalAssets],
      ['Accounts Payable', ...budgetItems.accPayable],
      ['Short-term Debt', ...budgetItems.shortDebt],
      ['Accrued Liabilities', ...budgetItems.liabilities],
      ['Total Current Liabilities', ...currentLiabilities],
      ['Long-term Debt', ...budgetItems.longDebt],
      ['Deferred Tax Liabilities', ...budgetItems.defTax],
      ['Total Non-Current Liabilities', ...totalNonCurrentLiabilities],
      ['Total Liabilities', ...totalLiabilities],
      ['Common Stock', ...budgetItems.commonStock],
      ['Retained Earnings', ...budgetItems.retainedEarning],
      ['Total Shareholder Equity', ...totalEquity],
      ['Total Liabilities and Equity', ...totalLiabilityEquity]
    ];

    const worksheet = XLSX.utils.aoa_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Balance Sheet');

    XLSX.writeFile(workbook, `BalanceSheet_${currentYear}.xlsx`);
  };

  return (
    <div className="balance-sheet">
      <div className="header">
        <h2 style={{display: 'inline', fontSize: 26, fontStyle: 'italic'}}>Balance Sheet: {currentYear}</h2>
        <div style={{flex: 1, alignItems: 'flex-start'}}>
          <div className="year-controls">
          <button data-testid="test-increment" onClick={handleIncrementYear}>▲</button>
          <button  data-testid="test-decrement" onClick={handleDecrementYear}>▼</button>
          </div>
          </div>
      </div>

      <div className="content">
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
        <h2 className="table-cell">Assets</h2>
        <div className="table-cell-head">Q1</div>
          <div className="table-cell-head">Q2</div>
          <div className="table-cell-head">Q3</div>
          <div className="table-cell-head">Q4</div>
          </div>
        {/* Current Assets */}
        <div className="section">
          <h3>Current Assets:</h3>
          <div className="row">
            <div data-testid="CCE" className="table-cell-head">Cash and Cash Equivalents</div>
            {budgetItems.cash.map((item, index) => (
              <input
              className="table-cell"
                key={index}
                data-testid="CCE"
                type="text"
                value={item}
                onChange={(e) => handleInputChange(e, 'cash', index)}
              />
            ))}
          </div>
          <div className="row">
            <div className="table-cell-head">Accounts Receivable</div>
            {budgetItems.accRecievable.map((item, index) => (
              <input
              className="table-cell"
                key={index}
                type="text"
                value={item}
                onChange={(e) => handleInputChange(e, 'accRecievable', index)}
              />
            ))}
          </div>
          <div className="row">
            <div className="table-cell-head">Inventory</div>
            {budgetItems.inventory.map((item, index) => (
              <input
              className="table-cell"
                key={index}
                type="text"
                value={item}
                onChange={(e) => handleInputChange(e, 'inventory', index)}
              />
            ))}
          </div>
          <div className="row">
            <div className="table-cell-head">Prepaid Expenses</div>
            {budgetItems.prepaid.map((item, index) => (
              <input
              className="table-cell"
                key={index}
                type="text"
                value={item}
                onChange={(e) => handleInputChange(e, 'prepaid', index)}
              />
            ))}
          </div>
            <div className="row">
            <div data-testid="CA" className="table-cell-head">Total Current Assets</div>
            {currentAssets.map((item) => (
              <div className="table-cell">${item}</div>
  ))}
          </div>
        </div>

        {/* Non-Current Assets */}
        <div className="section">
          <h3>Non-Current Assets:</h3>
          <div className="row">
            <div className="table-cell-head">Property, Plant, and Equipment</div>
            {budgetItems.propEquip.map((item, index) => (
              <input
              className="table-cell"
                key={index}
                type="text"
                value={item}
                onChange={(e) => handleInputChange(e, 'propEquip', index)}
              />
            ))}
          </div>
          <div className="row">
            <div className="table-cell-head">Less: Accumulated Depreciation</div>
            {budgetItems.accDepreciate.map((item, index) => (
              <input
              className="table-cell"
                key={index}
                type="text"
                value={item}
                onChange={(e) => handleInputChange(e, 'accDepreciate', index)}
              />
            ))}
          </div>
          <div className="row">
            <div className="table-cell-head">Goodwill</div>
            {budgetItems.goodwill.map((item, index) => (
              <input
              className="table-cell"
                key={index}
                type="text"
                value={item}
                onChange={(e) => handleInputChange(e, 'goodwill', index)}
              />
            ))}
          </div>
          <div className="row">
            <div className="table-cell-head">Intangible Assets</div>
            {budgetItems.intangible.map((item, index) => (
              <input
              className="table-cell"
                key={index}
                type="text"
                value={item}
                onChange={(e) => handleInputChange(e, 'intangible', index)}
              />
            ))}
          </div>
          <div className="row">
            <div className="table-cell-head">Total Non-Current Assets</div>
            {nonCurrentAssets.map((item) => (
              <div className="table-cell">${item}</div>
  ))}
          </div>
          <div className="row">
            <div className="table-cell-head">Total Assets</div>
            {totalAssets.map((item) => (
              <div className="table-cell">${item}</div>
  ))}
          </div>

        </div>

        {/* Liabilities and Equity */}
        <h2>Liabilities and Equity</h2>
        {/* Current Liabilities */}
        <div className="section">
          <h3>Current Liabilities:</h3>
          <div className="row">
            <div className="table-cell-head">Accounts Payable</div>
            {budgetItems.accPayable.map((item, index) => (
              <input
              className="table-cell"
                key={index}
                type="text"
                value={item}
                onChange={(e) => handleInputChange(e, 'accPayable', index)}
              />
            ))}
          </div>
          <div className="row">
            <div className="table-cell-head">Short-term Debt</div>
            {budgetItems.shortDebt.map((item, index) => (
              <input
              className="table-cell"
                key={index}
                type="text"
                value={item}
                onChange={(e) => handleInputChange(e, 'shortDebt', index)}
              />
            ))}
          </div>
          <div className="row">
            <div className="table-cell-head">Accrued Liabilities</div>
            {budgetItems.liabilities.map((item, index) => (
              <input
              className="table-cell"
                key={index}
                type="text"
                value={item}
                onChange={(e) => handleInputChange(e, 'liabilities', index)}
              />
            ))}
          </div>
          <div className="row">
            <div className="table-cell-head">Total Current Liabilities</div>
            {currentLiabilities.map((item) => (
              <div className="table-cell">${item}</div>
  ))}
          </div>
        </div>

        {/* Non-Current Liabilities */}
        <div className="section">
          <h3>Non-Current Liabilities:</h3>
          <div className="row">
            <div className="table-cell-head">Long-term Debt</div>
            {budgetItems.longDebt.map((item, index) => (
              <input
              className="table-cell"
                key={index}
                type="text"
                value={item}
                onChange={(e) => handleInputChange(e, 'longDebt', index)}
              />
            ))}
          </div>
          <div className="row">
            <div className="table-cell-head">Deferred Tax Liabilities</div>
            {budgetItems.defTax.map((item, index) => (
              <input
              className="table-cell"
                key={index}
                type="text"
                value={item}
                onChange={(e) => handleInputChange(e, 'defTax', index)}
              />
            ))}
          </div>
          <div className="row">
            <div className="table-cell-head">Total Non-Current Liabilities</div>
            {totalNonCurrentLiabilities.map((item) => (
              <div className="table-cell">${item}</div>
  ))}
          </div>
          <div className="row">
            <div className="table-cell-head">Total Liabilities</div>
            {totalLiabilities.map((item) => (
              <div className="table-cell">${item}</div>
  ))}
          </div>
        </div>

        {/* Equity */}
        <div className="section">
          <h3>Equity:</h3>
          <div className="row">
            <div className="table-cell-head">Common Stock</div>
            {budgetItems.commonStock.map((item, index) => (
              <input
              className="table-cell"
                key={index}
                type="text"
                value={item}
                onChange={(e) => handleInputChange(e, 'commonStock', index)}
              />
            ))}
          </div>
          <div className="row">
            <div className="table-cell-head">Retained Earnings</div>
            {budgetItems.retainedEarning.map((item, index) => (
              <input
              className="table-cell"
                key={index}
                type="text"
                value={item}
                onChange={(e) => handleInputChange(e, 'retainedEarning', index)}
              />
            ))}
          </div>
          <div className="row">
            <div className="table-cell-head">Total Shareholder Equity</div>
            {totalEquity.map((item) => (
              <div className="table-cell">${item}</div>
  ))}
          </div>
          <div className="row">
            <div className="table-cell-head">Total Liabilities and Equity</div>
            {totalLiabilityEquity.map((item) => (
              <div className="table-cell">${item}</div>
  ))}
          </div>

        </div>
      </div>
      <div style={{flexDirection: 'row'}}>
      <button className="export-button" onClick={handleSubmit}>Save Balance Sheet</button>
      <button className="export-button" onClick={exportToXLSX}>Export to XLSX</button>
    </div>
    </div>
  );
};

export default BalanceSheet;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../components/axiosConfig.js";

export const clearAllData = createAsyncThunk(
  "user/clearAllData",
  async (_, { rejectWithValue }) => {
    try {
      return true; // Indicate success
      // eslint-disable-next-line
    } catch (error) {
      // eslint-disable-next-line
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export const fetchBackendData = createAsyncThunk(
  "user/fetchBackendData",
  async (token, { rejectWithValue, getState }) => {
    const { csrfToken } = getState().auth;
    try {
      const response = await axiosInstance.get(
        "https://bossentrepreneur.com.au/formData/get",
        {
          headers: {
            "Content-Type": "application/json",
            "csrf-token": csrfToken,
          },
          withCredentials: true,
        }
      );

      console.log("Backend response:", response.data); // Add logging
      return response.data;
    } catch (error) {
      console.log("Fetch failed:", error); // Add logging
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

const initialState = {
  inputs: {},
  operatingBudget: {},
  cashFlow: {},
  ganttChart: {},
  competitors: {},
  hiring: {},
  incomeStatement: {},
  capitalisationTable: {},
  balanceSheet: {},
  marketingBudget: {},
  riskAnalysis: {},
  products: {},
  status: "idle",
  error: null,
};

const dataSlice = createSlice({
  name: "data",
  initialState,
  reducers: {
    clearData: (state) => {
      return { ...initialState }; // Reset formData to initial state
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBackendData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchBackendData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.inputs = action.payload.formData.inputs || {};
        state.operatingBudget = action.payload.formData.operatingBudget || {};
        state.cashFlow = action.payload.formData.cashFlow || {};
        state.ganttChart = action.payload.formData.ganttChart || {};
        state.competitors = action.payload.formData.competitors || {};
        state.hiring = action.payload.formData.hiring || {};
        state.incomeStatement = action.payload.formData.incomeStatement || {};
        state.capitalisationTable =
          action.payload.formData.capitalisationTable || {};
        state.balanceSheet = action.payload.formData.balanceSheet || {};
        state.marketingBudget = action.payload.formData.marketingBudget || {};
        state.riskAnalysis = action.payload.formData.riskAnalysis || {};
        state.products = action.payload.formData.products || {};
        // alert("State data updated");
      })
      .addCase(fetchBackendData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload || "Unknown error";
        console.error("Fetch failed with error:", action.payload); // Add logging
      });
  },
});

export const { clearData } = dataSlice.actions;

export default dataSlice.reducer;

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setIncomeStatement,
  incrementIncomeStatementYear,
} from "../../store/IncomeStatementSlice.js";
import DOMPurify from "dompurify";
import "./IncomeStatement.css";
import * as XLSX from "xlsx";

const IncomeStatement = () => {
  const dispatch = useDispatch();
  const incomeStatementData = useSelector(
    (state) => state.incomeStatement.formData
  );
  const [currentYear, setCurrentYear] = useState(
    Object.keys(incomeStatementData.years)[0]
  );
  const [budgetItems, setBudgetItems] = useState(
    incomeStatementData.years[currentYear].budgetItems
  );

  const handleIncrementYear = () => {
    const [startYear, endYear] = currentYear.split("-").map(Number);
    const newYear = `${startYear + 1}-${endYear + 1}`;
    dispatch(incrementIncomeStatementYear());
    setCurrentYear(newYear);
    if (!incomeStatementData.years[newYear]) {
      dispatch(
        setIncomeStatement({
          year: newYear,
          budgetItems: {
            grossSales: [0, 0, 0, 0],
            returnsAllowances: [0, 0, 0, 0],
            costGoodSold: [0, 0, 0, 0],
            sellingGeneralAdmin: [0, 0, 0, 0],
            depreciationAmortization: [0, 0, 0, 0],
            researchDevelopment: [0, 0, 0, 0],
            interestIncome: [0, 0, 0, 0],
            interestExpense: [0, 0, 0, 0],
            otherIncome: [0, 0, 0, 0],
            incomeTax: [0, 0, 0, 0],
          },
        })
      );
    }
  };

  const handleDecrementYear = () => {
    const years = Object.keys(incomeStatementData.years);
    const currentYearIndex = years.indexOf(currentYear);
    if (currentYearIndex > 0) {
      const previousYear = years[currentYearIndex - 1];
      setCurrentYear(previousYear);
    }
  };

  const saveData = () => {
    dispatch(setIncomeStatement({ year: currentYear, budgetItems }));
    alert("Income Statement Saved");
  };

  const netSales = budgetItems.grossSales.map(
    (grossSale, index) => grossSale - budgetItems.returnsAllowances[index]
  );
  const grossProfit = netSales.map(
    (netSale, index) => netSale - budgetItems.costGoodSold[index]
  );
  const operatingExpenses = budgetItems.sellingGeneralAdmin.map(
    (adminCost, index) =>
      adminCost +
      budgetItems.depreciationAmortization[index] +
      budgetItems.researchDevelopment[index]
  );
  const operatingIncome = grossProfit.map(
    (profit, index) => profit - operatingExpenses[index]
  );
  const totalOtherIncome = budgetItems.interestIncome.map(
    (interestIncome, index) =>
      interestIncome -
      budgetItems.interestExpense[index] +
      budgetItems.otherIncome[index]
  );
  const preTaxIncome = operatingIncome.map(
    (income, index) => income + totalOtherIncome[index]
  );
  const netIncome = preTaxIncome.map(
    (incomeBeforeTax, index) => incomeBeforeTax - budgetItems.incomeTax[index]
  );

  const handleInputChange = (text, field, index) => {
    const sanitizedValue = DOMPurify.sanitize(text);
    const newValue = sanitizedValue.replace(/[^0-9]/g, ""); // This regex ensures only numbers
    const updatedValues = [...budgetItems[field]]; // Clone the current field's array
    updatedValues[index] = parseInt(newValue, 10) || 0; // Update the value at the specified index, default to 0 if NaN
    setBudgetItems({ ...budgetItems, [field]: updatedValues }); // Update the state with the new values
    // dispatch(setIncomeStatement({ year: currentYear, budgetItems: updatedBudgetItems }));
  };

  function capitalizeWords(str) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }
  const exportToXLSX = () => {
    const data = [];
    const years = Object.keys(incomeStatementData.years);

    years.forEach((year) => {
      const yearData = incomeStatementData.years[year].budgetItems;
      Object.keys(yearData).forEach((category) => {
        const row = [
          year,
          capitalizeWords(category.replace(/([A-Z])/g, " $1")),
        ];
        row.push(...yearData[category]);
        data.push(row);
      });

      // Add calculated values for each year
      const netSales = yearData.grossSales.map(
        (grossSale, index) => grossSale - yearData.returnsAllowances[index]
      );
      const grossProfit = netSales.map(
        (netSale, index) => netSale - yearData.costGoodSold[index]
      );
      const operatingExpenses = yearData.sellingGeneralAdmin.map(
        (adminCost, index) =>
          adminCost +
          yearData.depreciationAmortization[index] +
          yearData.researchDevelopment[index]
      );
      const operatingIncome = grossProfit.map(
        (profit, index) => profit - operatingExpenses[index]
      );
      const totalOtherIncome = yearData.interestIncome.map(
        (interestIncome, index) =>
          interestIncome -
          yearData.interestExpense[index] +
          yearData.otherIncome[index]
      );
      const preTaxIncome = operatingIncome.map(
        (income, index) => income + totalOtherIncome[index]
      );
      const netIncome = preTaxIncome.map(
        (incomeBeforeTax, index) => incomeBeforeTax - yearData.incomeTax[index]
      );

      data.push([year, "Net Sales", ...netSales]);
      data.push([year, "Gross Profit", ...grossProfit]);
      data.push([year, "Total Operating Expenses", ...operatingExpenses]);
      data.push([year, "Operating Income", ...operatingIncome]);
      data.push([year, "Total Other Income", ...totalOtherIncome]);
      data.push([year, "Pre-Tax Income", ...preTaxIncome]);
      data.push([year, "Net Income", ...netIncome]);
    });

    const worksheet = XLSX.utils.aoa_to_sheet([
      ["Income Statement"],
      ["Year", "Category", "Q1", "Q2", "Q3", "Q4"],
      ...data,
    ]);

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Income Statement");

    XLSX.writeFile(workbook, "IncomeStatement.xlsx");
  };

  return (
    <div className="income-statement">
      <div className="header">
        <h2 style={{ display: "inline", fontSize: 26, fontStyle: "italic" }}>
          Income Statement: {currentYear}
        </h2>
        <div style={{ flex: 1, alignItems: "flex-start" }}>
          <div className="year-controls">
            <button data-testid="test-increment" onClick={handleIncrementYear}>
              ▲
            </button>
            <button data-testid="test-decrement" onClick={handleDecrementYear}>
              ▼
            </button>
          </div>
        </div>
      </div>

      <div className="table">
        <div className="header row">
          <div className="table-cell">Item</div>
          <div className="table-cell">Q1</div>
          <div className="table-cell">Q2</div>
          <div className="table-cell">Q3</div>
          <div className="table-cell">Q4</div>
        </div>

        <div className="table-row">
          <div style={{ width: 200 }}>Revenues:</div>
        </div>

        <div className="table-row">
          <div style={{ width: 200 }}>Gross Sales Revenue</div>
          {budgetItems.grossSales.map((item, index) => (
            <input
              className="table-cell"
              key={index}
              type="text"
              value={item}
              onChange={(e) =>
                handleInputChange(e.target.value, "grossSales", index)
              }
            />
          ))}
        </div>

        <div className="table-row">
          <div style={{ width: 200 }}>Less: Sales Returns and Allowances</div>
          {budgetItems.returnsAllowances.map((item, index) => (
            <input
              key={index}
              className="table-cell"
              type="text"
              value={item}
              onChange={(e) =>
                handleInputChange(e.target.value, "returnsAllowances", index)
              }
            />
          ))}
        </div>

        <div className="table-row">
          <div style={{ width: 200 }}>Net Sales Revenue</div>
          {netSales.map((item, index) => (
            <div key={index} style={{ width: "20%", margin: "0 5px" }}>
              ${item}
            </div>
          ))}
        </div>

        <div className="table-row">
          <div style={{ width: 200 }}>Cost of Goods Sold</div>
          {budgetItems.costGoodSold.map((item, index) => (
            <input
              key={index}
              className="table-cell"
              type="text"
              value={item}
              onChange={(e) =>
                handleInputChange(e.target.value, "costGoodSold", index)
              }
            />
          ))}
        </div>

        <div className="table-row">
          <div style={{ width: 300 }}>Gross Profit</div>
          {grossProfit.map((item, index) => (
            <div key={index} style={{ width: "20%", margin: "0 5px" }}>
              ${item}
            </div>
          ))}
        </div>

        <div className="table-row">
          <div style={{ width: 200 }}>Operating Expenses:</div>
        </div>

        <div className="table-row">
          <div style={{ width: 200 }}>Selling, General and Administrative</div>
          {budgetItems.sellingGeneralAdmin.map((item, index) => (
            <input
              key={index}
              className="table-cell"
              type="text"
              value={item}
              onChange={(e) =>
                handleInputChange(e.target.value, "sellingGeneralAdmin", index)
              }
            />
          ))}
        </div>

        <div className="table-row">
          <div style={{ width: 200 }}>Depreciation and Amortization</div>
          {budgetItems.depreciationAmortization.map((item, index) => (
            <input
              key={index}
              className="table-cell"
              type="text"
              value={item}
              onChange={(e) =>
                handleInputChange(
                  e.target.value,
                  "depreciationAmortization",
                  index
                )
              }
            />
          ))}
        </div>

        <div className="table-row">
          <div style={{ width: 200 }}>Research and Development</div>
          {budgetItems.researchDevelopment.map((item, index) => (
            <input
              key={index}
              className="table-cell"
              type="text"
              value={item}
              onChange={(e) =>
                handleInputChange(e.target.value, "researchDevelopment", index)
              }
            />
          ))}
        </div>

        <div className="table-row">
          <div style={{ width: 300 }}>Total Operating Expenses</div>
          {operatingExpenses.map((item, index) => (
            <div key={index} style={{ width: "20%", margin: "0 5px" }}>
              ${item}
            </div>
          ))}
        </div>

        <div className="table-row">
          <div style={{ width: 300 }}>Operating Income</div>
          {operatingIncome.map((item, index) => (
            <div key={index} style={{ width: "20%", margin: "0 5px" }}>
              ${item}
            </div>
          ))}
        </div>

        <div className="table-row">
          <div style={{ width: 200 }}>Other Income and Expenses:</div>
        </div>

        <div className="table-row">
          <div style={{ width: 200 }}>Interest Income</div>
          {budgetItems.interestIncome.map((item, index) => (
            <input
              key={index}
              className="table-cell"
              type="text"
              value={item}
              onChange={(e) =>
                handleInputChange(e.target.value, "interestIncome", index)
              }
            />
          ))}
        </div>

        <div className="table-row">
          <div style={{ width: 200 }}>Interest Expense</div>
          {budgetItems.interestExpense.map((item, index) => (
            <input
              key={index}
              className="table-cell"
              type="text"
              value={item}
              onChange={(e) =>
                handleInputChange(e.target.value, "interestExpense", index)
              }
            />
          ))}
        </div>

        <div className="table-row">
          <div style={{ width: 200 }}>Other Non-Operating Income</div>
          {budgetItems.otherIncome.map((item, index) => (
            <input
              key={index}
              className="table-cell"
              type="text"
              value={item}
              onChange={(e) =>
                handleInputChange(e.target.value, "otherIncome", index)
              }
            />
          ))}
        </div>

        <div className="table-row">
          <div style={{ width: 300 }}>Total Other Income</div>
          {totalOtherIncome.map((item, index) => (
            <div key={index} style={{ width: "20%", margin: "0 5px" }}>
              ${item}
            </div>
          ))}
        </div>

        <div className="table-row">
          <div style={{ width: 300 }}>Pre-Tax Income</div>
          {preTaxIncome.map((item, index) => (
            <div key={index} style={{ width: "20%", margin: "0 5px" }}>
              ${item}
            </div>
          ))}
        </div>

        <div className="table-row">
          <div style={{ width: 200 }}>Income Tax Expense</div>
          {budgetItems.incomeTax.map((item, index) => (
            <input
              key={index}
              className="table-cell"
              type="text"
              value={item}
              onChange={(e) =>
                handleInputChange(e.target.value, "incomeTax", index)
              }
            />
          ))}
        </div>

        <div className="table-row">
          <div style={{ width: 300 }}>Net Income</div>
          {netIncome.map((item, index) => (
            <div key={index} style={{ width: "20%", margin: "0 5px" }}>
              ${item}
            </div>
          ))}
        </div>
      </div>
      <div style={{ marginTop: 20 }}>
        <button className="export-button" onClick={saveData}>
          Save Income Statement
        </button>
        <button className="export-button" onClick={exportToXLSX}>
          Export to XLSX
        </button>
      </div>
    </div>
  );
};

export default IncomeStatement;

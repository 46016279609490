import { createSlice } from "@reduxjs/toolkit";
import { fetchBackendData } from "../thunks/fetchBackendData.js"; // Ensure the correct import path

const initialState = {
  formData: {
    employees: [],
    employed: [],
    hrPlan: "", // Add HR plan state
  },
};

const HiringSlice = createSlice({
  name: "hiring",
  initialState,
  reducers: {
    addEmployee: (state, action) => {
      console.log("Reducer - Adding Employee:", action.payload);
      state.formData.employees.push(action.payload);
    },
    updateEmployee: (state, action) => {
      console.log("Reducer - Update Employee Action:", action.payload);
      const index = state.formData.employees.findIndex(
        (employee) => employee.id === action.payload.id
      );
      if (index !== -1) {
        console.log(
          "Reducer - Updating Employee in Employees:",
          action.payload
        );
        state.formData.employees[index] = action.payload;
      } else {
        const employedIndex = state.formData.employed.findIndex(
          (employee) => employee.id === action.payload.id
        );
        if (employedIndex !== -1) {
          console.log(
            "Reducer - Updating Employee in Employed:",
            action.payload
          );
          state.formData.employed[employedIndex] = action.payload;
        }
      }
      console.log(
        "State after update:",
        JSON.stringify(state.formData, null, 2)
      );
    },
    removeEmployee: (state, action) => {
      console.log("Reducer - Removing Employee:", action.payload);
      state.formData.employees = state.formData.employees.filter(
        (employee) => employee.id !== action.payload
      );
      state.formData.employed = state.formData.employed.filter(
        (employee) => employee.id !== action.payload
      );
    },
    confirmEmployee: (state, action) => {
      const employeeToConfirm = state.formData.employees.find(
        (employee) => employee.id === action.payload
      );
      if (employeeToConfirm) {
        console.log("Reducer - Confirming Employee:", employeeToConfirm);
        state.formData.employed.push(employeeToConfirm);
        state.formData.employees = state.formData.employees.filter(
          (employee) => employee.id !== action.payload
        );
      }
    },
    updateHRPlan: (state, action) => {
      console.log("Reducer - Updating HR Plan:", action.payload);
      state.formData.hrPlan = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchBackendData.fulfilled, (state, action) => {
      state.formData = action.payload.formData.hiring.formData;
    });
  },
});

export const {
  addEmployee,
  updateEmployee,
  removeEmployee,
  confirmEmployee,
  updateHRPlan,
} = HiringSlice.actions;

export default HiringSlice.reducer;

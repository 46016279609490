import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer style={footerStyle}>
      <div style={contentStyle}>
        <p>ABN: 15 776 628 622</p>
        <p style={linkStyle}>
          <a
            href="mailto:dr.dbrown@icloud.com"
            style={{ color: "white", textDecoration: "none" }}
          >
            Email: dr.dbrown@icloud.com
          </a>
        </p>
      </div>
      <div style={contentStyleTwo}>
        <p>
          <Link to="/faq" style={{ color: "white", textDecoration: "none" }}>
            Go to Frequently Asked Questions
          </Link>
        </p>
        <p>&copy; 2024</p>
      </div>
    </footer>
  );
}

const footerStyle = {
  display: "flex",
  justifyContent: "space-between",
  background: "linear-gradient(to right, #1876D2, #000080)",
  textAlign: "center",
  bottom: "0",
  width: "90%",
  borderTop: "1px solid #e7e7e7",
  position: "relative", // Change to "fixed" if you want it at the bottom of the viewport
  left: "0",
  borderTopLeftRadius: "30px",
  borderTopRightRadius: "30px",
  marginRight: "5%",
  marginLeft: "5%",
};

const contentStyle = {
  display: "flex",
  flexDirection: "column",
  marginLeft: 20,
  fontFamily: "Verdana, sans-serif",
  fontSize: "14px",
  alignItems: "flex-start",
  color: "white",
};

const contentStyleTwo = {
  display: "flex",
  flexDirection: "column",
  marginRight: 20,
  fontSize: "14px",
  alignItems: "flex-end",
  color: "white",
  fontFamily: "Verdana, sans-serif",
};

const linkStyle = {
  color: "white",
  textDecoration: "none",
};

export default Footer;

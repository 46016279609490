import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setFormData } from "../store/inputsSlice.js";
import DOMPurify from "dompurify";
import "./styles/Style.css";

const CustomerAnalysis = () => {
  const dispatch = useDispatch();
  const reduxFormData = useSelector(
    (state) => state.inputs.formData.CustomerAnalysis
  );
  const [formData, setLocalFormData] = useState(reduxFormData);

  useEffect(() => {
    setLocalFormData(reduxFormData);
  }, [reduxFormData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const sanitizedValue = DOMPurify.sanitize(value);
    setLocalFormData({
      ...formData,
      [name]: sanitizedValue,
    });
  };

  const handleNumChange = (e) => {
    const { name, value } = e.target;
    const sanitizedValue = DOMPurify.sanitize(value);
    const numericValue = sanitizedValue.replace(/[^0-9.]/g, "");
    const validDecimalValue =
      numericValue.split(".").length > 2
        ? numericValue.slice(0, numericValue.lastIndexOf("."))
        : numericValue;
    setLocalFormData({
      ...formData,
      [name]: validDecimalValue,
    });
  };

  const handleSubmit = () => {
    dispatch(setFormData({ CustomerAnalysis: formData }));
    alert("Customer Analysis Saved");
  };

  const calculateMarketVolume = () =>
    Number(formData.input2) * (Number(formData.input3) / 100);
  const calculateMarketValue = () =>
    calculateMarketVolume() * Number(formData.input4);

  return (
    <div className="content">
      <h2 className="title">Customer Analysis</h2>
      <div className="column">
        <label className="subheading">Customer Profile:</label>
        <textarea
          className="textarea"
          type="text"
          name="input1"
          value={formData.input1}
          onChange={handleChange}
          placeholder="Who are your customers?"
        />
        <label className="subheading">Number of potential customers:</label>
        <textarea
          className="inputnum"
          type="text"
          name="input2"
          value={formData.input2}
          onChange={handleNumChange}
          placeholder="Hpw many potential customers?"
        />
        <label className="subheading">Percentge using Product:</label>
        <textarea
          className="inputnum"
          type="text"
          name="input3"
          value={formData.input3}
          onChange={handleNumChange}
          placeholder="What are the unique features?"
        />
        <label className="subheading">Sale Value per Customer:</label>
        <textarea
          className="inputnum"
          type="text"
          name="input4"
          value={formData.input4}
          onChange={handleNumChange}
          placeholder="Who specifically are your target market?"
        />
        <p className="labeltwo">
          Market Volume = {calculateMarketVolume()} customers
        </p>
        <p className="labeltwo">
          Estimated Market Value = ${calculateMarketValue()}
        </p>

        <button
          style={{ background: "linear-gradient(to right, #1876D2, #000080)" }}
          className="button"
          onClick={handleSubmit}
        >
          Save Customer Analysis
        </button>
      </div>
    </div>
  );
};

export default CustomerAnalysis;
